<template>
  <div
    class="app-new-item"
    v-loading.fullscreen.lock="loading"
    element-loading-spinner="el-icon-loading"
    element-loading-text="Desperately loading..."
    :style="{ width: sWidth }"
  >
    <!-- v-show="printShow" -->
    <div id="printDiv" v-show="printShow">
      <template>
        <TYP
          :printModel="this.$data"
          style="height-max: 1000px; width: 100vw; padding: 40px"
        ></TYP>
      </template>
    </div>
    <div class="new-item-content">
      <!-- General Specification -->
      <div class="general-specification">
        <div>标准规格</div>
        <div>
            <div>
              <span>编辑打印标题：</span>

              <div class="el-input" style="text-align: left">
                <el-checkbox
                  v-model="params.BaseInfoEditPrintTitleCheckbox"
                  placeholder="Please Input"
                ></el-checkbox>
                <el-input
                  v-show="params.BaseInfoEditPrintTitleCheckbox"
                  v-model="params.BaseInfoEditPrintTitle"
                  placeholder="Please Input"
                  style="margin-left: 10px; width: 575px"
                ></el-input>
              </div>
            </div>
            <div>
              <span>编辑打印副标题：</span>

              <div class="el-input" style="text-align: left">
                <el-checkbox
                  v-model="params.BaseInfoEditPrintSubtitleCheckbox"
                  placeholder="Please Input"
                ></el-checkbox>
                <el-input
                  v-show="params.BaseInfoEditPrintSubtitleCheckbox"
                  v-model="params.BaseInfoEditPrintSubtitle"
                  placeholder="Please Input"
                  style="margin-left: 10px; width: 575px"
                ></el-input>
              </div>
            </div>
          <div style="height: auto">
            <!-- <span>INCI Name：</span> -->
            <span>INCI名称：</span>
            <div style="display: inline-block; width: 615px; text-align: left">
              <div
                style="
                  display: flex;
                  justify: flex-start;
                  flex-wrap: wrap;
                  align-items: center;
                "
              >
                <el-tag
                  :key="i"
                  v-for="(tag, i) in inclNameArr"
                  closable
                  :disable-transitions="false"
                  
                  @close="handleClose(tag, 'inclNameArr')"
                  >
                    <!-- @click="tagHandle(inclNameArr, tag, i)" -->
                    <span @click="tagHandle(inclNameArr, tag, i)" style="cursor: pointer;">{{ tag }}</span>
                    <el-button type="primary" style="padding: 0 2px;margin-left:5px;" size="small" 
                    @click="showIndexInput('inclNameInputVisible', 'inclNameSaveTagInput',i,'inclNameArrIndex')">+</el-button>
                    <el-button v-show="i>0" type="primary" style="padding: 0;" size="small" icon="el-icon-arrow-left"
                    @click="leftInputValue(inclNameArr, i)"></el-button>
                    <el-button type="primary" style="padding: 0;" size="small" icon="el-icon-arrow-right"
                    @click="rightInputValue(inclNameArr, i)"></el-button>
                  <!-- {{ tag }} -->
                  </el-tag
                >
                <el-input
                  class="input-new-tag"
                  v-if="inclNameInputVisible"
                  v-model="inclNameInputValue"
                  ref="inclNameSaveTagInput"
                  size="small"
                  @keyup.enter.native="
                    handleInputConfirm(
                      'inclNameInputValue',
                      'inclNameArr',
                      'inclNameInputVisible'
                    )
                  "
                  @blur="
                    handleInputConfirm(
                      'inclNameInputValue',
                      'inclNameArr',
                      'inclNameInputVisible'
                    )
                  "
                ></el-input>
                <el-button
                  v-else
                  class="button-new-tag"
                  size="small"
                  @click="
                    showInput('inclNameInputVisible', 'inclNameSaveTagInput')
                  "
                  >+ 新增Incl名称</el-button
                >
              </div>
            </div>
          </div>
          <div style="height: auto">
            <!-- Botanical Name== -->
            <!-- <span>Botanical Name：</span> -->
            <span>植物学名：</span>
            <div style="display: inline-block; width: 615px; text-align: left">
              <div
                style="
                  display: flex;
                  justify: flex-start;
                  flex-wrap: wrap;
                  align-items: center;
                "
              >
                <el-tag
                  :key="i"
                  v-for="(tag, i) in botanicalNameArr"
                  closable
                  :disable-transitions="false"
                  @close="handleClose(tag, 'botanicalNameArr','cn-power')"
                  >
                    <span @click="tagHandle(botanicalNameArr, tag, i)" style="cursor: pointer;">{{ tag }}</span>
                    <el-button type="primary" style="padding: 0 2px;margin-left:5px;" size="small" 
                    @click="showIndexInput('botanicalNameInputVisible', 'botanicalNameSaveTagInput',i,'botanicalNameArrIndex')">+</el-button>
                    <el-button v-show="i>0" type="primary" style="padding: 0;" size="small" icon="el-icon-arrow-left"
                    @click="leftInputValue(botanicalNameArr, i)"></el-button>
                    <el-button type="primary" style="padding: 0;" size="small" icon="el-icon-arrow-right"
                    @click="rightInputValue(botanicalNameArr, i)"></el-button>
                  </el-tag
                >
                <el-input
                  class="input-new-tag"
                  v-if="botanicalNameInputVisible"
                  v-model="botanicalNameInputValue"
                  ref="botanicalNameSaveTagInput"
                  size="small"
                  @keyup.enter.native="
                    handleInputConfirm(
                      'botanicalNameInputValue',
                      'botanicalNameArr',
                      'botanicalNameInputVisible','cn-power'
                    )
                  "
                  @blur="
                    handleInputConfirm(
                      'botanicalNameInputValue',
                      'botanicalNameArr',
                      'botanicalNameInputVisible','cn-power'
                    )
                  "
                ></el-input>
                <el-button
                  :disabled="botanicalNameD"
                  v-else
                  class="button-new-tag"
                  size="small"
                  @click="
                    showInput(
                      'botanicalNameInputVisible',
                      'botanicalNameSaveTagInput'
                    )
                  "
                  >+ 新增植物学名</el-button
                >
              </div>
            </div>
          </div>
          <div style="height: auto">
            <!-- <span>Common/Other Name：</span> -->
            <span>通用名/其他名称：</span>
            <div style="display: inline-block; width: 615px; text-align: left">
              <div
                style="
                  display: flex;
                  justify: flex-start;
                  flex-wrap: wrap;
                  align-items: center;
                "
              >
                <el-tag
                  :key="i"
                  v-for="(tag, i) in commonOtherNameArr"
                  closable
                  :disable-transitions="false"
                  @close="handleClose(tag, 'commonOtherNameArr')"
                  >
                    <span @click="tagHandle(commonOtherNameArr, tag, i)" style="cursor: pointer;">{{ tag }}</span>
                    <el-button type="primary" style="padding: 0 2px;margin-left:5px;" size="small" 
                    @click="showIndexInput('commonOtherNameInputVisible', 'commonOtherNameSaveTagInput',i,'commonOtherNameArrIndex')">+</el-button>
                    <el-button v-show="i>0" type="primary" style="padding: 0;" size="small" icon="el-icon-arrow-left"
                    @click="leftInputValue(commonOtherNameArr, i)"></el-button>
                    <el-button type="primary" style="padding: 0;" size="small" icon="el-icon-arrow-right"
                    @click="rightInputValue(commonOtherNameArr, i)"></el-button>
                  </el-tag
                >
                <el-input
                  class="input-new-tag"
                  v-if="commonOtherNameInputVisible"
                  v-model="commonOtherNameInputValue"
                  ref="commonOtherNameSaveTagInput"
                  size="small"
                  @keyup.enter.native="
                    handleInputConfirm(
                      'commonOtherNameInputValue',
                      'commonOtherNameArr',
                      'commonOtherNameInputVisible'
                    )
                  "
                  @blur="
                    handleInputConfirm(
                      'commonOtherNameInputValue',
                      'commonOtherNameArr',
                      'commonOtherNameInputVisible'
                    )
                  "
                ></el-input>
                <el-button
                  :disabled="commonOtherNameD"
                  v-else
                  class="button-new-tag"
                  size="small"
                  @click="
                    showInput(
                      'commonOtherNameInputVisible',
                      'commonOtherNameSaveTagInput'
                    )
                  "
                  >+ 新增通用名/其他名称</el-button
                >
              </div>
            </div>
          </div>
          <div style="height: auto">
            <!-- <span>Part Used：</span> -->
            <span>使用部位：</span>
            <div style="display: inline-block; width: 615px; text-align: left">
              <div
                style="
                  display: flex;
                  justify: flex-start;
                  flex-wrap: wrap;
                  align-items: center;
                "
              >
                <el-tag
                  :key="i"
                  v-for="(tag, i) in partUsedArr"
                  closable
                  :disable-transitions="false"
                  @close="handleClose(tag, 'partUsedArr')"
                  @change="test1()"
                  >
                    <span @click="tagHandle(partUsedArr, tag, i)" style="cursor: pointer;">{{ tag }}</span>
                    <el-button type="primary" style="padding: 0 2px;margin-left:5px;" size="small" 
                    @click="showIndexInput('partUsedInputVisible', 'partUsedSaveTagInput',i,'partUsedArrIndex')">+</el-button>
                    <el-button v-show="i>0" type="primary" style="padding: 0;" size="small" icon="el-icon-arrow-left"
                    @click="leftInputValue(partUsedArr, i)"></el-button>
                    <el-button type="primary" style="padding: 0;" size="small" icon="el-icon-arrow-right"
                    @click="rightInputValue(partUsedArr, i)"></el-button>
                  </el-tag
                >
                <el-input
                  class="input-new-tag"
                  v-if="partUsedInputVisible"
                  v-model="partUsedInputValue"
                  ref="partUsedSaveTagInput"
                  size="small"
                  @keyup.enter.native="
                    handleInputConfirm(
                      'partUsedInputValue',
                      'partUsedArr',
                      'partUsedInputVisible'
                    )
                  "
                  @blur="
                    handleInputConfirm(
                      'partUsedInputValue',
                      'partUsedArr',
                      'partUsedInputVisible'
                    )
                  "
                ></el-input>
                <el-button
                  :disabled="partUsedD"
                  v-else
                  class="button-new-tag"
                  size="small"
                  @click="
                    showInput('partUsedInputVisible', 'partUsedSaveTagInput')
                  "
                  >+ 新增使用部位</el-button
                >
              </div>
            </div>
          </div>
          <div style="height: auto">
              <span>原产地：</span>
              
              <el-input
                v-model="params.countryOfOrigin"
                placeholder="请输入"
              ></el-input>
            </div>
          <div style="height: auto">
            <!-- <span>Percentage ：</span> -->
            <span>百分比 ：</span>
            <div style="display: inline-block; width: 615px; text-align: left">
              <div
                style="
                  display: flex;
                  justify: flex-start;
                  flex-wrap: wrap;
                  align-items: center;
                "
              >
                <!-- (tag,i) in ingredientsTags(2) -->
                <el-tag
                  :key="i"
                  v-for="(tag, i) in percentageArr"
                  closable
                  :disable-transitions="false"
                  @close="handleClose(tag, 'percentageArr')"
                  >
                    <span @click="tagHandle(percentageArr, tag, i)" style="cursor: pointer;">{{ tag }}</span>
                    <el-button type="primary" style="padding: 0 2px;margin-left:5px;" size="small" 
                    @click="showIndexInput('percentageInputVisible', 'percentageSaveTagInput',i,'percentageArrIndex')">+</el-button>
                    <el-button v-show="i>0" type="primary" style="padding: 0;" size="small" icon="el-icon-arrow-left"
                    @click="leftInputValue(percentageArr, i)"></el-button>
                    <el-button type="primary" style="padding: 0;" size="small" icon="el-icon-arrow-right"
                    @click="rightInputValue(percentageArr, i)"></el-button>
                  </el-tag
                >
                <el-input
                  class="input-new-tag"
                  v-if="percentageInputVisible"
                  v-model="percentageInputValue"
                  ref="percentageSaveTagInput"
                  size="small"
                  @keyup.enter.native="
                    handleInputConfirm(
                      'percentageInputValue',
                      'percentageArr',
                      'percentageInputVisible'
                    )
                  "
                  @blur="
                    handleInputConfirm(
                      'percentageInputValue',
                      'percentageArr',
                      'percentageInputVisible'
                    )
                  "
                ></el-input>
                <el-button
                  v-else
                  class="button-new-tag"
                  size="small"
                  @click="
                    showInput(
                      'percentageInputVisible',
                      'percentageSaveTagInput'
                    )
                  "
                  >+ 新增百分比</el-button
                >
              </div>
            </div>
          </div>
          <div style="height: auto">
            <div style="display: inline-block; text-align: left">
            <table style="border: 1px solid #eee;">
              <tr>
                <td style="border: 1px solid #eee;">
                  <el-input placeholder="请输入内容" style="width: 100%" v-model="params.tableInput1"></el-input>
                </td>
                <td style="border: 1px solid #eee;">
                  <el-input placeholder="请输入内容" style="width: 100%" v-model="params.tableInput2"></el-input>
                </td>
                <td style="border: 1px solid #eee;">
                  <el-input placeholder="请输入内容" style="width: 100%" v-model="params.tableInput3"></el-input>
                </td>
                <td style="border: 1px solid #eee;">
                  <el-input placeholder="请输入内容" style="width: 100%" v-model="params.tableInput4"></el-input>
                </td>
                <td style="border: 1px solid #eee;">
                  <el-input placeholder="请输入内容" style="width: 100%" v-model="params.tableInput5"></el-input>
                </td>
                <td>
                  <el-button type="primary" class="button-new-tag" size="small" @click="addTableInput(1, 0)">+</el-button>
                </td>
              </tr>
              <tr v-for="(item, tableInputIndex) in tableInputCustomFieldAttr" :key="tableInputIndex + 1">
                <td style="border: 1px solid #eee;">
                  <el-input placeholder="请输入内容" style="width: 100%" v-model="item.tableInput1"></el-input>
                </td>
                <td style="border: 1px solid #eee;">
                  <el-input placeholder="请输入内容" style="width: 100%" v-model="item.tableInput2"></el-input>
                </td>
                <td style="border: 1px solid #eee;">
                  <el-input placeholder="请输入内容" style="width: 100%" v-model="item.tableInput3"></el-input>
                </td>
                <td style="border: 1px solid #eee;">
                  <el-input placeholder="请输入内容" style="width: 100%" v-model="item.tableInput4"></el-input>
                </td>
                <td style="border: 1px solid #eee;">
                  <el-input placeholder="请输入内容" style="width: 100%" v-model="item.tableInput5"></el-input>
                </td>
                <td>
                  <el-button type="primary" class="button-new-tag" size="small" @click="addTableInput(1, 0)">+</el-button>
                  <el-button type="danger" class="button-new-tag" size="small" @click="addTableInput(0, tableInputIndex)">-</el-button>
                </td>
              </tr>
            </table>
            </div>
          </div>
          <div style="clear: both; height: 10px"></div>
          <div>
            <!-- <span>Extraction Ratio：</span> -->
            <span>提取率：</span>
            <el-input
              v-model="params.extractionRatio"
              placeholder="请输入提取率"
            ></el-input>
          </div>
          <div>
            <!-- <span>Important Constituents In Plant：</span> -->
            <span>植物主要成分：</span>
            <el-input
              v-model="params.importantConstitueInPlant"
              placeholder="请输入植物主要成分"
            ></el-input>
          </div>
          <div>
            <!-- <span>Appearance：</span> -->
            <span>外观：</span>
            <el-select
              v-model="params.appearance"
              placeholder="请选择外观"
              clearable
            >
              <el-option
                v-for="(item, i) in appearanceList"
                :key="i"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <div>
            <!-- <span>Solubility：</span> -->
            <span>溶解度：</span>
            <el-input
              v-model="params.solubility"
              placeholder="Please Input"
            ></el-input>
          </div>
          <div>
            <!-- <span>Homogeneity：</span> -->
            <span>均匀度：</span>
            <div style="width: 600px; display: inline-block; text-align: left">
              完全均匀
            </div>
          </div>
          <div>
            <!-- <span>Extraction Process：</span> -->
            <span>提取过程：</span>
            <el-input
                  v-model="params.ehxtoExtractionProcess"
                  placeholder="请输入"
                ></el-input>
          </div>
          <div>
            <!-- <span>Processing Aid：</span> -->
            <span>加工助剂：</span>
            <el-input
              v-model="params.processingAid"
              placeholder="请输入加工助剂"
            ></el-input>
          </div>
          <div>
            <!-- <span>Solvent for Extraction：</span> -->
            <span>提取溶剂：</span>
            <el-input
              v-model="params.solventForExtracti"
              placeholder="请输入提取溶剂"
            ></el-input>
          </div>
          <div>
            <!-- <span>Carrier：</span> -->
            <span>辅料：</span>
            <el-select
              v-model="params.carrier"
              placeholder="请选择辅料"
              clearable
              filterable
              allow-create
            >
              <el-option
                v-for="(item, i) in carrierList"
                :key="i"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <div>
            <!-- <span>Pesticides：</span> -->
            <span>农药残留：</span>
            <el-input
              v-model="params.pesticides"
              placeholder="请输入农药残留"
            ></el-input>
          </div>
          <div>
            <!-- <span>Certification：</span> -->
            <span>证书：</span>
            <el-input
              v-model="params.certification"
              placeholder="请输入证书"
            ></el-input>
          </div>
            <div>
              <span style="margin-left: 6px">
                <el-input
                  v-model="params.generalSpecificationKey1"
                  placeholder="请输入名称"
                  class="custom-row"
                  style="width: 120px"
                ></el-input
                >：</span
              >
              <div class="custom-row el-input">
                <el-input
                  v-model="params.generalSpecificationValue1"
                  class="custom-row"
                  placeholder="请输入值"
                ></el-input>
              </div>
            </div>
            <div>
              <span style="margin-left: 6px">
                <el-input
                  v-model="params.generalSpecificationKey2"
                  placeholder="请输入名称"
                  class="custom-row"
                  style="width: 120px"
                ></el-input
                >：</span
              >
              <div class="custom-row el-input">
                <el-input
                  v-model="params.generalSpecificationValue2"
                  class="custom-row"
                  placeholder="请输入值"
                ></el-input>
              </div>
            </div>
          
            <div>
              <span style="margin-left: 56px">
                <el-input
                  v-model="params.generalSpecificationKey3"
                  placeholder="请输入名称"
                  class="custom-row"
                  style="width: 120px"
                ></el-input
                >：</span
              >
              <div class="custom-row el-input">
                <el-input
                  v-model="params.generalSpecificationValue3"
                  class="custom-row"
                  placeholder="请输入值"
                ></el-input>
              </div>
              <el-button
                type="primary"
                class="button-new-tag"
                size="small"
                @click="addGenSpecCustomRow(1, 0)"
                >+</el-button>
            </div>
            <div
              v-for="(item, genSpecIndex) in genSpecCustomFieldAttr"
              :key="genSpecIndex + 1"
            >
              <span style="margin-left: 96px">
                <el-input
                  v-model="item.customKey"
                  placeholder="请输入名称"
                  class="custom-row"
                  style="width: 50%"
                ></el-input
                >：</span
              >
              <div class="custom-row el-input">
                <el-input
                  v-model="item.customValue"
                  class="custom-row"
                  placeholder="请输入值"
                ></el-input>
              </div>
              <el-button
                type="primary"
                class="button-new-tag"
                size="small"
                @click="addGenSpecCustomRow(1, genSpecIndex)"
                >+</el-button
              >
              <el-button
                type="danger"
                class="button-new-tag"
                size="small"
                @click="addGenSpecCustomRow(0, genSpecIndex)"
                >-</el-button
              >
            </div>
        </div>
      </div>

      <!-- Analysis -->
      <div class="analysis" v-if="productTypeVisible.normal">
        <div>分析</div>
        <div>
          <div class="specification">
            <div>产品规格</div>
            <div>
              <span>标准1：</span>
              <el-input
                v-model="params.specificationStandard1"
                placeholder="请输入标准"
              ></el-input>
            </div>

            <div>
              <span>标准2：</span>
              <el-input
                v-model="params.specificationStandard2"
                placeholder="请输入标准"
              ></el-input>
            </div>

            <div>
              <span>标准3：</span>
              <el-input
                v-model="params.specificationStandard3"
                placeholder="请输入标准"
              ></el-input>
            </div>

            <div>
              <!-- <span>Mesh Size：</span> -->
              <span>过筛尺寸：</span>
              <el-select
                v-model="params.specificationMeshSize"
                placeholder="请选择过筛尺寸"
                clearable
                filterable
                allow-create
              >
                <el-option
                  v-for="(item, i) in meshSizeList"
                  :key="i"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>

            <div style="display: flex; align-items: center">
              <!-- <span>Loss on Drying：</span> -->
              <span>干燥失重：</span>
              <el-input
                onkeyup="value=value.replace(/[^\d.]|/g,'')"
                v-model="params.specificationLossonDrying"
                placeholder="Please Input"
              >
                <template slot="prepend">
                  <div style="width: 100%">&lt;</div>
                </template>
                <template slot="append">
                  <div style="width: 100%">%</div>
                </template>
              </el-input>
            </div>

            <div style="display: flex; align-items: center">
              <!-- <span>Bulk Density：</span> -->
              <span>堆密度：</span>
              <el-input
                v-model="params.specificationBulkDensity"
                placeholder="请输入堆密度"
              >
                <template slot="append">
                  <div style="width: 100%">g/mL</div>
                </template>
              </el-input>
            </div>

            <div>
              <!-- <span>Identification：</span> -->
              <span>HPTLC鉴定：</span>
              <div style="width: 40%; display: inline-block; text-align: left">
                合格
              </div>
            </div>

            <div style="display: flex; align-items: center">
              <!-- <span>Total Heavy Metals：</span> -->
              <span>总重金属：</span>
              <el-input
                v-model="params.specificationTotalHeavyMetals"
                placeholder="请输入总重金属"
              >
                <template slot="prepend">
                  <div style="width: 100%">&lt;</div>
                </template>
                <template slot="append">
                  <div style="width: 100%">ppm</div>
                </template>
              </el-input>
            </div>

            <div style="display: flex; align-items: center">
              <!-- <span>Arsenic：</span> -->
              <span>砷：</span>
              <el-input
                v-model="params.specificationArsenic"
                placeholder="请输入砷"
              >
                <!-- <template slot="prepend">
                  <div style="width:100%;">&lt;</div>
                </template> -->
                <template slot="append">
                  <div style="width: 100%">ppm</div>
                </template>
              </el-input>
            </div>
            <div style="display: flex; align-items: center">
              <!-- <span>Cadmium：</span> -->
              <span>镉：</span>
              <el-input
                v-model="params.specificationCadmium"
                placeholder="请输入镉"
              >
                <!-- <template slot="prepend">
                  <div style="width:100%;">&lt;</div>
                </template> -->
                <template slot="append">
                  <div style="width: 100%">ppm</div>
                </template>
              </el-input>
            </div>
            <div style="display: flex; align-items: center">
              <!-- <span>Lead：</span> -->
              <span>铅：</span>
              <el-input
                v-model="params.specificationLead"
                placeholder="请输入铅"
              >
                <!-- <template slot="prepend">
                  <div style="width:100%;">&lt;</div>
                </template> -->
                <template slot="append">
                  <div style="width: 100%">ppm</div>
                </template>
              </el-input>
            </div>
            <div style="display: flex; align-items: center">
              <!-- <span>Mercury：</span> -->
              <span>汞：</span>
              <el-input
                v-model="params.specificationMercury"
                placeholder="请输入汞"
              >
                <!-- <template slot="prepend">
                  <div style="width:100%;">&lt;</div>
                </template> -->
                <template slot="append">
                  <div style="width: 100%">ppm</div>
                </template>
              </el-input>
            </div>
            
            <div>
              <span>
              <el-input
                v-model="params.specificationCustomerKey1"
                placeholder="请输入"
                style="width:120px"
              ></el-input>：</span>
              <el-input
                v-model="params.specificationCustomerValue1"
                placeholder="请输入"
              ></el-input>
            </div>            
            <div>
              <span>
              <el-input
                v-model="params.specificationCustomerKey2"
                placeholder="请输入"
                style="width:120px"
              ></el-input>：</span>
              <el-input
                v-model="params.specificationCustomerValue2"
                placeholder="请输入"
              ></el-input>
            </div>
            <div>
              <span>
              <el-input
                v-model="params.specificationCustomerKey3"
                placeholder="请输入"
                style="width:120px"
              ></el-input>：</span>
              <el-input
                v-model="params.specificationCustomerValue3"
                placeholder="请输入"
              ></el-input>
            </div>
            <div>
              <span>
              <el-input
                v-model="params.specificationCustomerKey4"
                placeholder="请输入"
                style="width:120px"
              ></el-input>：</span>
              <el-input
                v-model="params.specificationCustomerValue4"
                placeholder="请输入"
              ></el-input>
            </div>
            <div>
              <span>
              <el-input
                v-model="params.specificationCustomerKey5"
                placeholder="请输入"
                style="width:120px"
              ></el-input>：</span>
              <el-input
                v-model="params.specificationCustomerValue5"
                placeholder="请输入"
              ></el-input>
            </div>
            <div
                v-for="(item, analysisIndexEhxto) in analysisCustomFieldAttr"
                :key="analysisIndexEhxto" >
              <span>
              <el-input
                v-model="item.customKey"
                placeholder="请输入"
                style="width:120px"
              ></el-input>：</span>
              <el-input
                v-model="item.customValue"
                placeholder="请输入"
              ></el-input>
            </div>

            <div>
              <span>颜色：</span>
              <el-select
              v-model="params.specificationColor"
              placeholder="请选择颜色"
              clearable
              filterable
              allow-create
            >
              <el-option
                v-for="(item, i) in colorList"
                :key="i"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            </div>

            <div>
              <span>气味：</span>
              <el-input
                v-model="params.specificationOrdor"
                placeholder="请输入气味"
              ></el-input>
            </div>

            <div>
              <span>味道：</span>
              <el-input
                v-model="params.specificationTaste"
                placeholder="请输入味道"
              ></el-input>
            </div>
          </div>
          <div class="test-method">
            <div>测试方法</div>
            <div>
              <span>标准1：</span>
              <el-input
                v-model="params.testMethodStandard1"
                placeholder="请输入标准"
              ></el-input>
            </div>

            <div>
              <span>标准2：</span>
              <el-input
                v-model="params.testMethodStandard2"
                placeholder="请输入标准"
              ></el-input>
            </div>

            <div>
              <span>标准3：</span>
              <el-input
                v-model="params.testMethodStandard3"
                placeholder="请输入标准"
              ></el-input>
            </div>

            <div>
              <!-- <span>MESH：</span> -->
              <span>过筛尺寸：</span>
              <div style="width: 40%; display: inline-block; text-align: left">
                筛子
              </div>
            </div>
            <div>
              <span>干燥失重：</span>
              <div style="width: 40%; display: inline-block; text-align: left">
                Draco-1.1.1.0
              </div>
            </div>
            <div>
              <span>堆密度：</span>
              <div style="width: 40%; display: inline-block; text-align: left">
                USP &lt;616&gt;
              </div>
            </div>
            <div>
              <span>HPTLC鉴定：</span>
              <div style="width: 40%; display: inline-block; text-align: left">
                标准感光板
              </div>
            </div>

            <div>
              <span>总重金属：</span>
              <div style="width: 40%; display: inline-block; text-align: left">
                USP &lt;231&gt;
              </div>
            </div>

            <div>
              <span>砷：</span>
              <el-input
                v-model="params.testMethodArsenic"
                placeholder="请输入砷"
              ></el-input>
            </div>
            <div>
              <span>镉：</span>
              <el-input
                v-model="params.testMethodCadmium"
                placeholder="请输入镉"
              ></el-input>
            </div>
            <div>
              <span>铅：</span>
              <el-input
                v-model="params.testMethodLead"
                placeholder="请输入铅"
              ></el-input>
            </div>
            <div>
              <span>汞：</span>
              <el-input
                v-model="params.testMethodMercury"
                placeholder="请输入汞"
              ></el-input>
            </div>

            <div>
              <span>{{params.specificationCustomerKey1}}：</span>
              <el-input
                v-model="params.testMethodCustomerValue1"
                placeholder="请输入"
              ></el-input>
            </div>
            <div>
              <span>{{params.specificationCustomerKey2}}：</span>
              <el-input
                v-model="params.testMethodCustomerValue2"
                placeholder="请输入"
              ></el-input>
            </div>
            <div>
              <span>{{params.specificationCustomerKey3}}：</span>
              <el-input
                v-model="params.testMethodCustomerValue3"
                placeholder="请输入"
              ></el-input>
            </div>
            <div>
              <span>{{params.specificationCustomerKey4}}：</span>
              <el-input
                v-model="params.testMethodCustomerValue4"
                placeholder="请输入"
              ></el-input>
            </div>
            <div>
              <span>{{params.specificationCustomerKey5}}：</span>
              <el-input
                v-model="params.testMethodCustomerValue5"
                placeholder="请输入"
              ></el-input>
                <el-button
                  style="margin-top: 14px"
                  type="primary"
                  class="button-new-tag"
                  size="small"
                  @click="addAnalysisCustomRow(1, 0)"
                  >+</el-button>
            </div>
            <div
                v-for="(item, analysisIndexEhxto) in analysisCustomFieldAttr"
                :key="analysisIndexEhxto" >
              <span>{{item.customKey}}：</span>
              <el-input
                v-model="item.customValue1"
                placeholder="请输入"
              ></el-input>
                <el-button
                  style="margin-top: 14px"
                  type="primary"
                  class="button-new-tag"
                  size="small"
                  @click="addAnalysisCustomRow(1, analysisIndexEhxto)"
                  >+</el-button>
                <el-button
                  style="margin-top: 14px"
                  type="danger"
                  class="button-new-tag"
                  size="small"
                  @click="addAnalysisCustomRow(0, analysisIndexEhxto)"
                  >-</el-button
                >
            </div>
            <div>
              <span>颜色：</span>
              <div style="width: 40%; display: inline-block; text-align: left">
                视觉
              </div>
            </div>
            <div>
              <span>气味：</span>
              <el-input
                v-model="params.testMethodOrdor"
                placeholder="请输入"
              ></el-input>
            </div>
            <div>
              <span>味道：</span>
              <el-input
                v-model="params.testMethodTaste"
                placeholder="请输入"
              ></el-input>
            </div>
          </div>
        </div>
      </div>

      <!-- Microbiological Tests -->
      <div
        class="microbiological-tests"
        v-if="
          productTypeVisible.normal || productTypeVisible.microbiologicalShow
        "
      >
        <div>微生物检验</div>
        <div>
          <div class="specification">
            <div>产品规格</div>
            <div style="display: flex; align-items: center">
              <!-- <span>Total Plate Count：</span> -->
              <span>细菌总数：</span>
              <!-- <el-input
                v-if="params.productType == 'CSFMU' || params.productType == ''"
                v-model="params.specificationTotalPlateCount"
                placeholder="Please Input"
              ></el-input>
              <div
                v-else
                style="width: 40%;display:inline-block;text-align:left"
              >{{showTotalPlateCount}}</div>-->
              <el-input
                onkeyup="value=value.replace(/[^\d.]|/g,'')"
                v-model="params.specificationTotalPlateCount"
                placeholder="请输入细菌总数"
              >
                <template slot="prepend">
                  <div style="width: 100%">&lt;</div>
                </template>
                <template slot="append">
                  <div style="width: 100%">cfu / g</div>
                </template>
              </el-input>
            </div>
            <div style="display: flex; align-items: center">
              <!-- <span>Yeast and Mold：</span> -->
              <span>酵母和霉菌：</span>
              <!-- <div style="width: 40%;display:inline-block;text-align:left">&lt;100/g</div>
              -->
              <el-input
                onkeyup="value=value.replace(/[^\d.]|/g,'')"
                :readonly="specificationYeastAndMoldD"
                v-model="params.specificationYeastAndMold"
                placeholder="请输入酵母和霉菌"
              >
                <template slot="prepend">
                  <div style="width: 100%">&lt;</div>
                </template>
                <template slot="append">
                  <div style="width: 100%">cfu / g</div>
                </template>
              </el-input>
            </div>
            <div>
              <!-- <span>E. Coli：</span> -->
              <span>大肠杆菌：</span>
              <el-input
                v-model="params.specificationEcoli"
                placeholder="请输入大肠杆菌"
              ></el-input>
            </div>
            <div>
              <!-- <span>Salmonella：</span> -->
              <span>沙门氏菌：</span>
              <el-input
                v-model="params.specificationSalmonella"
                placeholder="请输入沙门氏菌"
              ></el-input>
            </div>
            <div>
              <!-- <span>Staph Aureus：</span> -->
              <span>金色葡萄球菌：</span>
              <el-input
                v-model="params.specificationStaphAureus"
                placeholder="请输入金色葡萄球菌"
              ></el-input>
            </div>
            <div style="display: flex; align-items: center">
              <span>大肠菌群：</span>
              <el-input
                @blur="checkSpecificationTotalColiformscnpower()"
                v-model="params.specificationTotalColiforms"
                placeholder="请输入大肠菌群"
              >
                <template slot="prepend">
                  <div style="width: 100%">&lt;</div>
                </template>
                <template slot="append">
                  <div style="width: 100%">MPN/g</div>
                </template>
              </el-input>
            </div>
            <div>
              <span>
              <el-input
                v-model="params.microbiologicalCustomerKey1"
                placeholder="请输入"
                style="width:120px"
              ></el-input>：</span>
              <el-input
                v-model="params.microbiologicalCustomerValue1"
                placeholder="请输入"
              ></el-input>
            </div>
            
            <div
             v-for="(item, micTestsIndex) in micTestsCustomFieldAttr"
                :key="micTestsIndex" >
              <span>
              <el-input
                v-model="item.customKey"
                placeholder="请输入"
                style="width:120px"
              ></el-input>：</span>
              <el-input
                v-model="item.customValue"
                placeholder="请输入"
              ></el-input>
            </div>
          </div>
          <div class="test-method">
            <div>测试方法</div>
            <div>
              <span>细菌总数：</span>
              <div style="width: 40%; display: inline-block; text-align: left">
                FDA-BAM
              </div>
            </div>
            <div>
              <span>酵母和霉菌：</span>
              <div style="width: 40%; display: inline-block; text-align: left">
                FDA-BAM
              </div>
            </div>
            <div>
              <span>大肠杆菌：</span>
              <div style="width: 40%; display: inline-block; text-align: left">
                FDA-BAM
              </div>
            </div>
            <div>
              <span>沙门氏菌：</span>
              <div style="width: 40%; display: inline-block; text-align: left">
                FDA-BAM
              </div>
            </div>
            <div>
              <span>金色葡萄球菌：</span>
              <div style="width: 40%; display: inline-block; text-align: left">
                FDA-BAM
              </div>
            </div>
            <div>
              <span>大肠菌群：</span>
              <div style="width: 40%; display: inline-block; text-align: left">
                FDA-BAM
              </div>
            </div>
            <div>
              <span>{{params.microbiologicalCustomerKey1}}：</span>
              <el-input
                v-model="params.microbiologicalTestMethodCustomerValue1"
                placeholder="请输入"
              ></el-input>
                <el-button
                  style="margin-top: 14px"
                  type="primary"
                  class="button-new-tag"
                  size="small"
                  @click="addMicTestsCustomRow(1, 0)"
                  >+</el-button
                >
            </div>
            <div
             v-for="(item, micTestsIndex) in micTestsCustomFieldAttr"
                :key="micTestsIndex" >
              <span>{{item.customKey}}：</span>
              <el-input
                v-model="item.customValue1"
                placeholder="请输入"
              ></el-input>
                <el-button
                  style="margin-top: 14px"
                  type="primary"
                  class="button-new-tag"
                  size="small"
                  @click="addMicTestsCustomRow(1, micTestsIndex)"
                  >+</el-button
                >
                <el-button
                  style="margin-top: 14px"
                  type="danger"
                  class="button-new-tag"
                  size="small"
                  @click="addMicTestsCustomRow(0, micTestsIndex)"
                  >-</el-button
                >
            </div>
          </div>
        </div>
      </div>
      <!-- Other Notes -->
      <div class="other-notes">
        <div>其他</div>
          <div>
            <span>保质期：</span>
            <el-select
              v-model="params.shelflifeNumber"
              placeholder="保质期"
              clearable
              filterable
              allow-create
            >
              <el-option
                v-for="(item, i) in shelflifeList"
                :key="i"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
        <div>
          <!-- <span>Shelf Life：</span> -->
          <span>保质期备注：</span>
          <!-- <span>Three years unopened. Product is sensitive to heat and moisture.</span> -->
          <el-input
            type="textarea"
            :rows="5"
            resize="none"
            style="width: 600px"
            placeholder="Please Input"
            v-model="params.shelflife"
          ></el-input>
        </div>
        <div>
          <!-- <span>Storage：</span> -->
          <span>储存条件：</span>
          <el-input
            v-model="params.storage"
            placeholder="Please Input"
          ></el-input>
        </div>
      </div>
      <div>
        <el-form :inline="true" class="demo-form-inline">
          <div v-for="(item, index) in noteTableData" :key="index">
            <el-form-item label="修订日期">
              <el-input
                v-model="item.revisionDate"
                placeholder="修订日期"
                style="width: 140px"
                :disabled="item.isComplete"
              ></el-input>
            </el-form-item>
            <el-form-item label="提出修订人">
              <el-input
                v-model="item.requestedBy"
                placeholder="提出修订人"
                style="width: 140px"
                :disabled="item.isComplete"
              ></el-input>
            </el-form-item>
            <el-form-item label="修订项目">
              <el-input
                v-model="item.itemsRevised"
                placeholder="修订项目"
                style="width: 140px"
                :disabled="item.isComplete"
              ></el-input>
            </el-form-item>
            <el-form-item label="修订人">
              <el-input
                v-model="item.editedBy"
                placeholder="修订人"
                style="width: 140px"
                :disabled="item.isComplete"
              ></el-input>
            </el-form-item>
            <el-form-item label="修订原因">
              <el-input
                v-model="item.reason"
                placeholder="修订原因"
                style="width: 140px"
                :disabled="item.isComplete"
              ></el-input>
            </el-form-item>
            <el-form-item label="批准人">
              <el-input
                v-model="item.approvedBy"
                placeholder="批准人"
                style="width: 140px"
                disabled="disabled"
              ></el-input>
            </el-form-item>
            <!-- <el-form-item style="width: 120px">
              <el-button type="text" @click="addNoteTableClick(index,'cn')" 
                v-if="item.revisionDate && item.requestedBy && item.itemsRevised && item.editedBy && item.reason && item.approvedBy"
              >Complete</el-button>
              &nbsp;&nbsp;
              <el-button type="text"
                v-if="!item.isComplete && noteTableData.length>1" @click="removeNoteTableClick(index)">
                <i style="color:Red;font-size:18px;" class="el-icon-delete"></i>
              </el-button>
            </el-form-item> -->
          </div>
        </el-form>
      </div>

        <div class="other-notes">
          <div>Notes</div>
          <div style="margin-top:0px;">
            <el-input v-model="newNote" placeholder="Please Input" style="width:600px;margin-bottom:10px;margin-right:10px;"></el-input>
            <el-button type="text" @click="addNewNoteTableClick">
              Complete
            </el-button>
          </div>
        </div>
        <div class="base-info">
          <div>注释:</div>
          <div style="width: 80%; text-align: left; font-weight: bold">
            <div>
              * 不同批次产品的颜色可能会因天然原材料的自然变化而产生差异。
            </div>
            <div style="color:Red;">
              <el-checkbox v-model="params.isShowNotes2" label="打印是否显示"></el-checkbox>* 产品含抗氧化化合物，可能会随着时间推移颜色变深但不影响使用效果。
            </div>
            <div>
              * 必要情况下，产品的pH 值应该在1%该产品的水溶液中测定。
            </div>
            <div v-for="(item, index) in newNoteArr" :key="index" style="margin-top:0px;">
              * {{item.content}}
              <el-button type="text" @click="deleteNewNoteTableClick(index)">
                <i style="color:red;font-size:20px;" class="el-icon-delete"></i>
              </el-button>
            </div>
          </div>
        </div>
      <!-- EHXTO OTHER ONTES -->
      <!-- buttons -->
      <div>
        <el-checkbox v-model="isSignaturePrint" @change="changeSignatureHandle">打印客户确认</el-checkbox>
      </div>
      <div>
          <el-button v-if="userInfo.operateStatus && params.productId!=0 && params.verifiedList==0" type="warning" @click="handleCnSpecNew('Continue Edit',1)">新增至 Verify List</el-button>
        <el-button v-if="userInfo.operateStatus" type="primary" @click="handleCnSpecNew('Continue Edit')">保存</el-button>
        <el-button v-if="userInfo.operateStatus" type="primary" @click="handleCnSpecNew('New')">保存并新增</el-button>
        <el-button
          v-if="cnpemission(userInfo.username) && isShowVertify && userInfo.operateStatus"
          type="warning"
          @click="cnvertify(formData.id,'powder')"
          >审核</el-button>
        <el-button @click="backcn">返回首页</el-button>
        <el-button type="success" icon="el-icon-printer" @click="printPage()">打印</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import base from "@/common/base.js";
import TYP from "../PrintProductTypeModule/Cn-TY-P.vue";
import newItemMethod from "../module/NewItemMethod";

export default {
  props: ["baseInfoDetail"],
  name: "cnnew-item",
  mixins: [base],
  components: { TYP },
  data() {
    return {
      isShowVertify:false,
      newItemType:"New",
      isSignaturePrint: false,
      printShow: false,
      productTypeVisible: {
        normal: true,
        shxto: false,
        liquidExt: false,
        ehxto: false,
      },
      noteTableData: [
        {
          revisionDate: "",
          requestedBy: "",
          itemsRevised: "",
          editedBy: "",
          approvedBy: "",
          reason:"",
          isComplete:false,
        },
      ],
      newNoteArr:[],
      newNote:"",
      colorList:[
        { label: "标准", value: "标准" },
        { label: "白色--类白色", value: "白色--类白色" },
        { label: "类白色--浅黄色", value: "类白色--浅黄色" },
        { label: "白色--灰色", value: "白色--灰色" },
        { label: "浅黄色--黄色", value: "浅黄色--黄色" },
        { label: "黄色--橘红色", value: "黄色--橘红色" },
        { label: "浅黄色--黄棕色", value: "浅黄色--黄棕色" },
        { label: "浅黄棕色--深黄棕色", value: "浅黄棕色--深黄棕色" },
        { label: "浅棕黄色--深棕黄色", value: "浅棕黄色--深棕黄色" },
        { label: "浅棕色--深棕色", value: "浅棕色--深棕色" },
        { label: "浅粉色--粉红色", value: "浅粉色--粉红色" },
        { label: "浅绿色--黄绿色", value: "浅绿色--黄绿色" },
        { label: "浅棕红色--深棕红色", value: "浅棕红色--深棕红色" },
        { label: "浅红棕色--深红棕色", value: "浅红棕色--深红棕色" },
        { label: "浅紫色--深紫色", value: "浅紫色--深紫色" },
        { label: "紫红色--紫黑色", value: "紫红色--紫黑色" },
        { label: "黑色", value: "黑色" },
      ],
      shelflifeList: [
        { label: "3个月", value: "3" },
        { label: "6个月", value: "6" },
        { label: "12个月", value: "12" },
        { label: "18个月", value: "18" },
        { label: "24个月", value: "24" },
        { label: "36个月", value: "36" }
      ],
      commonNameArr: [],
      commonNameInputVisible: "",
      commonNameInputValue: "",
      commonNameD: false,
      commonNameArrIndex:-1,

      preservativeArr: [],
      preservativeInputVisible: "",
      preservativeInputValue: "",
      preservativeArrIndex:-1,

      majorConstituentsInNativeHerbsArr: [],
      majorConstituentsInNativeHerbsInputVisible: "",
      majorConstituentsInNativeHerbsInputValue: "",
      majorConstituentsInNativeHerbsArrIndex:-1,

      processingArr: [],
      processingInputVisible: "",
      processingInputValue: "",
      processingArrIndex:-1,

      ingredientsArr: [],
      inputVisible: "",
      inputValue: "",
      ingredientsArrIndex:-1,

      inclNameArr: [],
      inclNameInputVisible: "",
      inclNameInputValue: "",
      inclNameArrIndex:-1,

      botanicalNameArr: [],
      botanicalNameInputVisible: "",
      botanicalNameInputValue: "",
      botanicalNameArrIndex:-1,

      commonOtherNameArr: [],
      commonOtherNameInputVisible: "",
      commonOtherNameInputValue: "",
      commonOtherNameArrIndex:-1,

      partUsedArr: [],
      partUsedInputVisible: "",
      partUsedInputValue: "",
      partUsedArrIndex:-1,

      percentageArr: [],
      percentageInputVisible: "",
      percentageInputValue: "",
      percentageArrIndex:-1,

      sWidth: 0,
      // Powder, Granular
      appearance: "",
      appearanceList: [
        { label: "粉末", value: "粉末" },
        { label: "颗粒", value: "颗粒" },
      ],
      //  None; Maltodextrin
      carrier: "",
      carrierList: [
        { label: "无", value: "无" },
        { label: "麦芽糊精", value: "麦芽糊精" },
      ],

      carrierSolvent: "",
      carrierSolventList: [
        { label: "Glycerin", value: "Glycerin" },
        { label: "Butylene glycol", value: "Butylene glycol" },
        { label: "Propylene glycol", value: "Propylene glycol" },
      ],
      // 80-100 Mesh、 20 Mesh、 10 Mesh
      meshSize: "",
      meshSizeList: [
        { label: "80-100 目", value: "80-100 目" },
        { label: "20 目", value: "20 目" },
        { label: "10 目", value: "10 目" },
        { label: ">95% pass 80 目", value: ">95% pass 80 目" },
      ],
      revDateTime:base.methods.getLocalNowTime(),
      baseInfoCustomFieldList:[],
      params: {
        baseInfoCustomField:"",
        versionNumber: "",
        revNumber:1,
        revDate:[],
        BaseInfoEditPrintTitle: "上海天源植物制品有限公司",
        BaseInfoEditPrintTitleCheckbox: false,
        BaseInfoEditPrintSubtitle: "TECHNICAL DATA SHEET",
        BaseInfoEditPrintSubtitleCheckbox: false,
        storage: "避光，密封，在干燥处保存。",
        percentage: "",
        liquidExtCustomerKey1: "",
        liquidExtCustomerValue1: "",
        liquidExtCustomerKey2: "",
        liquidExtCustomerValue2: "",
        ehxtoIcip: "",
        ehxtoHomogeneity: "",
        ehxtoExtractionProcess: "可控温度下水萃取，喷雾干燥",
        ehxtoSolventExtraction: "",
        ehxtoCarrier: "",
        ehxtoPesticides: "",
        ehxtoCertification: "",
        ehxtoCustomerKey1: "",
        ehxtoCustomerValue1: "",
        ehxtoTestLimitCustomerKey1: "",
        ehxtoTestLimitCustomerValue1: "",
        ehxtoTestLimitCustomerKey2: "",
        ehxtoTestLimitCustomerValue2: "",
        ehxtoTestLimitCustomerKey3: "",
        ehxtoTestLimitCustomerValue3: "",
        ehxtoTestLimitCustomerKey4: "",
        ehxtoTestLimitCustomerValue4: "",
        ehxtoTestMethodCustomerKey1: "",
        ehxtoTestMethodCustomerValue1: "",
        ehxtoTestMethodCustomerKey2: "",
        ehxtoTestMethodCustomerValue2: "",
        ehxtoTestMethodCustomerKey3: "",
        ehxtoTestMethodCustomerValue3: "",
        ehxtoTestMethodCustomerKey4: "",
        ehxtoTestMethodCustomerValue4: "",
        productSubType: "",
        // 新增字段
        otherNotesRevision: "",

        microbiologicalCustomerKey1: "",
        microbiologicalCustomerValue1: "",
        microbiologicalTestMethodCustomerKey1: "",
        microbiologicalTestMethodCustomerValue1: "",

        specificationCustomerKey1: "",
        specificationCustomerValue1: "",
        testMethodCustomerKey1: "",
        testMethodCustomerValue1: "",

        specificationCustomerKey2: "",
        specificationCustomerValue2: "",
        testMethodCustomerKey2: "",
        testMethodCustomerValue2: "",

        specificationCustomerKey3: "",
        specificationCustomerValue3: "",
        testMethodCustomerKey3: "",
        testMethodCustomerValue3: "",

        specificationCustomerKey4: "",
        specificationCustomerValue4: "",
        testMethodCustomerKey4: "",
        testMethodCustomerValue4: "",

        specificationCustomerKey5: "",
        specificationCustomerValue5: "",
        testMethodCustomerKey5: "",
        testMethodCustomerValue5: "",

        generalSpecificationKey1: "",
        generalSpecificationValue1: "",
        generalSpecificationKey2: "",
        generalSpecificationValue2: "",
        generalSpecificationKey3: "",
        generalSpecificationValue3: "",
        productId: "",
        processingAid: "",
        customKey1: "",
        customValue1: "",
        customKey2: "",
        customValue2: "",
        customKey3: "",
        customValue3: "",
        processing: "",
        countryOfOrigin: "",
        color: "符合标准",
        odor: "天然植物气味",
        taste: "",
        testLimitMoisture: "",
        testMethodMoisture: "",
        testLimitSpecificaGravity: "",
        testMethodSpecificaGravity: "",
        testLimitRefractiveIndex: "",
        testMethodRefractiveIndex: "",
        testLimitOpticalRotation: "",
        testMethodOpticalRotation: "",
        testLimitArsenic: "",
        testMethodArsenic: "USP<232>",
        testLimitCadmium: "",
        testMethodCadmium: "USP<232>",
        testLimitLead: "",
        testMethodLead: "USP<232>",
        testLimitMercury: "",
        testMethodMercury: "USP<232>",
        testLimitAcidValue: "",
        testMethodAcidValue: "",
        testLimitPeroxideValue: "",
        testMethodPeroxideValue: "",
        testLimitSaponificationValue: "",
        testMethodSaponificationValue: "",
        testLimitUnsaponifiableMaster: "",
        testMethodUnsaponifiableMaster: "",
        testLimitMoistureVolatiles: "",
        testMethodMoistureVolatiles: "",
        testLimitPesticides: "",
        testMethodPesticides: "",
        testLimitThcResidue: "",
        testMethodThcResidue: "",
        fattryLimitPalmitica: "",
        fattryMethodPalmitica: "",
        fattryLimitPalmitoleic: "",
        fattryMethodPalmitoleic: "",
        fattryLimitStearic: "",
        fattryMethodStearic: "",
        fattryLimitOleicVaccenic: "",
        fattryMethodOleicVaccenic: "",
        fattryLimitLinoleic: "",
        fattryMethodLinoleic: "",
        fattryLimitALinolenic: "",
        fattryMethodALinolenic: "",
        testLimitCustomKey1: "",
        testLimitCustomKey1Value: "",
        testMethodCustomKey1Value: "",
        testLimitCustomKey2: "",
        testLimitCustomKey2Value: "",
        testMethodCustomKey2Value: "",
        fattryLimitKey1: "",
        fattryLimitKey1Value: "",
        fattryMethodKey1Value: "",
        fattryLimitKey2: "",
        fattryLimitKey2Value: "",
        fattryMethodKey2Value: "",
        fattryLimitKey3: "",
        fattryLimitKey3Value: "",
        fattryMethodKey3Value: "",
        fattryLimitKey4: "",
        fattryLimitKey4Value: "",
        fattryMethodKey4Value: "",
        fattryLimitC8: "",
        fattryMethodC8: "",
        fattryLimitC10: "",
        fattryMethodC10: "",
        fatterLimitOthers: "",
        fatterMethodOthers: "",
        packageInfo: "",
        productDefinition: "",
        carrierSolvent: "",
        preservative: "",
        botanicalExtractionProcess: "",
        botanicalSolventForExtraction: "",
        analysisRangeBioactivesLevel: "",
        analysisTestBioactivesLevel: "",
        analysisRangePh: "",
        analysisRangeHeavyMetal: "Conforms to USP <231>",
        analysisTestPh: "",
        analysisRangePesticide: "Conforms to USP<565>",
        analysisTestPesticide: "",
        analysisRangeTotalPlateCount: "100",
        analysisTestTotalPlateCount: "",
        analysisRangeYeastMold: "100",
        analysisMethodPh: "",
        analysisMethodHeavyMetal: "",
        analysisMethodPesticide: "",
        analysisMethodTotalPlateCount: "100",
        analysisMethodYeastMold: "100",
        majorConstituentsInNativeHerbs: "",
        testLimitHeavyMetals: "10",

        productName: "",
        itemCode: "",
        productDescription: "低温下喷雾干燥法提取的粉末状 XXXXXX植物提取物",
        generalSpecificatio: "",
        createTime: "",
        productType: "",
        ingredients: "",
        productGrade: "",
        inciName: "",
        botanicalName: "",
        commonOtherName: "",
        partUsed: "",
        extractionRatio: "",
        importantConstitueInPlant: "",
        appearance: "",
        solubility: "溶于/可分散于水",
        homogeneity: "",
        extractionProcess: "",
        solventForExtracti: "水",
        carrier: "",
        pesticides: "符合USP <565>",
        certification: "Kosher certified, HALAL certified",
        specificationStandard1: "",
        specificationStandard2: "",
        specificationStandard3: "",
        specificationMeshSize: "",
        specificationLossonDrying: "6",
        specificationBulkDensity: "0.25-0.75",
        specificationArsenic: "",
        specificationCadmium: "",
        specificationLead: "",
        specificationMercury: "",
        specificationHptlcIdentification: "",
        specificationTotalHeavyMetals: "10",
        specificationColor: "标准",
        specificationOrdor: "天然植物气味",
        specificationTaste: "",
        testMethodStandard1: "DracoUV-",
        testMethodStandard2: "DracoHPLC-",
        testMethodStandard3: "",
        testMethodMeshSize: "",
        testMethodLossOnDrying: "",
        testMethodBulkDensity: "",
        testMethodHptlcIdentification: "",
        testMethodHeavyMetals: "10",
        testMethodColor: "",
        testMethodOrdor: "",
        testMethodTaste: "",
        testMethodTotalColiforms: "",
        specificationTotalPlateCount: "",
        specificationYeastAndMold: "100",
        specificationEcoli: "阴性",
        specificationSalmonella: "阴性",
        specificationStaphAureus: "阴性",
        specificationTotalColiforms: "10",
        testMethodTotalPlateCount: "",
        testMethodYeastAndMold: "",
        testMethodEcoli: "",
        testMethodSalmonella: "",
        testMethodTaphAureus: "",
        shelflife: "未开封情况下三年保质期。产品对热和潮湿敏感。",
        shelflifeNumber:"",
        stotage: "",
        fattyAcidComposition: "",
        shTest: "",

        genSpecCustomField: "",
        analysisCustomField: "",
        micTestsCustomField: "",

        tableInput1:"",
        tableInput2:"",
        tableInput3:"",
        tableInput4:"",
        tableInput5:"",
        tableInputCustomField:"",
        isShowNotes2:true
      },
      itemCodeExist: 1,
      loading: false,
      // 对应禁止输入
      inciNameD: false,
      ingredientsD: false,
      botanicalNameD: false,
      commonOtherNameD: false,
      partUsedD: false,
      isSubmit: false,
      showVerified: false,
      // verifiedRes: 1,
      isVerifing: false,
      checkItemCode: false,
      checkProductName: false,
      checkProductType: false,
      specificationYeastAndMoldD: false,
      hasVerifyItemCode: false,
      // 输入框禁用
      colorD: false,
      fattyAcidCompositionArr: [],
      shTestArr: [],
      shTest: {
        testLimitAcidValue: "",
        testLimitPeroxideValue: "",
        testLimitSaponificationValue: "",
        testLimitUnsaponifiableMaster: "",
        testLimitMoistureVolatiles: "",
        testLimitLead: "",
        testLimitArsenic: "",
        testLimitCadmium: "",
        testLimitMercury: "",
        testLimitPesticides: "USP <561>",
        testMethodAcidValue: "AOCS Cd 3d-63",
        testMethodPeroxideValue: "AOCS Cd 8b-90",
        testMethodSaponificationValue: "AOCS Cd 3-25",
        testMethodUnsaponifiableMaster: "AOCS Ca 6a-40",
        testMethodMoistureVolatiles: "AOCS Ca 2d-25",
        testMethodLead: "AOCS Ca 18c-91",
        testMethodArsenic: "AOAC 986.15",
        testMethodCadmium: "AOAC 986.15",
        testMethodMercury: "AOAC 971.21",
        testMethodPesticides: "USP <561>",
      },
      fattyAcidComposition: {
        fattryLimitPalmitica: "", //28.0-35.0
        fattryLimitPalmitoleic: "", //25.0-55.0
        fattryLimitStearic: "", //0-3.0
        fattryLimitOleicVaccenic: "", //21.0-32.0
        fattryLimitLinoleic: "", //3.0-12.0
        fattryLimitALinolenic: "", //0-4.0
        fatterLimitOthers: "", //0-3.0
        fattryMethodPalmitica: "AOCS Ce 1e-91",
        fattryMethodPalmitoleic: "AOCS Ce 1e-91",
        fattryMethodStearic: "AOCS Ce 1e-91",
        fattryMethodOleicVaccenic: "AOCS Ce 1e-91",
        fattryMethodLinoleic: "AOCS Ce 1e-91",
        fattryMethodALinolenic: "AOCS Ce 1e-91",
        fatterMethodOthers: "AOCS Ce 1e-91",
      },
      formData: {
        id: 0,
        productName: "",
        itemCode: "",
        lot: "",
        jsonValue: {},
      },
      genSpecCustomFieldAttr: [],
      analysisCustomFieldAttr: [],
      micTestsCustomFieldAttr: [],
      customField: {
        customKey: "",
        customValue: "",
        customKey1: "",
        customValue1: "",
        customKey2: "",
        customValue2: "",
      },
      tableInputCustomFieldAttr: [],
      tableInputField: {
        tableInput1: "",
        tableInput2: "",
        tableInput3: "",
        tableInput4: "",
        tableInput5: "",
      },
    };
  },
  computed: {
    verify_show: {
      get() {
        switch (this.params.verified) {
          case "true":
            return { icon: "el-icon-success", color: "#67c23a" };
          case "false":
            return { icon: "el-icon-error", color: "#f56c6c" };
          default:
            return { icon: "el-icon-question", color: "#a6a9ad" };
        }
      },
      set() {
        switch (this.params.verified) {
          case "true":
            return { icon: "el-icon-success", color: "#67c23a" };
          case "false":
            return { icon: "el-icon-error", color: "#f56c6c" };
          default:
            return { icon: "el-icon-question", color: "#a6a9ad" };
        }
      },
    },
  },
  watch: {
    baseInfoDetail: {
      handler(basInfo) {
        console.log("power=====basInfo")
        console.log(basInfo)
        this.params.productType=basInfo.params.productType;
        this.params.productName=basInfo.params.BaseInfoProductName;
        this.params.itemCode=basInfo.params.BaseInfoItemCode;
        this.params.productDescription=basInfo.params.BaseInfoProductDescription;
        this.params.versionNumber=basInfo.params.versionNumber;
        this.specificationYeastAndMoldD = false;
        this.baseInfoCustomFieldList = basInfo.BaseInfoCustomFieldAttr
      },
      immediate: true,
      deep: true,
    },
  },
  methods: newItemMethod,
  mounted() {
    let params = { ...this.$route.query };
    if (params.id) {
      this.formData.id = params.id;
      this.isShowVertify = true
      this.newItemType="Edit";
      this.itemCodeExist == 2;
      this.getCnSpecData(params);
    } else {
      this.newItemType = "New";
    }
    this.sWidth = "1920px";
  },
  created() {},
};
</script>
<style lang="scss" scoped>
@import "../module/NewItemStyle.scss";
</style>



