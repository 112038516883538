<template>
  <div
    class="page"
    style="margin-top: -80px"
    v-loading.fullscreen.lock="loading"
    element-loading-spinner="el-icon-loading"
    element-loading-text="Desperately loading..."
  >
    <div id="printDiv" v-show="printShow">
      <CnLiquid
        :printModel="this.$data"
        style="height-max: 1000px; width: 100vw; padding: 40px"
      ></CnLiquid>
    </div>

    <center>
      <div class="new-item-content">
        <!-- General Specification -->
        <div class="base-info">
          <div>标准规格</div>
          <div>
            <div>
              <span>编辑打印标题：</span>
              <div class="el-input" style="text-align: left">
                <el-checkbox
                  v-model="form.BaseInfoEditPrintTitleCheckbox"
                  placeholder="请输入"
                ></el-checkbox>
                <el-input
                  v-show="form.BaseInfoEditPrintTitleCheckbox"
                  v-model="form.BaseInfoEditPrintTitle"
                  placeholder="请输入"
                  style="margin-left: 10px; width: 575px"
                ></el-input>
              </div>
            </div>
            <div style="height: auto">
              <span>通用名/其他名称：</span>
              <!-- <el-input
                v-model="form.GeneralSpecCommonName"
                placeholder="请输入"
              ></el-input> -->

              <div
                style="display: inline-block; width: 615px; text-align: left"
              >
                <div
                  style="
                    display: flex;
                    justify: flex-start;
                    flex-wrap: wrap;
                    align-items: center;
                  "
                >
                  <el-tag
                    :key="i"
                    v-for="(tag, i) in commonNameArr"
                    closable
                    :disable-transitions="false"
                    @close="handleClose(tag, 'commonNameArr')"
                    >
                    <span @click="tagHandle(commonNameArr, tag, i)" style="cursor: pointer;">{{ tag }}</span>
                    <el-button type="primary" style="padding: 0 2px;margin-left:5px;" size="small" 
                    @click="showIndexInput('commonNameInputVisible', 'commonNameSaveTagInput',i,'commonNameArrIndex')">+</el-button>
                    <el-button v-show="i>0" type="primary" style="padding: 0;" size="small" icon="el-icon-arrow-left"
                    @click="leftInputValue(commonNameArr, i)"></el-button>
                    <el-button type="primary" style="padding: 0;" size="small" icon="el-icon-arrow-right"
                    @click="rightInputValue(commonNameArr, i)"></el-button>
                  </el-tag
                  >
                  <el-input
                    class="input-new-tag"
                    v-if="commonNameInputVisible"
                    v-model="commonNameInputValue"
                    ref="commonNameSaveTagInput"
                    size="small"
                    @keyup.enter.native="
                      handleInputConfirm(
                        'commonNameInputValue',
                        'commonNameArr',
                        'commonNameInputVisible'
                      )
                    "
                    @blur="
                      handleInputConfirm(
                        'commonNameInputValue',
                        'commonNameArr',
                        'commonNameInputVisible'
                      )
                    "
                  ></el-input>
                  <el-button
                    :disabled="commonNameD"
                    v-else
                    class="button-new-tag"
                    size="small"
                    @click="
                      showInput(
                        'commonNameInputVisible',
                        'commonNameSaveTagInput'
                      )
                    "
                    >+ 新增通用名/其他名称</el-button
                  >
                </div>
              </div>
            </div>
            <div style="height: auto">
              <span>INCI名称：</span>
              <!-- <el-input
                v-model="form.GeneralSpecINCIName"
                placeholder="请输入"
              ></el-input> -->

              <div
                style="display: inline-block; width: 615px; text-align: left"
              >
                <div
                  style="
                    display: flex;
                    justify: flex-start;
                    flex-wrap: wrap;
                    align-items: center;
                  "
                >
                  <el-tag
                    :key="i"
                    v-for="(tag, i) in inclNameArr"
                    closable
                    :disable-transitions="false"
                    @close="handleClose(tag, 'inclNameArr')"
                    >
                    <span @click="tagHandle(inclNameArr, tag, i)" style="cursor: pointer;">{{ tag }}</span>
                    <el-button type="primary" style="padding: 0 2px;margin-left:5px;" size="small" 
                    @click="showIndexInput('inclNameInputVisible', 'inclNameSaveTagInput',i,'inclNameArrIndex')">+</el-button>
                    <el-button v-show="i>0" type="primary" style="padding: 0;" size="small" icon="el-icon-arrow-left"
                    @click="leftInputValue(inclNameArr, i)"></el-button>
                    <el-button type="primary" style="padding: 0;" size="small" icon="el-icon-arrow-right"
                    @click="rightInputValue(inclNameArr, i)"></el-button>
                  </el-tag
                  >
                  <el-input
                    class="input-new-tag"
                    v-if="inclNameInputVisible"
                    v-model="inclNameInputValue"
                    ref="inclNameSaveTagInput"
                    size="small"
                    @keyup.enter.native="
                      handleInputConfirm(
                        'inclNameInputValue',
                        'inclNameArr',
                        'inclNameInputVisible'
                      )
                    "
                    @blur="
                      handleInputConfirm(
                        'inclNameInputValue',
                        'inclNameArr',
                        'inclNameInputVisible'
                      )
                    "
                  ></el-input>
                  <el-button
                    :disabled="inciNameD"
                    v-else
                    class="button-new-tag"
                    size="small"
                    @click="
                      showInput('inclNameInputVisible', 'inclNameSaveTagInput')
                    "
                    >+ 新增INCI名称</el-button
                  >
                </div>
              </div>
            </div>
            <div style="height: auto">
              <span>植物名称：</span>
              <!-- <el-input
                v-model="form.GeneralSpecBotanicalName"
                placeholder="请输入"
              ></el-input> -->

              <div
                style="display: inline-block; width: 615px; text-align: left"
              >
                <div
                  style="
                    display: flex;
                    justify: flex-start;
                    flex-wrap: wrap;
                    align-items: center;
                  "
                >
                  <el-tag
                    :key="i"
                    v-for="(tag, i) in botanicalNameArr"
                    closable
                    :disable-transitions="false"
                    @close="handleClose(tag, 'botanicalNameArr')"
                    >
                    <span @click="tagHandle(botanicalNameArr, tag, i)" style="cursor: pointer;">{{ tag }}</span>
                    <el-button type="primary" style="padding: 0 2px;margin-left:5px;" size="small" 
                    @click="showIndexInput('botanicalNameInputVisible', 'botanicalNameSaveTagInput',i,'botanicalNameArrIndex')">+</el-button>
                    <el-button v-show="i>0" type="primary" style="padding: 0;" size="small" icon="el-icon-arrow-left"
                    @click="leftInputValue(botanicalNameArr, i)"></el-button>
                    <el-button type="primary" style="padding: 0;" size="small" icon="el-icon-arrow-right"
                    @click="rightInputValue(botanicalNameArr, i)"></el-button>
                  </el-tag
                  >
                  <el-input
                    class="input-new-tag"
                    v-if="botanicalNameInputVisible"
                    v-model="botanicalNameInputValue"
                    ref="botanicalNameSaveTagInput"
                    size="small"
                    @keyup.enter.native="
                      handleInputConfirm(
                        'botanicalNameInputValue',
                        'botanicalNameArr',
                        'botanicalNameInputVisible'
                      )
                    "
                    @blur="
                      handleInputConfirm(
                        'botanicalNameInputValue',
                        'botanicalNameArr',
                        'botanicalNameInputVisible'
                      )
                    "
                  ></el-input>
                  <el-button
                    :disabled="botanicalNameD"
                    v-else
                    class="button-new-tag"
                    size="small"
                    @click="
                      showInput(
                        'botanicalNameInputVisible',
                        'botanicalNameSaveTagInput'
                      )
                    "
                    >+ 新增植物名称</el-button
                  >
                </div>
              </div>
            </div>
            <div style="height: auto">
              <span>植物主要成分：</span>
              
              <el-input
                v-model="form.GeneralSpecNativeHerbs"
                placeholder="请输入"
              ></el-input>

              <!-- <div
                style="display: inline-block; width: 615px; text-align: left"
              >
                <div
                  style="
                    display: flex;
                    justify: flex-start;
                    flex-wrap: wrap;
                    align-items: center;
                  "
                >
                  <el-tag
                    :key="i"
                    v-for="(tag, i) in nativeHerbsArr"
                    closable
                    :disable-transitions="false"
                    @click="tagHandle(nativeHerbsArr, tag, i)"
                    @close="handleClose(tag, 'nativeHerbsArr')"
                    >{{ tag }}</el-tag
                  >
                  <el-input
                    class="input-new-tag"
                    v-if="nativeHerbsInputVisible"
                    v-model="nativeHerbsInputValue"
                    ref="nativeHerbsSaveTagInput"
                    size="small"
                    @keyup.enter.native="
                      handleInputConfirm(
                        'nativeHerbsInputValue',
                        'nativeHerbsArr',
                        'nativeHerbsInputVisible'
                      )
                    "
                    @blur="
                      handleInputConfirm(
                        'nativeHerbsInputValue',
                        'nativeHerbsArr',
                        'nativeHerbsInputVisible'
                      )
                    "
                  ></el-input>
                  <el-button
                    :disabled="nativeHerbsD"
                    v-else
                    class="button-new-tag"
                    size="small"
                    @click="
                      showInput(
                        'nativeHerbsInputVisible',
                        'nativeHerbsSaveTagInput'
                      )
                    "
                    >+ New nativeHerbs</el-button
                  >
                </div>
              </div> -->
            </div>
            <div style="height: auto">
              <span>使用部位：</span>
              <!-- <el-input
                v-model="form.GeneralSpecPartUsed"
                class="custom-row"
                placeholder="请输入"
              ></el-input> -->

              <div
                style="display: inline-block; width: 615px; text-align: left"
              >
                <div
                  style="
                    display: flex;
                    justify: flex-start;
                    flex-wrap: wrap;
                    align-items: center;
                  "
                >
                  <el-tag
                    :key="i"
                    v-for="(tag, i) in partUsedArr"
                    closable
                    :disable-transitions="false"
                    @close="handleClose(tag, 'partUsedArr')"
                    >
                    <span @click="tagHandle(partUsedArr, tag, i)" style="cursor: pointer;">{{ tag }}</span>
                    <el-button type="primary" style="padding: 0 2px;margin-left:5px;" size="small" 
                    @click="showIndexInput('partUsedInputVisible', 'partUsedSaveTagInput',i,'partUsedArrIndex')">+</el-button>
                    <el-button v-show="i>0" type="primary" style="padding: 0;" size="small" icon="el-icon-arrow-left"
                    @click="leftInputValue(partUsedArr, i)"></el-button>
                    <el-button type="primary" style="padding: 0;" size="small" icon="el-icon-arrow-right"
                    @click="rightInputValue(partUsedArr, i)"></el-button>
                  </el-tag
                  >
                  <el-input
                    class="input-new-tag"
                    v-if="partUsedInputVisible"
                    v-model="partUsedInputValue"
                    ref="partUsedSaveTagInput"
                    size="small"
                    @keyup.enter.native="
                      handleInputConfirm(
                        'partUsedInputValue',
                        'partUsedArr',
                        'partUsedInputVisible'
                      )
                    "
                    @blur="
                      handleInputConfirm(
                        'partUsedInputValue',
                        'partUsedArr',
                        'partUsedInputVisible'
                      )
                    "
                  ></el-input>
                  <el-button
                    :disabled="partUsedD"
                    v-else
                    class="button-new-tag"
                    size="small"
                    @click="
                      showInput('partUsedInputVisible', 'partUsedSaveTagInput')
                    "
                    >+ 新增使用部位</el-button
                  >
                </div>
              </div>
            </div>   
            <div style="height: auto">
              <span>原产地：</span>
              
              <el-input
                v-model="form.countryOfOrigin"
                placeholder="请输入"
              ></el-input>
            </div>        
            <div style="height: auto">
              <!-- <span>Percentage ：</span> -->
              <span>百分比 ：</span>
              <div style="display: inline-block; width: 615px; text-align: left">
                <div
                  style="
                    display: flex;
                    justify: flex-start;
                    flex-wrap: wrap;
                    align-items: center;
                  "
                >
                  <!-- (tag,i) in ingredientsTags(2) -->
                  <el-tag
                    :key="i"
                    v-for="(tag, i) in percentageArr"
                    closable
                    :disable-transitions="false"
                    @close="handleClose(tag, 'percentageArr')"
                    >
                    <span @click="tagHandle(percentageArr, tag, i)" style="cursor: pointer;">{{ tag }}</span>
                    <el-button type="primary" style="padding: 0 2px;margin-left:5px;" size="small" 
                    @click="showIndexInput('percentageInputVisible', 'percentageSaveTagInput',i,'percentageArrIndex')">+</el-button>
                    <el-button v-show="i>0" type="primary" style="padding: 0;" size="small" icon="el-icon-arrow-left"
                    @click="leftInputValue(percentageArr, i)"></el-button>
                    <el-button type="primary" style="padding: 0;" size="small" icon="el-icon-arrow-right"
                    @click="rightInputValue(percentageArr, i)"></el-button>
                  </el-tag
                  >
                  <el-input
                    class="input-new-tag"
                    v-if="percentageInputVisible"
                    v-model="percentageInputValue"
                    ref="percentageSaveTagInput"
                    size="small"
                    @keyup.enter.native="
                      handleInputConfirm(
                        'percentageInputValue',
                        'percentageArr',
                        'percentageInputVisible'
                      )
                    "
                    @blur="
                      handleInputConfirm(
                        'percentageInputValue',
                        'percentageArr',
                        'percentageInputVisible'
                      )
                    "
                  ></el-input>
                  <el-button
                    v-else
                    class="button-new-tag"
                    size="small"
                    @click="
                      showInput(
                        'percentageInputVisible',
                        'percentageSaveTagInput'
                      )
                    "
                    >+ 新增百分比</el-button
                  >
                </div>
              </div>
            </div>
            <div style="height: auto">
              <div style="display: inline-block; text-align: left">
              <table style="border: 1px solid #eee;">
                <tr>
                  <td style="border: 1px solid #eee;">
                    <el-input placeholder="请输入内容" style="width: 100%" v-model="form.tableInput1"></el-input>
                  </td>
                  <td style="border: 1px solid #eee;">
                    <el-input placeholder="请输入内容" style="width: 100%" v-model="form.tableInput2"></el-input>
                  </td>
                  <td style="border: 1px solid #eee;">
                    <el-input placeholder="请输入内容" style="width: 100%" v-model="form.tableInput3"></el-input>
                  </td>
                  <td style="border: 1px solid #eee;">
                    <el-input placeholder="请输入内容" style="width: 100%" v-model="form.tableInput4"></el-input>
                  </td>
                  <td style="border: 1px solid #eee;">
                    <el-input placeholder="请输入内容" style="width: 100%" v-model="form.tableInput5"></el-input>
                  </td>
                  <td>
                    <el-button type="primary" class="button-new-tag" size="small" @click="addTableInput(1, 0)">+</el-button>
                  </td>
                </tr>
                <tr v-for="(item, tableInputIndex) in tableInputCustomFieldAttr" :key="tableInputIndex + 1">
                  <td style="border: 1px solid #eee;">
                    <el-input placeholder="请输入内容" style="width: 100%" v-model="item.tableInput1"></el-input>
                  </td>
                  <td style="border: 1px solid #eee;">
                    <el-input placeholder="请输入内容" style="width: 100%" v-model="item.tableInput2"></el-input>
                  </td>
                  <td style="border: 1px solid #eee;">
                    <el-input placeholder="请输入内容" style="width: 100%" v-model="item.tableInput3"></el-input>
                  </td>
                  <td style="border: 1px solid #eee;">
                    <el-input placeholder="请输入内容" style="width: 100%" v-model="item.tableInput4"></el-input>
                  </td>
                  <td style="border: 1px solid #eee;">
                    <el-input placeholder="请输入内容" style="width: 100%" v-model="item.tableInput5"></el-input>
                  </td>
                  <td>
                    <el-button type="primary" class="button-new-tag" size="small" @click="addTableInput(1, 0)">+</el-button>
                    <el-button type="danger" class="button-new-tag" size="small" @click="addTableInput(0, tableInputIndex)">-</el-button>
                  </td>
                </tr>
              </table>
              </div>
            </div>
            <div>
              <span>外观：</span>
              <el-input
                v-model="form.appearance"
                placeholder="请输入"
              ></el-input>
            </div>
            <div>
              <!-- <span>Mesh Size：</span> -->
              <span>颜色：</span>
              <el-select
                v-model="form.color"
                placeholder="请选择颜色"
                clearable
                filterable
                allow-create
              >
                <el-option
                  v-for="(item, i) in colorList"
                  :key="i"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div>
              <span>气味：</span>
              <el-input
                v-model="form.ordor"
                class="custom-row"
                placeholder="请输入气味"
              ></el-input>
            </div>
            <div>
              <span>味道：</span>
              <el-input
                v-model="form.taste"
                class="custom-row"
                placeholder="请输入味道"
              ></el-input>
            </div>
            <div>
              <!-- <span>Mesh Size：</span> -->
              <span>溶剂：</span>
              <el-select
                v-model="form.solventForExtracti"
                placeholder="请选择溶剂"
                clearable
                filterable
                allow-create
              >
                <el-option
                  v-for="(item, i) in solventForExtractiList"
                  :key="i"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div>
              <span>防腐剂：</span>
              <el-input
                v-model="form.GeneralSpecPreservative"
                class="custom-row"
                placeholder="请输入防腐剂"
              ></el-input>
            </div>
            <div>
              <span>其他成分：</span>
              <el-input
                v-model="form.otherIngredients"
                class="custom-row"
                placeholder="请输入其他成分"
              ></el-input>
            </div>

            <div>
              <span
                ><el-input
                  v-model="form.GeneralSpecCustomRowKey1"
                  class="custom-row"
                  placeholder="请输入"
                  style="width: 50%"
                ></el-input
                >：</span
              >
              <div class="custom-row el-input">
                <el-input
                  v-model="form.GeneralSpecCustomRowValue1"
                  class="custom-row"
                  placeholder="请输入"
                ></el-input>
              </div>
            </div>
            <div>
              <span
                ><el-input
                  v-model="form.GeneralSpecCustomRowKey2"
                  class="custom-row"
                  placeholder="请输入"
                  style="width: 50%"
                ></el-input
                >：</span
              >
              <div class="custom-row el-input">
                <el-input
                  v-model="form.GeneralSpecCustomRowValue2"
                  class="custom-row"
                  placeholder="请输入"
                ></el-input>
              </div>
            </div>
            <div>
              <span  style="margin-left: 52px"
                ><el-input
                  v-model="form.GeneralSpecCustomRowKey3"
                  class="custom-row"
                  placeholder="请输入"
                  style="width: 50%"
                ></el-input
                >：</span
              >
              <div class="custom-row el-input">
                <el-input
                  v-model="form.GeneralSpecCustomRowValue3"
                  class="custom-row"
                  placeholder="请输入"
                ></el-input>
              </div>
              <el-button
                type="primary"
                class="button-new-tag"
                size="small"
                @click="addGenSpecCustomRow(1, 0)"
                >+</el-button>
            </div>
            
            <div
              v-for="(item, genSpecIndex) in genSpecCustomFieldAttr"
              :key="genSpecIndex + 1"
            >
              <span style="margin-left: 96px">
                <el-input
                  v-model="item.customKey"
                  placeholder="请输入名称"
                  class="custom-row"
                  style="width: 50%"
                ></el-input
                >：</span
              >
              <div class="custom-row el-input">
                <el-input
                  v-model="item.customValue"
                  class="custom-row"
                  placeholder="请输入值"
                ></el-input>
              </div>
              <el-button
                type="primary"
                class="button-new-tag"
                size="small"
                @click="addGenSpecCustomRow(1, genSpecIndex)"
                >+</el-button
              >
              <el-button
                type="danger"
                class="button-new-tag"
                size="small"
                @click="addGenSpecCustomRow(0, genSpecIndex)"
                >-</el-button
              >
            </div>
          </div>
        </div>
        <!-- Analysis -->
        <div class="analysis">
        <div>分析</div>
        <div>
          <div class="specification">
            <div>产品规格</div>
            <div style="display: flex; align-items: center">
              <span>活性成分控制指标：</span>
              <el-input
                v-model="form.analysisRangeBioactivesLevel"
                placeholder="请输入"
              >
                <template slot="prepend">
                  <div style="width: 100%">≥</div>
                </template>
                <template slot="append">
                  <div style="width: 100%">%</div>
                </template>
              </el-input>
            </div>
            <div>
              <span>
                <el-input style="width:150px;"
                v-model="form.liquidExtCustomerKey1"
                placeholder="请输入"
              ></el-input>：
              </span>
              
              <el-input
                v-model="form.liquidExtCustomerValue1"
                placeholder="请输入"
              ></el-input>
            </div>
            <div>
              <span>
              <el-input style="width:150px;"
                v-model="form.liquidExtCustomerKey2"
                placeholder="请输入"
              ></el-input>：
              </span>
              <el-input
                v-model="form.liquidExtCustomerValue2"
                placeholder="请输入"
              ></el-input>
            </div>
            <div>
              <span>
              <el-input style="width:150px;"
                v-model="form.liquidExtCustomerKey3"
                placeholder="请输入"
              ></el-input>：
              </span>
              <el-input
                v-model="form.liquidExtCustomerValue3"
                placeholder="请输入"
              ></el-input>
            </div>
            <div>
              <span>
              <el-input style="width:150px;"
                v-model="form.liquidExtCustomerKey4"
                placeholder="请输入"
              ></el-input>：
              </span>
              <el-input
                v-model="form.liquidExtCustomerValue4"
                placeholder="请输入"
              ></el-input>
            </div>
            <div 
              v-for="(item, analysisIndex) in analysisCustomFieldAttr"
              :key="analysisIndex+1">
              <span><el-input
                v-model="item.customKey"
                placeholder="请输入"
                  style="width: 160px"
              ></el-input>：</span>
              <el-input
                v-model="item.customValue"
                placeholder="请输入值"
              ></el-input>
            </div>
            <div>
              <span>pH：</span>
              <el-input
                v-model="form.analysisRangePh"
                placeholder="请输入"
              ></el-input>
            </div>
            <div>
              <span>重金属：</span>
              <el-input
                v-model="form.analysisRangeHeavyMetal"
                placeholder="请输入"
              >
              </el-input>
            </div>
            <div style="display: flex; align-items: center">
              <span>砷：</span>
              <el-input
                v-model="form.specificationArsenic"
                placeholder="请输入砷"
              >
                <template slot="prepend">
                  <div style="width:100%;">&lt;</div>
                </template>
                <template slot="append">
                  <div style="width: 100%">ppm</div>
                </template>
              </el-input>
            </div>
            <div style="display: flex; align-items: center">
              <span>镉：</span>
              <el-input
                v-model="form.specificationCadmium"
                placeholder="请输入镉"
              >
                <template slot="prepend">
                  <div style="width:100%;">&lt;</div>
                </template>
                <template slot="append">
                  <div style="width: 100%">ppm</div>
                </template>
              </el-input>
            </div>
            <div style="display: flex; align-items: center">
              <span>铅：</span>
              <el-input
                v-model="form.specificationLead"
                placeholder="请输入铅"
              >
                <template slot="prepend">
                  <div style="width:100%;">&lt;</div>
                </template>
                <template slot="append">
                  <div style="width: 100%">ppm</div>
                </template>
              </el-input>
            </div>
            <div style="display: flex; align-items: center">
              <span>汞：</span>
              <el-input
                v-model="form.specificationMercury"
                placeholder="请输入汞"
              >
                <template slot="prepend">
                  <div style="width:100%;">&lt;</div>
                </template>
                <template slot="append">
                  <div style="width: 100%">ppm</div>
                </template>
              </el-input>
            </div>
            <div>
              <span>农药残留：</span>
              <el-input
                disabled
                v-model="form.analysisRangePesticide"
                placeholder="请输入"
              ></el-input>
            </div>
            <div style="display: flex; align-items: center">
              <span>细菌总数：</span>
              <el-input
                v-model="form.analysisRangeTotalPlateCount"
                placeholder="请输入"
                onkeyup="value=value.replace(/[^\d.]|/g,'')"
              >
                <template slot="prepend">
                  <div style="width: 100%">&lt;</div>
                </template>
                <template slot="append">
                  <div style="width: 100%">cfu / g</div>
                </template>
              </el-input>
            </div>
            <div style="display: flex; align-items: center">
              <span>酵母和霉菌：</span>
              <el-input 
                v-model="form.analysisRangeYeastMold"
                placeholder="请输入"
              >
                <template slot="prepend">
                  <div style="width: 100%">&lt;</div>
                </template>
                <template slot="append">
                  <div style="width: 100%">cfu / g</div>
                </template>
              </el-input>
            </div>
            <div>
              <span>大肠杆菌：</span>
              <el-input
                v-model="form.specificationEcoli"
                placeholder="请输入"
              ></el-input>
            </div>
            <div>
              <span>沙门氏菌：</span>
              <el-input
                v-model="form.specificationSalmonella"
                placeholder="请输入"
              ></el-input>
            </div>
            <div>
              <span>金色葡萄球菌：</span>
              <el-input
                v-model="form.specificationStaphAureus"
                placeholder="请输入"
              ></el-input>
            </div>
            <div style="display: flex; align-items: center">
              <span>大肠菌群：</span>
              <!-- <div style="width: 40%;display:inline-block;text-align:left">&lt;100/g</div>
              -->
              <el-input
                  @blur="checktestMethodTotalColiformscn()"
                v-model="form.testMethodTotalColiforms"
                placeholder="请输入"
              >
                <template slot="prepend">
                  <div style="width: 100%">&lt;</div>
                </template>
                <template slot="append">
                  <div style="width: 100%">MPN/g</div>
                </template>
              </el-input>
            </div>
            <div>
              <span>
              <el-input style="width:150px;"
                v-model="form.liquidExtCustomerKey5"
                placeholder="请输入"
              ></el-input>：
              </span>
              <el-input
                v-model="form.liquidExtCustomerValue5"
                placeholder="请输入"
              ></el-input>
            </div>
          </div>
          <div class="test-method">
            <div>测试方法</div>
            <div>
              <span>活性成分控制指标：</span>
              <el-input v-model="form.analysisMethodBioactivesLevel" placeholder="请输入"></el-input>
            </div>

            <div>
              <span>{{form.liquidExtCustomerKey1}}：</span>
              <el-input
                v-model="form.liquidExtCustomerValue1TM"
                placeholder="请输入"
              ></el-input>
            </div>
            <div>
              <span>{{form.liquidExtCustomerKey2}}：</span>
              <el-input
                v-model="form.liquidExtCustomerValue2TM"
                placeholder="请输入"
              ></el-input>
            </div>
            <div>
               <span>{{form.liquidExtCustomerKey3}}：</span>
              <el-input
                v-model="form.liquidExtCustomerValue3TM"
                placeholder="请输入"
              ></el-input></div>
            <div>
               <span>{{form.liquidExtCustomerKey4}}：</span>
              <el-input
                v-model="form.liquidExtCustomerValue4TM"
                placeholder="请输入"
              ></el-input>
              <el-button style="margin-top:14px;" type="primary" class="button-new-tag" size="small" @click="addAnalysisCustomRow(1,0)" >+</el-button>
            </div>
            <div 
              v-for="(item, analysisIndex1) in analysisCustomFieldAttr"
              :key="analysisIndex1+1">
               <span>{{item.customKey}}：</span>
              <el-input
                v-model="item.customValue1"
                placeholder="请输入值"
              ></el-input>             
              <el-button style="margin-top:14px;" type="primary" class="button-new-tag" size="small" @click="addAnalysisCustomRow(1,analysisIndex1)" >+</el-button>
              <el-button style="margin-top:14px;" type="danger" class="button-new-tag" size="small" @click="addAnalysisCustomRow(0,analysisIndex1)" >-</el-button>
            </div>

            <div>
              <span>pH：</span>
              <el-input
                v-model="form.analysisMethodPh"
                placeholder="USP<791>"
              ></el-input>
            </div>

            <div>
              <span>重金属：</span>
              <el-input
                v-model="form.analysisMethodHeavyMetal"
                placeholder="USP <231>"
              ></el-input>
            </div>
            

            <div>
              <span>砷：</span>
              <el-input
                v-model="form.testMethodArsenic"
                placeholder="请输入砷"
              ></el-input>
            </div>
            <div>
              <span>镉：</span>
              <el-input
                v-model="form.testMethodCadmium"
                placeholder="请输入镉"
              ></el-input>
            </div>
            <div>
              <span>铅：</span>
              <el-input
                v-model="form.testMethodLead"
                placeholder="请输入铅"
              ></el-input>
            </div>
            <div>
              <span>汞：</span>
              <el-input
                v-model="form.testMethodMercury"
                placeholder="请输入汞"
              ></el-input>
            </div>

            <div>
              <span>农药残留：</span>
              <el-input
                v-model="form.analysisMethodPesticide"
                placeholder="USP<565>"
              ></el-input>
            </div>

            <div style="display: flex; align-items: center">
              <span>细菌总数：</span>
              <el-input v-model="form.analysisMethodTotalPlateCount"></el-input>
                <!-- onkeyup="value=value.replace(/[^\d.]|/g,'')" -->
            </div>

            <div style="display: flex; align-items: center">
              <span>酵母和霉菌：</span>
              <el-input v-model="form.analysisMethodYeastMold"></el-input>
            </div>

            <div>
              <span>大肠杆菌：</span>
              <el-input v-model="form.analysisMethodEColi"></el-input>
            </div>
            <div>
              <span>沙门氏菌：</span>
              <el-input v-model="form.analysisMethodSalmonella"></el-input>
            </div>
            <div>
              <span>金色葡萄球菌：</span>
              <el-input v-model="form.analysisMethodStaphAureus"></el-input>
            </div>
            <div>
              <span>大肠菌群：</span>
              <el-input v-model="form.analysisMethodTotalColiform"></el-input>
            </div>
            <div>
               <span>{{form.liquidExtCustomerKey5}}：</span>
              <el-input
                v-model="form.liquidExtCustomerValue5TM"
                placeholder="请输入"
              ></el-input></div>
            <!-- <div>
               <span>{{form.liquidExtCustomerKey6}}：</span>
              <el-input
                v-model="form.liquidExtCustomerValue6TM"
                placeholder="请输入"
              ></el-input></div> -->
          </div>
        </div>
      </div>
      <div class="other-notes">
        <div>其他</div>
        <div>
          <span>保质期：</span>
          <el-select
                v-model="form.shelflifeNumber"
                placeholder="保质期"
                clearable
                filterable
                allow-create
              >
                <el-option
                  v-for="(item, i) in shelflifeList"
                  :key="i"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
        </div>
        <div>
          <!-- <span>Shelf Life：</span> -->
          <span>保质期备注：</span>
          <!-- <span>Three years unopened. Product is sensitive to heat and moisture.</span> -->
          <el-input
            type="textarea"
            :rows="5"
            resize="none"
            style="width: 600px"
            placeholder="请输入"
            v-model="form.shelflife"
          ></el-input>
        </div>
        <div>
          <!-- <span>Storage：</span> -->
          <span>储存条件：</span>
          <el-input
            v-model="form.storage"
            placeholder="请输入"
          ></el-input>
        </div>
      </div>
      <div>
        <el-form :inline="true" class="demo-form-inline">
          <div v-for="(item, index) in noteTableData" :key="index">
            <el-form-item label="修订日期">
              <el-input
                v-model="item.revisionDate"
                placeholder="修订日期"
                style="width: 140px"
                :disabled="item.isComplete"
              ></el-input>
            </el-form-item>
            <el-form-item label="提出修订人">
              <el-input
                v-model="item.requestedBy"
                placeholder="提出修订人"
                style="width: 140px"
                :disabled="item.isComplete"
              ></el-input>
            </el-form-item>
            <el-form-item label="修订项目">
              <el-input
                v-model="item.itemsRevised"
                placeholder="修订项目"
                style="width: 140px"
                :disabled="item.isComplete"
              ></el-input>
            </el-form-item>
            <el-form-item label="修订人">
              <el-input
                v-model="item.editedBy"
                placeholder="修订人"
                style="width: 140px"
                :disabled="item.isComplete"
              ></el-input>
            </el-form-item>
            <el-form-item label="修订原因">
              <el-input
                v-model="item.reason"
                placeholder="修订原因"
                style="width: 140px"
                :disabled="item.isComplete"
              ></el-input>
            </el-form-item>
            <el-form-item label="批准人">
              <el-input
                v-model="item.approvedBy"
                placeholder="批准人"
                disabled="disabled"
                style="width: 140px"
              ></el-input>
            </el-form-item>
            <!-- <el-form-item style="width: 120px">
              <el-button type="text" @click="addNoteTableClick(index,'cn')" 
                v-if="item.revisionDate && item.requestedBy && item.itemsRevised && item.editedBy && item.reason && item.approvedBy"
              >Complete</el-button>
              &nbsp;&nbsp;
              <el-button type="text"
                v-if="!item.isComplete && noteTableData.length>1" @click="removeNoteTableClick(index)">
                <i style="color:Red;font-size:18px;" class="el-icon-delete"></i>
              </el-button>
            </el-form-item> -->
          </div>
        </el-form>
      </div>
        <div class="other-notes">
          <div>Notes</div>
          <div style="margin-top:0px;">
            <el-input v-model="newNote" placeholder="Please Input" style="width:600px;margin-bottom:10px;margin-right:10px;"></el-input>
            <el-button type="text" @click="addNewNoteTableClick">
              Complete
            </el-button>
          </div>
        </div>
        <div class="base-info">
          <div>注释:</div>
          <div style="width: 80%; text-align: left; font-weight: bold">
            <div>
              * 不同批次产品的颜色可能会因天然原材料的自然变化而产生差异。
            </div>
            <div style="color:Red;">
              <el-checkbox v-model="form.isShowNotes2" label="打印是否显示"></el-checkbox>* 产品含抗氧化化合物，可能会随着时间推移颜色变深但不影响使用效果。
            </div>
            <div>
              * 天然植物产品在长期静置时可能会产生一些产品本身的沉淀物，这不会影响产品的质量；如发现产品有沉淀，请在使用前适当加热并搅拌至充分混合。
            </div>
            <div>
              * 如果产品中含水量低于50%，则产品的pH 值必须在1%产品的水溶液中测定。
            </div>
            <div v-for="(item, index) in newNoteArr" :key="index" style="margin-top:0px;">
              * {{item.content}}
              <el-button type="text" @click="deleteNewNoteTableClick(index)">
                <i style="color:red;font-size:20px;" class="el-icon-delete"></i>
              </el-button>
            </div>
          </div>
        </div>
      <div>
        <el-checkbox v-model="isSignaturePrint" @change="changeSignatureHandle">打印客户确认</el-checkbox>
      </div>
        <!-- Save -->
        <div class="save-bottom">
          <el-button v-if="userInfo.operateStatus && form.productId!=0 && form.verifiedList==0" type="warning" @click="handleCnSpecLNew('Continue Edit',1)">新增至 Verify List</el-button>
          <el-button v-if="userInfo.operateStatus" type="primary" @click="handleCnSpecLNew('Continue Edit')">保存</el-button>
          <el-button v-if="userInfo.operateStatus" type="primary" @click="handleCnSpecLNew('New')">保存并新增</el-button>
          <el-button
          v-if="cnpemission(userInfo.username) && isShowVertify && userInfo.operateStatus"
          type="warning"
          @click="cnvertify(formData.id,'liquid')"
          >审核</el-button>
          <el-button @click="backcn">返回首页</el-button>
          <el-button type="success" icon="el-icon-printer" @click="printCnLPage()">打印</el-button>
        </div>
      </div>
    </center>
  </div>
</template>
<script>
import base from "@/common/base.js";
import CnLiquid from "../PrintProductTypeModule/Cn-Liquid.vue";
import newItemMethod from "../module/NewItemMethod";
export default {
  props: ["baseInfoDetail"],
  mixins: [base],
  components: { CnLiquid },
  data() {
    return {
      isShowVertify:false,
      newItemType:"New",
      isSignaturePrint: false,      
      inclNameArr: [],
      inclNameInputVisible: "",
      inclNameInputValue: "",
      inciNameD: false,
      inclNameArrIndex:-1,

      commonNameArr: [],
      commonNameInputVisible: "",
      commonNameInputValue: "",
      commonNameD: false,
      commonNameArrIndex:-1,

      botanicalNameArr: [],
      botanicalNameInputVisible: "",
      botanicalNameInputValue: "",
      botanicalNameD: false,
      botanicalNameArrIndex:-1,

      nativeHerbsArr: [],
      nativeHerbsInputVisible: "",
      nativeHerbsInputValue: "",
      nativeHerbsD: false,
      nativeHerbsArrIndex:-1,

      partUsedArr: [],
      partUsedInputVisible: "",
      partUsedInputValue: "",
      partUsedD: false,
      partUsedArrIndex:-1,


      percentageArr: [],
      percentageInputVisible: "",
      percentageInputValue: "",
      percentageD: false,
      percentageArrIndex:-1,

      noteTableData: [
        {
          revisionDate: "",
          requestedBy: "",
          itemsRevised: "",
          editedBy: "",
          approvedBy: "",
          reason:"",
          isComplete:false,
        },
      ],
      newNoteArr:[],
      newNote:"",
      colorList:[
        { label: "标准", value: "标准" },
        { label: "透明", value: "透明" },
        { label: "白色--灰色", value: "白色--灰色" },
        { label: "类白色--黄色", value: "类白色--黄色" },
        { label: "浅黄色--黄色", value: "浅黄色--黄色" },
        { label: "浅琥珀棕色--琥珀棕色", value: "浅琥珀棕色--琥珀棕色" },
        { label: "浅棕色--深棕色", value: "浅棕色--深棕色" },
        { label: "浅棕黄色--深棕黄色", value: "浅棕黄色--深棕黄色" },
        { label: "浅黄棕色--深黄棕色", value: "浅黄棕色--深黄棕色" },
        { label: "棕色--褐色", value: "棕色--褐色" },
        { label: "红棕色--深红色棕色", value: "红棕色--深红色棕色" },
        { label: "浅绿色--黄绿色", value: "浅绿色--黄绿色" },
        { label: "浅红棕色--深红棕色", value: "浅红棕色--深红棕色" },
        { label: "紫色--深色紫色", value: "紫色--深色紫色" },
        { label: "紫红色--紫黑色", value: "紫红色--紫黑色" }
      ],
      solventForExtractiList:[
        { label: "甘油", value: "甘油" },
        { label: "丁二醇", value: "丁二醇" },
        { label: "丙二醇", value: "丙二醇" },
      ],
      shelflifeList:[
        { label: "3个月", value: "3" },
        { label: "6个月", value: "6" },
        { label: "12个月", value: "12" },
        { label: "18个月", value: "18" },
        { label: "24个月", value: "24" },
        { label: "36个月", value: "36" },
      ],
      baseInfoCustomFieldList:[],
      form: {
        baseInfoCustomField:"",
        otherIngredients:"",
        versionNumber: "",
        revNumber:1,
        revDate:[],
        BaseInfoEditPrintTitle: "上海天源植物制品有限公司",
        BaseInfoEditPrintTitleCheckbox: false,
        productId:"",
        appearance:"",
        ordor:"天然植物气味",
        taste:"",
        color:"",
        solventForExtracti:"",
        shelflife: "未开封情况下3年保质期。产品对热敏感。",
        shelflifeNumber:"",
        storage: "避光，密封保存。",
        percentage: "",
        productType: "",
        BaseInfoItemCode: "",
        BaseInfoProductName: "",
        BaseInfoProductDescription: "",
        BaseInfoCustomProductName: "",
        inciName: "",
        commonName: "",
        botanicalName: "",
        nativeHerbs: "",
        partUsed: "",

        GeneralSpecCommonName: "",
        GeneralSpecINCIName: "",
        GeneralSpecBotanicalName: "",
        GeneralSpecNativeHerbs: "",
        GeneralSpecPartUsed: "",
        GeneralSpecCarrier: "",
        GeneralSpecPreservative: "",
        GeneralSpecCustomRowKey1: "",
        GeneralSpecCustomRowValue1: "",
        GeneralSpecCustomRowKey2: "",
        GeneralSpecCustomRowValue2: "",
        GeneralSpecCustomRowKey3: "",
        GeneralSpecCustomRowValue3: "",

        analysisRangeBioactivesLevel: "",
        analysisTestBioactivesLevel: "",
        analysisRangePh: "3.5-7.5",
        analysisRangeHeavyMetal: "符合USP <231>",
        analysisTestPh: "符合USP <791>",
        analysisRangePesticide: "符合USP <565>",
        analysisTestPesticide: "",
        analysisRangeTotalPlateCount: "100",
        analysisTestTotalPlateCount: "",
        analysisRangeYeastMold: "100",
        analysisMethodBioactivesLevel: "",
        analysisMethodPh: "pH Meter",
        analysisMethodHeavyMetal: "USP<231>",
        analysisMethodPesticide: "USP<565>",
        analysisMethodTotalPlateCount: "FDA-BAM",
        analysisMethodYeastMold: "FDA-BAM",
        analysisMethodEColi: "FDA-BAM",
        analysisMethodSalmonella: "FDA-BAM",
        analysisMethodStaphAureus: "FDA-BAM",
        analysisMethodTotalColiform: "FDA-BAM",
        specificationArsenic: "1",
        specificationCadmium: "1",
        specificationLead: "1",
        specificationMercury: "1",
        testMethodArsenic: "USP<232>",
        testMethodCadmium: "USP<232>",
        testMethodLead: "USP<232>",
        testMethodMercury: "USP<232>",

        liquidExtCustomerKey1: "",
        liquidExtCustomerValue1: "",
        liquidExtCustomerKey2: "",
        liquidExtCustomerValue2: "",
        liquidExtCustomerKey3: "",
        liquidExtCustomerValue3: "",
        liquidExtCustomerKey4: "",
        liquidExtCustomerValue4: "",
        liquidExtCustomerKey5: "",
        liquidExtCustomerValue5: "",
        liquidExtCustomerKey6: "",
        liquidExtCustomerValue6: "",
        liquidExtCustomerValue1TM: "", 
        liquidExtCustomerValue2TM: "", 
        liquidExtCustomerValue3TM: "", 
        liquidExtCustomerValue4TM: "", 
        liquidExtCustomerValue5TM: "",
        liquidExtCustomerValue6TM: "",
        majorConstituentsInNativeHerbs: "",
        genSpecCustomField: "",
        analysisCustomField: "",

        tableInput1:"",
        tableInput2:"",
        tableInput3:"",
        tableInput4:"",
        tableInput5:"",
        tableInputCustomField:"",
        isShowNotes2:true
      },
      formData: {
        id: 0,
        productName: "",
        itemCode: "",
        lot: "",
        jsonValue: {},
      },
      loading: false,
      isSelectPONumber: false,
      isSelectCustomerCode: false,
      printShow: false,
      printObj: {
        id: "printDiv", // 这里是要打印元素的ID
        popTitle: "", // 打印的标题
        extraCss: "", // 打印可引入外部的一个 css 文件
        extraHead: "", // 打印头部文字
      },
      createTime: "",
      genSpecCustomFieldAttr: [],
      analysisCustomFieldAttr: [],
      customField: {
        customKey: "",
        customValue: "",
        customKey1: "",
        customValue1: "",
        customKey2: "",
        customValue2: "",
      },
      tableInputCustomFieldAttr: [],
      tableInputField: {
        tableInput1: "",
        tableInput2: "",
        tableInput3: "",
        tableInput4: "",
        tableInput5: "",
      },
    };
  },
  methods:newItemMethod,
  mounted() {
    let params = { ...this.$route.query };
    if (params.id) {
      this.isShowVertify = true
      this.getCnSpecLData(params);
      this.formData.id = params.id;
      this.newItemType="Edit";
    }else {
      this.newItemType = "New";
    }
    let localData = localStorage.getItem("tpsDataKey");
    let data;
    if (localData != "undefined") {
      data = JSON.parse(localStorage.getItem("tpsDataKey"));
    }

    if (data) {
      Object.keys(data).forEach((key) => {
        this.form[key] = data[key];
      });
    }
  },
  watch: {
    baseInfoDetail: {
      handler(basInfo) {
        console.log("liquid====basInfo")
        console.log(basInfo)
        this.form.productType=basInfo.params.productType;
        this.form.BaseInfoProductName=basInfo.params.BaseInfoProductName;
        this.form.BaseInfoItemCode=basInfo.params.BaseInfoItemCode;
        this.form.BaseInfoProductDescription=basInfo.params.BaseInfoProductDescription;
        this.form.versionNumber=basInfo.params.versionNumber;
        this.baseInfoCustomFieldList = basInfo.BaseInfoCustomFieldAttr
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../module/NewItemStyle.scss";
</style>
<style>
@import "../module/CoaStyle.css";
</style>
