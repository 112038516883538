<template>
  <div v-if="isSignature">
    <p></p>
    <p></p>
    <div>
      <div style="clear: both; height: 50px">客户签名:</div>
      <div style="clear: both; height: 50px">
        <div style="float: left; height: 20px; width: 100px; text-align: right">
          公司名称：
        </div>
        <div
          style="
            border-bottom: 1px solid black;
            width: 200px;
            float: left;
            height: 20px;
          "
        ></div>
      </div>
      <div style="clear: both; height: 50px">
        <div style="float: left; width: 350px">
          <div
            style="float: left; height: 20px; width: 100px; text-align: right"
          >
            联系人：
          </div>
          <div
            style="
              border-bottom: 1px solid black;
              width: 200px;
              float: left;
              height: 20px;
            "
          ></div>
        </div>
        <div style="float: left">
          <div
            style="float: left; height: 20px; width: 100px; text-align: right"
          >
            职务：
          </div>
          <div
            style="
              border-bottom: 1px solid black;
              width: 200px;
              float: left;
              height: 20px;
            "
          ></div>
        </div>
      </div>
      <div style="clear: both; height: 50px">
        <div style="float: left; width: 350px">
          <div
            style="float: left; height: 20px; width: 100px; text-align: right"
          >
            签名：
          </div>
          <div
            style="
              border-bottom: 1px solid black;
              width: 200px;
              float: left;
              height: 20px;
            "
          ></div>
        </div>
        <div style="float: left">
          <div
            style="float: left; height: 20px; text-align: right; width: 100px"
          >
            日期：
          </div>
          <div
            style="
              border-bottom: 1px solid black;
              width: 200px;
              float: left;
              height: 20px;
            "
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isSignature: false
    };
  },
  mounted() {
    this.$EventBus.$on("signatureChange", val => {
      this.isSignature = val;
    });
  }
};
</script>
