<template>
  <div class="page" style="text-align: left">
    <!-- <div class="watermark" style="color: coral;height: 100%;width: 100%;position: absolute; margin: 0;text-align: center;line-height:100%;">ggsdfsdfsd</div> -->
    <div class="row">
      <div style="width: 99%">
        <div class="header" id="header" style="width: 100%; height: 100px">
          <div style="width: 11%; float: left">
            <img
              src="../../assets/print_logo.jpg"
              style="width: 100px; height: 100px"
            />
          </div>
          <div style="width: 89%; float: right">
            <p
              style="
                font-size: 28px;
                font-family: Times New Roman;
                text-align: center;
                vertical-align: text-top;
              "
            >
              <b v-if="printModel.form.BaseInfoEditPrintTitleCheckbox==false">Draco Natural Products, Inc. </b>
              <b v-if="printModel.form.BaseInfoEditPrintTitleCheckbox">{{printModel.form.BaseInfoEditPrintTitle}}</b>
              <!-- <b>Draco Natural Products, Inc. </b> -->
            </p>
            <p
              style="
                font-size: 28px;
                font-family: Times New Roman;
                text-align: center;
                vertical-align: text-top;
              "
            >
              <!-- <b><u>TECHNICAL DATA SHEET</u></b> -->
              <b v-if="printModel.form.BaseInfoEditPrintSubtitleCheckbox==false"><u>TECHNICAL DATA SHEET</u></b>
              <b v-if="printModel.form.BaseInfoEditPrintSubtitleCheckbox"><u>{{printModel.form.BaseInfoEditPrintSubtitle}}</u></b>
            </p>
          </div>
        </div>

        <!-- Product info -->
        <table style="width: 99%">
          <tr v-show="printModel.form.BaseInfoProductName">
            <td style="width: 25%"><b>产品名称</b></td>
            <td style="width: 75%">
              <span t-field="o.name" />{{ printModel.form.BaseInfoProductName }}
            </td>
          </tr>
          <tr v-show="printModel.form.versionNumber">
            <td style="width: 25%"><b>版本号</b></td>
            <td style="width: 75%">
              {{ printModel.form.versionNumber }}
            </td>
          </tr>

          <tr v-show="printModel.form.BaseInfoItemCode">
            <td style="width: 25%"><b>产品编号</b></td>
            <td style="width: 75%">
              <span t-field="o.default_code" />{{ printModel.form.BaseInfoItemCode }}
            </td>
          </tr>
          <tr v-show="printModel.form.BaseInfoProductDescription">
            <td style="width: 25%"><b>产品说明</b></td>
            <td style="width: 75%">
              <span t-field="o.description" />{{
                printModel.form.BaseInfoProductDescription
              }}
            </td>
          </tr>
        </table>
        <table style="width: 99%" v-show="printModel.baseInfoCustomFieldList && printModel.baseInfoCustomFieldList.length>0">
          <tr v-for="(baseinfoCustom,baseinfoIndex) in printModel.baseInfoCustomFieldList" :key="baseinfoIndex">
            <td style="width: 25%"><b>{{baseinfoCustom.baseInfoInput1}}</b></td>
            <td style="width: 75%">
              {{ baseinfoCustom.baseInfoInput2 }}
            </td>
          </tr>
        </table>

        <div style="height: 10px"></div>
        <div style="font-size: 18px">
          <b>标准规格</b>
        </div>
        
        <!-- 遍历table -->
        <template
          v-if="
            printModel.inclNameArr.length > 0 ||
            printModel.botanicalNameArr.length > 0 ||
            printModel.commonNameArr.length > 0 ||
            printModel.partUsedArr.length > 0
          "
        >
          <table
            style="width: 98%;border-collapse: collapse;"
            cellspacing="0"
            class="tab"
          >
            <thead>
              <tr style="text-align: left;border-bottom:2px solid gray">
                <th v-show="printModel.commonNameArr.length > 0">通用名/其他名称</th>
                <th v-show="printModel.inclNameArr.length>0">INCI名称</th>
                <th v-show="printModel.botanicalNameArr.length > 0">植物学名</th>
                <th v-show="printModel.partUsedArr.length > 0">使用部位</th>
              </tr>
            </thead>
            <tbody>
              <tr :key="i" v-for="(tag, i) in maxArr">
                <td v-show="i + 1 <= printModel.commonNameArr.length">
                  {{ printModel.commonNameArr[i] }}
                </td>
                <td v-show="(i+1)<=printModel.inclNameArr.length">{{printModel.inclNameArr[i]}}</td>
                <td v-show="i + 1 <= printModel.botanicalNameArr.length" style="font-style:italic;">
                  {{ printModel.botanicalNameArr[i] }}
                </td>
                <td v-show="i + 1 <= printModel.partUsedArr.length">
                  {{ printModel.partUsedArr[i] }}
                </td>
              </tr>
            </tbody>
          </table>
        </template>
        
        <template v-if="isShowTable">
          <table
            style="width: 98%;margin-top:10px;"
            cellspacing="0"
            class="tab"
          >
            <tbody>
              <tr>
                <td v-show="printModel.form.tableInput1" style="width:20%;">{{ printModel.form.tableInput1 }}</td>
                <td v-show="printModel.form.tableInput2" style="width:20%;">{{ printModel.form.tableInput2 }}</td>
                <td v-show="printModel.form.tableInput3" style="width:20%;">{{ printModel.form.tableInput3 }}</td>
                <td v-show="printModel.form.tableInput4" style="width:20%;">{{ printModel.form.tableInput4 }}</td>
                <td v-show="printModel.form.tableInput5" style="width:20%;">{{ printModel.form.tableInput5 }}</td>
              </tr>
              <tr v-for="(item, tableInputIndex) in printModel.tableInputCustomFieldAttr" :key="tableInputIndex + 1">
                <td v-show="item.tableInput1">{{ item.tableInput1 }}</td>
                <td v-show="item.tableInput2">{{ item.tableInput2 }}</td>
                <td v-show="item.tableInput3">{{ item.tableInput3 }}</td>
                <td v-show="item.tableInput4">{{ item.tableInput4 }}</td>
                <td v-show="item.tableInput5">{{ item.tableInput5 }}</td>
              </tr>
            </tbody>
          </table>
        </template>

        <table style="width: 99%">
          <tbody>
            <tr v-show="printModel.form.appearance">
              <td style="width: 30%"><b>外观</b></td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{
                  printModel.form.appearance
                }}</span>
              </td>
            </tr>

            <tr v-show="printModel.form.color.length > 0">
              <td style="width: 30%"><b>颜色</b></td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{ printModel.form.color }}</span>
              </td>
            </tr>

            <tr v-show="printModel.form.odor.length > 0">
              <td style="width: 30%"><b>气味</b></td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{ printModel.form.odor }}</span>
              </td>
            </tr>

            <tr v-show="printModel.form.taste.length > 0">
              <td style="width: 30%"><b>味道</b></td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{ printModel.form.taste }}</span>
              </td>
            </tr>
            <tr v-show="printModel.form.otherIngredients">
              <td style="width: 30%"><b>其他成分</b></td>
              <td style="width: 35%">
                <span t-field="o.apperance">{{ printModel.form.otherIngredients }}</span>
              </td>
              <td style="width: 35%">
              </td>
            </tr>

            <tr v-show="printModel.form.customValue1">
              <td style="width: 30%">
                <b>{{ printModel.form.customKey1 }}</b>
              </td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{
                  printModel.form.customValue1
                }}</span>
              </td>
            </tr>

            <tr v-show="printModel.form.customValue2">
              <td style="width: 30%">
                <b>{{ printModel.form.customKey2 }}</b>
              </td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{
                  printModel.form.customValue2
                }}</span>
              </td>
            </tr>

            <tr v-show="printModel.form.customValue3">
              <td style="width: 30%">
                <b>{{ printModel.form.customKey3 }}</b>
              </td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{
                  printModel.form.customValue3
                }}</span>
              </td>
            </tr>
          </tbody>
        </table>

        <!-- Microbiological Tests -->
        <div style="height: 10px"></div>
        <table style="width: 99%">
          <thead>
            <tr>
              <th style="width: 30%">
                <b><u>分析</u></b>
              </th>
              <th style="width: 35%"><u>范围</u></th>
              <th style="width: 35%"><u>测试方法</u></th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="printModel.form.testLimitAcidValue.length > 0">
              <td style="width: 30%"><b>酸价</b></td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold" />NMT {{
                  printModel.form.testLimitAcidValue
                }}mg KOH/G
              </td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />{{
                  printModel.form.testMethodAcidValue
                }}
              </td>
            </tr>

            <tr v-if="printModel.form.testLimitPeroxideValue.length > 0">
              <td style="width: 30%"><b>过氧化物值</b></td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold" />NMT {{
                  printModel.form.testLimitPeroxideValue
                }} meq/kg
              </td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />{{
                  printModel.form.testMethodPeroxideValue
                }}
              </td>
            </tr>

            <tr v-if="printModel.form.testLimitSaponificationValue.length > 0">
              <td style="width: 30%"><b>皂化值</b></td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold" />{{
                  printModel.form.testLimitSaponificationValue
                }}mg KOH/g
              </td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />{{
                  printModel.form.testMethodSaponificationValue
                }}
              </td>
            </tr>

            <tr v-if="printModel.form.testLimitUnsaponifiableMaster.length > 0">
              <td style="width: 30%"><b>不皂化物含量</b></td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold" />{{
                  printModel.form.testLimitUnsaponifiableMaster
                }}
                <template v-if="printModel.form.testLimitUnsaponifiableMaster.length > 0"
                  >%</template
                >
              </td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />{{
                  printModel.form.testMethodUnsaponifiableMaster
                }}
              </td>
            </tr>

            <tr v-if="printModel.form.testLimitMoistureVolatiles.length > 0">
              <td style="width: 30%"><b>水分和挥发物</b></td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold" />{{
                  printModel.form.testLimitMoistureVolatiles
                }}
                <template v-if="printModel.form.testLimitMoistureVolatiles.length > 0"
                  >%</template
                >
              </td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />{{
                  printModel.form.testMethodMoistureVolatiles
                }}
              </td>
            </tr>

            <tr v-if="printModel.form.testLimitHeavyMetals">
              <td style="width: 30%"><b>总重金属</b></td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold" /> &lt;{{ printModel.form.testLimitHeavyMetals }} ppm
              </td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />{{ printModel.form.testMethodHeavyMetals }}
              </td>
            </tr>

            <tr v-if="printModel.form.testLimitLead">
              <td style="width: 30%"><b>铅</b></td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold" />{{ printModel.form.testLimitLead }} ppm
              </td>
              <!-- <td style="width:35%"><span t-field="o.yeast_mold_test"/>AOAC 986.15</td> -->
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />{{ printModel.form.testMethodLead }}
              </td>
            </tr>

            <tr v-if="printModel.form.testLimitArsenic.length > 0">
              <td style="width: 30%"><b>砷</b></td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold" />{{ printModel.form.testLimitArsenic }} ppm
              </td>
              <!-- <td style="width:35%"><span t-field="o.yeast_mold_test"/>AOAC 986.15</td> -->
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />{{ printModel.form.testMethodArsenic }}
              </td>
            </tr>

            <tr v-if="printModel.form.testLimitCadmium.length > 0">
              <td style="width: 30%"><b>镉</b></td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold" />{{ printModel.form.testLimitCadmium }} ppm
              </td>
              <!-- <td style="width:35%"><span t-field="o.yeast_mold_test"/>AOCS Ca 18c-91</td> -->
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />{{ printModel.form.testMethodCadmium }}
              </td>
            </tr>

            <tr v-if="printModel.form.testLimitMercury.length > 0">
              <td style="width: 30%"><b>汞</b></td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold" />{{ printModel.form.testLimitMercury }} ppm
              </td>
              <!-- <td style="width:35%"><span t-field="o.yeast_mold_test"/>AOAC 971.21</td> -->
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />{{ printModel.form.testMethodMercury }}
              </td>
            </tr>

            <tr v-if="printModel.form.testLimitPesticides.length > 0">
              <td style="width: 30%"><b>农药残留</b></td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold" />{{ printModel.form.testLimitPesticides }}
              </td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />{{
                  printModel.form.testMethodPesticides
                }}
              </td>
            </tr>

            <tr v-show="printModel.form.testLimitCustomKey1">
              <td style="width: 30%">
                <b>{{ printModel.form.testLimitCustomKey1 }}</b>
              </td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold" />{{
                  printModel.form.testLimitCustomKey1Value
                }}
              </td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />{{
                  printModel.form.testMethodCustomKey1Value
                }}
              </td>
            </tr>
            <tr v-show="printModel.form.testLimitCustomKey2">
              <td style="width: 30%">
                <b>{{ printModel.form.testLimitCustomKey2 }}</b>
              </td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold" />{{
                  printModel.form.testLimitCustomKey2Value
                }}
              </td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />{{
                  printModel.form.testMethodCustomKey2Value
                }}
              </td>
            </tr>
            
            <tr v-show="printModel.form.specificationTotalPlateCount">
              <td style="width: 30%"><b>细菌总数</b></td>
              <td style="width: 35%">
                <span t-field="o.total_plate" />&lt;{{
                  printModel.form.specificationTotalPlateCount
                }} cfu/g
              </td>
              <td style="width: 35%">
                <span t-field="o.total_plate_test" />
                {{ printModel.form.testMethodTotalPlateCount }}
              </td>
            </tr>

            <tr v-show="printModel.form.specificationYeastAndMold">
              <td style="width: 30%"><b>酵母和霉菌</b></td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold" />&lt;{{
                  printModel.form.specificationYeastAndMold
                }} cfu/g
              </td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />{{
                  printModel.form.testMethodYeastAndMold
                }}
              </td>
            </tr>

            <tr v-show="printModel.form.specificationEcoli">
              <td style="width: 30%"><b>大肠杆菌</b></td>
              <td style="width: 35%" v-if="
            !printModel.form.specificationEcoli || 
            printModel.form.specificationEcoli.toLowerCase()=='negative' || 
            printModel.form.specificationEcoli.toLowerCase()=='阴性'">
              {{ printModel.form.specificationEcoli }}
            </td>
            <td style="width: 35%" v-else>
              &lt;{{ printModel.form.specificationEcoli }} cfu/g
            </td>
              <!-- <td style="width: 35%">
                <span t-field="o.yeast_mold" />{{
                  printModel.form.specificationEcoli
                }}<span
                  v-if="printModel.form.specificationEcoli != '阴性' && printModel.form.specificationEcoli.toLowerCase() != 'negative'"
                  > cfu/g</span
                >
              </td> -->
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />{{
                  printModel.form.testMethodEcoli
                }}
              </td>
            </tr>

            <tr v-show="printModel.form.specificationSalmonella">
              <td style="width: 30%"><b>沙门氏菌</b></td>
              <td style="width: 35%" v-if="
            !printModel.form.specificationSalmonella || 
            printModel.form.specificationSalmonella.toLowerCase()=='negative' || 
            printModel.form.specificationSalmonella.toLowerCase()=='阴性'">
              {{ printModel.form.specificationSalmonella }}
            </td>
            <td style="width: 35%" v-else>
              &lt;{{ printModel.form.specificationSalmonella }} cfu/g
            </td>
              <!-- <td style="width: 35%">
                <span t-field="o.yeast_mold" />{{
                  printModel.form.specificationSalmonella
                }}<span
                  v-if="printModel.form.specificationSalmonella != '阴性' && printModel.form.specificationSalmonella.toLowerCase() != 'negative'"
                  > cfu/g</span
                >
              </td> -->
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />{{
                  printModel.form.testMethodSalmonella
                }}
              </td>
            </tr>

            <tr v-show="printModel.form.specificationStaphAureus">
              <td style="width: 30%"><b>金色葡萄球菌</b></td>
              <td style="width: 35%" v-if="
            !printModel.form.specificationStaphAureus || 
            printModel.form.specificationStaphAureus.toLowerCase()=='negative' || 
            printModel.form.specificationStaphAureus.toLowerCase()=='阴性'">
              {{ printModel.form.specificationStaphAureus }}
            </td>
            <td style="width: 35%" v-else>
              &lt;{{ printModel.form.specificationStaphAureus }} cfu/g
            </td>
              <!-- <td style="width: 35%">
                <span t-field="o.yeast_mold" />{{
                  printModel.form.specificationStaphAureus
                }}
              </td> -->
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />{{
                  printModel.form.testMethodTaphAureus
                }}
              </td>
            </tr>

            <tr v-show="printModel.form.specificationTotalColiforms">
              <td style="width: 30%"><b>大肠菌群</b></td>
              <!-- <td style="width: 35%">
                <span t-field="o.yeast_mold" />&lt;{{
                  printModel.form.specificationTotalColiforms
                }} MPN/g
              </td> -->
              
              <td style="width: 35%" v-if="
            !printModel.form.specificationTotalColiforms || 
            printModel.form.specificationTotalColiforms.toLowerCase()=='negative' || 
            printModel.form.specificationTotalColiforms.toLowerCase()=='阴性'">
              {{ printModel.form.specificationTotalColiforms }}
            </td>
            <td style="width: 35%" v-else>
              &lt;{{ printModel.form.specificationTotalColiforms }} MPN/g
            </td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />{{
                  printModel.form.testMethodTotalColiforms
                }}
              </td>
            </tr>

            <tr v-if="printModel.form.microbiologicalCustomerKey1">
              <td style="width: 30%">
                <b>{{ printModel.form.microbiologicalCustomerKey1 }}</b>
              </td>
              <td style="width: 35%">
                {{ printModel.form.microbiologicalCustomerValue1 }}
              </td>
              <td style="width: 35%">
                {{ printModel.form.microbiologicalTestMethodCustomerValue1 }}
              </td>
            </tr>
          </tbody>
          <tbody v-for="(item, analysisIndex) in printModel.analysisCustomFieldAttr" :key="analysisIndex" >
            <tr v-show="item.customKey">
              <td style="width: 35%">
                <b>{{ item.customKey }}</b>
              </td>
              <td style="width: 35%">
                <span t-field="o.apperance">{{ item.customValue }}</span>
              </td>
              <td style="width: 35%">
                <span t-field="o.apperance">{{ item.customValue1 }}</span>
              </td>
            </tr>
          </tbody>
          <tbody v-for="(item, micTestsIndex) in printModel.micTestsCustomFieldAttr" :key="micTestsIndex" >
            <tr v-show="item.customKey">
              <td style="width: 35%">
                <b>{{ item.customKey }}</b>
              </td>
              <td style="width: 35%">
                <span t-field="o.apperance">{{ item.customValue }}</span>
              </td>
              <td style="width: 35%">
                <span t-field="o.apperance">{{ item.customValue1 }}</span>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- Analysis -->
        <div style="height: 10px"></div>
        <table style="width: 99%"
        v-if="printModel.form.fattryLimitCaprylic 
         || printModel.form.fattryLimitCapric 
         || printModel.form.fattryLimitLauric
         || printModel.form.fattryLimitMyristic
         || printModel.form.fattryLimitMyristoleic
         || printModel.form.fattryLimitPalmitica
         || printModel.form.fattryLimitPalmitoleic
         || printModel.form.fattryLimitStearic 
         || printModel.form.fattryLimitOleicVaccenic 
         || printModel.form.fattryLimitLinoleic 
         || printModel.form.fattryLimitALinolenic
         || printModel.form.fattryLimitLinoleicacid
         || printModel.form.fattryLimitPunicicacid
         || printModel.form.fattryLimitArachidic
         || printModel.form.fattryLimitArachidonic
         || printModel.form.fattryLimitEicosapentaenoic
         || printModel.form.fattryLimitDocosahexaenoic
         || printModel.form.fattryLimitKey1
         || printModel.form.fattryLimitKey2
         || printModel.form.fattryLimitKey3
         || printModel.fattyCustomFieldAttr.length>0
         || printModel.form.fatterLimitOthers">
          <tbody>
            <tr>
              <td style="width: 30%">
                <u><b>脂肪酸组成</b></u>
              </td>
              <td style="width: 35%">
                <u><b>范围</b></u>
              </td>
              <td style="width: 35%">
                <u><b>测试方法</b></u>
              </td>
            </tr>
            <tr v-if="printModel.form.fattryLimitCaprylic">
              <td style="width: 30%"><b>C8:0辛酸 </b></td>
              <td style="width: 35%">{{ printModel.form.fattryLimitCaprylic }}%</td>
              <td style="width: 35%">{{ printModel.form.fattryMethodCaprylic }}</td>
            </tr>
            <tr v-if="printModel.form.fattryLimitCapric">
              <td style="width: 30%"><b>C10:0癸酸 </b></td>
              <td style="width: 35%">{{ printModel.form.fattryLimitCapric }}%</td>
              <td style="width: 35%">{{ printModel.form.fattryMethodCapric }}</td>
            </tr>

            <tr v-if="printModel.form.fattryLimitLauric">
              <td style="width: 30%"><b>C12:0月桂酸 </b></td>
              <td style="width: 35%">{{ printModel.form.fattryLimitLauric }}%</td>
              <td style="width: 35%">{{ printModel.form.fattryMethodLauric }}</td>
            </tr>

            <tr v-if="printModel.form.fattryLimitMyristic">
              <td style="width: 30%"><b>C14:0豆蔻酸 </b></td>
              <td style="width: 35%">{{ printModel.form.fattryLimitMyristic }}%</td>
              <td style="width: 35%">{{ printModel.form.fattryMethodMyristic }}</td>
            </tr>

            <tr v-if="printModel.form.fattryLimitMyristoleic">
              <td style="width: 30%"><b>C14:1豆蔻油酸  </b></td>
              <td style="width: 35%">{{ printModel.form.fattryLimitMyristoleic }}%</td>
              <td style="width: 35%">{{ printModel.form.fattryMethodMyristoleic }}</td>
            </tr>
            <tr v-if="printModel.form.fattryLimitPalmitica">
              <td style="width: 30%"><b>C16：0棕榈酸 </b></td>
              <td style="width: 35%">{{ printModel.form.fattryLimitPalmitica }}%</td>
              <td style="width: 35%">{{ printModel.form.fattryMethodPalmitica }}</td>
            </tr>

            <tr v-if="printModel.form.fattryLimitPalmitoleic">
              <td style="width: 30%"><b>C16：1棕榈油酸</b></td>
              <td style="width: 35%">{{ printModel.form.fattryLimitPalmitoleic }}%</td>
              <td style="width: 35%">{{ printModel.form.fattryMethodPalmitoleic }}</td>
            </tr>

            <tr v-if="printModel.form.fattryLimitStearic">
              <td style="width: 30%"><b>C18：0硬脂酸</b></td>
              <td style="width: 35%">{{ printModel.form.fattryLimitStearic }}%</td>
              <td style="width: 35%">{{ printModel.form.fattryMethodStearic }}</td>
            </tr>

            <tr v-if="printModel.form.fattryLimitOleicVaccenic">
              <td style="width: 30%"><b>C18：1油酸</b></td>
              <td style="width: 35%">
                <span t-field="o.standard2">{{ printModel.form.fattryLimitOleicVaccenic }}%</span>
              </td>
              <td style="width: 35%">{{ printModel.form.fattryMethodOleicVaccenic }}</td>
            </tr>

            <tr v-if="printModel.form.fattryLimitLinoleic">
              <td style="width: 30%"><b>C18：2亚油酸</b></td>
              <td style="width: 35%">{{ printModel.form.fattryLimitLinoleic }}%</td>
              <td style="width: 35%">{{ printModel.form.fattryMethodLinoleic }}</td>
            </tr>

            <tr v-if="printModel.form.fattryLimitALinolenic">
              <td style="width: 30%"><b>C18：3 α-亚麻酸</b></td>
              <td style="width: 35%">
                {{ printModel.form.fattryLimitALinolenic }}
                <template v-if="printModel.form.fattryLimitALinolenic"
                  >%</template
                >
              </td>
              <td style="width: 35%">{{ printModel.form.fattryMethodALinolenic }}</td>
            </tr>

            <tr v-if="printModel.form.fattryLimitLinoleicacid">
              <td style="width: 30%"><b>C18:3 γ-亚麻酸</b></td>
              <td style="width: 35%">{{ printModel.form.fattryLimitLinoleicacid }}%</td>
              <td style="width: 35%">{{ printModel.form.fattryMethodLinoleicacid }}</td>
            </tr>

            <tr v-if="printModel.form.fattryLimitPunicicacid">
              <td style="width: 30%"><b>C18:3 γ-亚麻酸</b></td>
              <td style="width: 35%">{{ printModel.form.fattryLimitPunicicacid }}%</td>
              <td style="width: 35%">{{ printModel.form.fattryMethodPunicicacid }}</td>
            </tr>

            <tr v-if="printModel.form.fattryLimitArachidic">
              <td style="width: 30%"><b>C20:0花生酸/AA </b></td>
              <td style="width: 35%">{{ printModel.form.fattryLimitArachidic }}%</td>
              <td style="width: 35%">{{ printModel.form.fattryMethodArachidic }}</td>
            </tr>

            <tr v-if="printModel.form.fattryLimitArachidonic">
              <td style="width: 30%"><b>C20:4花生四烯酸 </b></td>
              <td style="width: 35%">{{ printModel.form.fattryLimitArachidonic }}%</td>
              <td style="width: 35%">{{ printModel.form.fattryMethodArachidonic }}</td>
            </tr>

            <tr v-if="printModel.form.fattryLimitEicosapentaenoic">
              <td style="width: 30%"><b>C20:5二十碳五烯酸/EPA</b></td>
              <td style="width: 35%">{{ printModel.form.fattryLimitEicosapentaenoic }}%</td>
              <td style="width: 35%">{{ printModel.form.fattryMethodEicosapentaenoic }}</td>
            </tr>

            <tr v-if="printModel.form.fattryLimitDocosahexaenoic">
              <td style="width: 30%"><b>C 22:6二十二碳六烯酸/DHA </b></td>
              <td style="width: 35%">{{ printModel.form.fattryLimitDocosahexaenoic }}%</td>
              <td style="width: 35%">{{ printModel.form.fattryMethodDocosahexaenoic }}</td>
            </tr>

            <tr v-show="printModel.form.fattryLimitKey1">
              <td style="width: 30%">
                <b>{{ printModel.form.fattryLimitKey1 }}</b>
              </td>
              <td style="width: 35%">{{ printModel.form.fattryLimitKey1Value }}</td>
              <td style="width: 35%">{{ printModel.form.fattryMethodKey1Value }}</td>
            </tr>
            <tr v-show="printModel.form.fattryLimitKey2">
              <td style="width: 30%">
                <b>{{ printModel.form.fattryLimitKey2 }}</b>
              </td>
              <td style="width: 35%">{{ printModel.form.fattryLimitKey2Value }}</td>
              <td style="width: 35%">{{ printModel.form.fattryMethodKey2Value }}</td>
            </tr>
            <tr v-show="printModel.form.fattryLimitKey3">
              <td style="width: 30%">
                <b>{{ printModel.form.fattryLimitKey3 }}</b>
              </td>
              <td style="width: 35%">{{ printModel.form.fattryLimitKey3Value }}</td>
              <td style="width: 35%">{{ printModel.form.fattryMethodKey3Value }}</td>
            </tr>
          </tbody>
          <tbody v-for="(item, fattyIndex) in printModel.fattyCustomFieldAttr" :key="fattyIndex" >
            <tr v-show="item.customKey">
              <td style="width: 35%">
                <b>{{ item.customKey }}</b>
              </td>
              <td style="width: 35%">
                <span t-field="o.apperance">{{ item.customValue }}</span>
              </td>
              <td style="width: 35%">
                <span t-field="o.apperance">{{ item.customValue1 }}</span>
              </td>
            </tr>
          </tbody>
          
          <tbody>
            <tr v-show="printModel.form.fatterLimitOthers">
              <td style="width: 30%"><b>其他</b></td>
              <td style="width: 35%">
                {{ printModel.form.fatterLimitOthers }}
                <template>%</template>
              </td>
              <td style="width: 35%">{{ printModel.form.fatterMethodOthers }}</td>
            </tr>
          </tbody>
        </table>

        <!-- <div style="height: 10px"></div>
        <table style="width: 99%">
          <tbody>
            <tr>
              <th style="width: 30%">
                <b><u>微生物检验</u></b>
              </th>
              <th style="width: 35%"><u>规格</u></th>
              <th style="width: 35%"><u>测试方法</u></th>
            </tr>
            <tr v-show="printModel.form.specificationTotalPlateCount">
              <td style="width: 30%"><b>细菌总数</b></td>
              <td style="width: 35%">
                <span t-field="o.total_plate" />&lt;{{
                  printModel.form.specificationTotalPlateCount
                }}
                cfu/g
              </td>
              <td style="width: 35%">
                <span t-field="o.total_plate_test" />
                {{ printModel.form.testMethodTotalPlateCount }}
              </td>
            </tr>

            <tr v-show="printModel.form.specificationYeastAndMold">
              <td style="width: 30%"><b>酵母和霉菌</b></td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold" />&lt;{{
                  printModel.form.specificationYeastAndMold
                }}
                cfu/g
              </td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />{{
                  printModel.form.testMethodYeastAndMold
                }}
              </td>
            </tr>

            <tr v-show="printModel.form.specificationEcoli">
              <td style="width: 30%"><b>大肠杆菌</b></td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold" />{{
                  printModel.form.specificationEcoli
                }}
              </td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />{{
                  printModel.form.testMethodEcoli
                }}
              </td>
            </tr>

            <tr v-show="printModel.form.specificationSalmonella">
              <td style="width: 30%"><b>沙门氏菌</b></td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold" />{{
                  printModel.form.specificationSalmonella
                }}
              </td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />{{
                  printModel.form.testMethodSalmonella
                }}
              </td>
            </tr>

            <tr v-show="printModel.form.specificationStaphAureus">
              <td style="width: 30%"><b>金色葡萄球菌</b></td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold" />{{
                  printModel.form.specificationStaphAureus
                }}
              </td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />{{
                  printModel.form.testMethodTaphAureus
                }}
              </td>
            </tr>

            <tr v-show="printModel.form.specificationTotalColiforms">
              <td style="width: 30%"><b>大肠菌群</b></td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold" />&lt;{{
                  printModel.form.specificationTotalColiforms
                }}
                MPN/g
              </td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />{{
                  printModel.form.testMethodTotalColiforms
                }}
              </td>
            </tr>

            <tr v-if="printModel.form.microbiologicalCustomerKey1">
              <td style="width: 30%">
                <b>{{ printModel.form.microbiologicalCustomerKey1 }}</b>
              </td>
              <td style="width: 35%">
                {{ printModel.form.microbiologicalCustomerValue1 }}
              </td>
              <td style="width: 35%">
                {{ printModel.form.microbiologicalTestMethodCustomerValue1 }}
              </td>
            </tr>
          </tbody>
        </table> -->
        <div style="height: 10px"></div>
        <table style="width: 99%">
          <tbody>
            <tr>
              <td style="width: 30%; font-size: 18px">
                <b><u>其他</u></b>
              </td>
              <td style="width: 70%" />
            </tr>
            <tr v-if="printModel.form.shelflife">
              <td style="width: 30%"><b>保质期</b></td>
              <td style="width70%">
                <span t-field="o.shelf_life" />{{ printModel.form.shelflife }}
              </td>
            </tr>

            <tr v-if="printModel.form.storage">
              <td style="width: 30%"><b>储存条件</b></td>
              <td style="width70%">
                <span t-field="o.storage" />{{ printModel.form.storage }}
              </td>
            </tr>

            <tr v-show="printModel.form.packageInfo">
              <td style="width: 30%"><b>包装 </b></td>
              <td style="width70%">
                <span t-field="o.shelf_life" />{{
                  printModel.form.packageInfo
                }}
              </td>
            </tr>
          </tbody>
        </table>

        <div style="clear: both"></div>
        <div style="height: 20px"></div>
        <div style="font-size: 18px">
          <b><u>注释</u></b>
        </div>
        <div
          class="footer"
          id="footer"
          style="
            font-size: 11px;
            font-family: Times New Roman;
            margin-top: 10px;
          "
        >
          <div>
            <span
              ><i
                >* 不同批次产品的颜色可能会因天然原材料的自然变化而产生差异。</i
              ></span
            >
          </div>
          <div style="height: 10px"></div>
          <div v-if="printModel.form.isShowNotes2">
            <span><i>* 产品含抗氧化化合物，可能会随着时间推移颜色变深但不影响使用效果。</i></span>
          </div>
          <div style="height: 10px" v-if="printModel.form.isShowNotes2"></div>
          <div>
            <span
              ><i
                >* 天然植物产品在长期静置时可能会产生一些产品本身的沉淀物，这不会影响产品的质量；如发现产品有沉淀，请在使用前适当加热并搅拌至充分混合。</i
              ></span
            >
          </div>
          <p v-for="(item, index) in printModel.newNoteArr" :key="index">
            * {{item.content}}
          </p>
          <div style="height: 10px"></div>
          <div>
            <b><u>Disclaimer: The product specifications, limits, test methods, and information contained on this document are accurate to the best of our knowledge based upon general industry information, available scientific data, and customer requests. These values are not intended to be used as specifications for a finished product and should be used only at the sole discretion of the purchaser. According to GMP regulations, the test results of the product must be independently verified by the purchasing party. The appropriate use of this product is the sole responsibility of the user or the purchasing party.</u></b>
          </div>
        </div>
        <FooterSigner></FooterSigner>
      </div>
    </div> 
  </div>
</template>
<script>
import FooterSigner from "./Cn-FooterSigner";
export default {
  name: "CSFMU",
  components: { FooterSigner },
  props: ["printModel"],
  data() {
    return {
      username: "",
    };
  },
  computed: {
    maxArr() {
      let tmp = [
        this.printModel.inclNameArr.length,
        this.printModel.botanicalNameArr.length,
        this.printModel.commonNameArr.length,
        this.printModel.partUsedArr.length,
      ].sort();
      return tmp[tmp.length - 1];
    },
    isShowTable(){
      if(this.printModel.form.tableInput1 || this.printModel.form.tableInput2 || this.printModel.form.tableInput3 || this.printModel.form.tableInput4 || this.printModel.form.tableInput5){
        return true
      }
      return false
    }
  },
  methods: {},
};
</script>

<style scoped>
.tab tr td,
.tab thead th {
  padding: 5px;
}
.watermark {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0;
  text-align: center;
  border: solid 1px;
  color: coral;
  background-color: coral;
}
</style>