<template>
  <div class="app-new-item">
    <center>
      <div class="new-item-content">
        <div id="printDiv" v-show="printShow">
          <COAOilShxto
            :printModel="this.$data"
            style="height-max: 1000px; width: 100vw"
          ></COAOilShxto>
        </div>
        <!-- General Specification -->
        <div class="base-info">
          <div>标准规格</div>
          <div>
            <div>
              <span>编辑打印标题：</span>
              <div class="el-input" style="text-align: left">
                <el-checkbox
                  v-model="form.BaseInfoEditPrintTitleCheckbox"
                  placeholder="请输入"
                ></el-checkbox>
                <el-input
                  v-show="form.BaseInfoEditPrintTitleCheckbox"
                  v-model="form.BaseInfoEditPrintTitle"
                  placeholder="请输入"
                  style="margin-left: 10px; width: 575px"
                ></el-input>
              </div>
            </div>
            <div>
              <span>编辑打印副标题：</span>

              <div class="el-input" style="text-align: left">
                <el-checkbox
                  v-model="form.BaseInfoEditPrintSubtitleCheckbox"
                  placeholder="Please Input"
                ></el-checkbox>
                <el-input
                  v-show="form.BaseInfoEditPrintSubtitleCheckbox"
                  v-model="form.BaseInfoEditPrintSubtitle"
                  placeholder="Please Input"
                  style="margin-left: 10px; width: 575px"
                ></el-input>
              </div>
            </div>
            <div style="height: auto">
            <span>通用名/其他名称：</span>
            <div style="display: inline-block; width: 615px; text-align: left">
              <div
                style="
                  display: flex;
                  justify: flex-start;
                  flex-wrap: wrap;
                  align-items: center;
                "
              >
                <el-tag
                  :key="i"
                  v-for="(tag, i) in commonNameArr"
                  closable
                  :disable-transitions="false"
                  @close="handleClose(tag, 'commonNameArr')"
                  >
                    <span @click="tagHandle(commonNameArr, tag, i)" style="cursor: pointer;">{{ tag }}</span>
                    <el-button type="primary" style="padding: 0 2px;margin-left:5px;" size="small" 
                    @click="showIndexInput('commonNameInputVisible', 'commonNameSaveTagInput',i,'commonNameArrIndex')">+</el-button>
                    <el-button v-show="i>0" type="primary" style="padding: 0;" size="small" icon="el-icon-arrow-left"
                    @click="leftInputValue(commonNameArr, i)"></el-button>
                    <el-button type="primary" style="padding: 0;" size="small" icon="el-icon-arrow-right"
                    @click="rightInputValue(commonNameArr, i)"></el-button>
                  </el-tag
                >
                <el-input
                  class="input-new-tag"
                  v-if="commonNameInputVisible"
                  v-model="commonNameInputValue"
                  ref="commonNameSaveTagInput"
                  size="small"
                  @keyup.enter.native="
                    handleInputConfirm(
                      'commonNameInputValue',
                      'commonNameArr',
                      'commonNameInputVisible'
                    )
                  "
                  @blur="
                    handleInputConfirm(
                      'commonNameInputValue',
                      'commonNameArr',
                      'commonNameInputVisible'
                    )
                  "
                ></el-input>
                <el-button
                  :disabled="commonNameD"
                  v-else
                  class="button-new-tag"
                  size="small"
                  @click="
                    showInput(
                      'commonNameInputVisible',
                      'commonNameSaveTagInput'
                    )
                  "
                  >+ 新增通用名/其他名称</el-button
                >
              </div>
            </div>
            </div>

            <div style="height: auto">
              <span>INCI名称：</span>
              <div style="display: inline-block; width: 615px; text-align: left">
                <div
                  style="
                    display: flex;
                    justify: flex-start;
                    flex-wrap: wrap;
                    align-items: center;
                  "
                >
                  <el-tag
                    :key="i"
                    v-for="(tag, i) in inclNameArr"
                    closable
                    :disable-transitions="false"
                    @close="handleClose(tag, 'inclNameArr')"
                    >
                    <span @click="tagHandle(inclNameArr, tag, i)" style="cursor: pointer;">{{ tag }}</span>
                    <el-button type="primary" style="padding: 0 2px;margin-left:5px;" size="small" 
                    @click="showIndexInput('inclNameInputVisible', 'inclNameSaveTagInput',i,'inclNameArrIndex')">+</el-button>
                    <el-button v-show="i>0" type="primary" style="padding: 0;" size="small" icon="el-icon-arrow-left"
                    @click="leftInputValue(inclNameArr, i)"></el-button>
                    <el-button type="primary" style="padding: 0;" size="small" icon="el-icon-arrow-right"
                    @click="rightInputValue(inclNameArr, i)"></el-button>
                  </el-tag
                  >
                  <el-input
                    class="input-new-tag"
                    v-if="inclNameInputVisible"
                    v-model="inclNameInputValue"
                    ref="inclNameSaveTagInput"
                    size="small"
                    @keyup.enter.native="
                      handleInputConfirm(
                        'inclNameInputValue',
                        'inclNameArr',
                        'inclNameInputVisible'
                      )
                    "
                    @blur="
                      handleInputConfirm(
                        'inclNameInputValue',
                        'inclNameArr',
                        'inclNameInputVisible'
                      )
                    "
                  ></el-input>
                  <el-button
                    :disabled="inciNameD"
                    v-else
                    class="button-new-tag"
                    size="small"
                    @click="
                      showInput('inclNameInputVisible', 'inclNameSaveTagInput')
                    "
                    >+ 新增INCI名称</el-button
                  >
                </div>
              </div>
            </div>

            <div style="height: auto">
              <span>植物学名：</span>
              <div style="display: inline-block; width: 615px; text-align: left">
                <div
                  style="
                    display: flex;
                    justify: flex-start;
                    flex-wrap: wrap;
                    align-items: center;
                  "
                >
                  <el-tag
                    :key="i"
                    v-for="(tag, i) in botanicalNameArr"
                    closable
                    :disable-transitions="false"
                    @close="handleClose(tag, 'botanicalNameArr')"
                    >
                    <span @click="tagHandle(botanicalNameArr, tag, i)" style="cursor: pointer;">{{ tag }}</span>
                    <el-button type="primary" style="padding: 0 2px;margin-left:5px;" size="small" 
                    @click="showIndexInput('botanicalNameInputVisible', 'botanicalNameSaveTagInput',i,'botanicalNameArrIndex')">+</el-button>
                    <el-button v-show="i>0" type="primary" style="padding: 0;" size="small" icon="el-icon-arrow-left"
                    @click="leftInputValue(botanicalNameArr, i)"></el-button>
                    <el-button type="primary" style="padding: 0;" size="small" icon="el-icon-arrow-right"
                    @click="rightInputValue(botanicalNameArr, i)"></el-button>
                  </el-tag
                  >
                  <el-input
                    class="input-new-tag"
                    v-if="botanicalNameInputVisible"
                    v-model="botanicalNameInputValue"
                    ref="botanicalNameSaveTagInput"
                    size="small"
                    @keyup.enter.native="
                      handleInputConfirm(
                        'botanicalNameInputValue',
                        'botanicalNameArr',
                        'botanicalNameInputVisible'
                      )
                    "
                    @blur="
                      handleInputConfirm(
                        'botanicalNameInputValue',
                        'botanicalNameArr',
                        'botanicalNameInputVisible'
                      )
                    "
                  ></el-input>
                  <el-button
                    :disabled="botanicalNameD"
                    v-else
                    class="button-new-tag"
                    size="small"
                    @click="
                      showInput(
                        'botanicalNameInputVisible',
                        'botanicalNameSaveTagInput'
                      )
                    "
                    >+ 新增植物学名</el-button
                  >
                </div>
              </div>
            </div>
            
            <div style="height: auto">
              <span>使用部位：</span>
              <div style="display: inline-block; width: 615px; text-align: left">
                <div
                  style="
                    display: flex;
                    justify: flex-start;
                    flex-wrap: wrap;
                    align-items: center;
                  "
                >
                  <el-tag
                    :key="i"
                    v-for="(tag, i) in partUsedArr"
                    closable
                    :disable-transitions="false"
                    @close="handleClose(tag, 'partUsedArr')"
                    >
                    <span @click="tagHandle(partUsedArr, tag, i)" style="cursor: pointer;">{{ tag }}</span>
                    <el-button type="primary" style="padding: 0 2px;margin-left:5px;" size="small" 
                    @click="showIndexInput('partUsedInputVisible', 'partUsedSaveTagInput',i,'partUsedArrIndex')">+</el-button>
                    <el-button v-show="i>0" type="primary" style="padding: 0;" size="small" icon="el-icon-arrow-left"
                    @click="leftInputValue(partUsedArr, i)"></el-button>
                    <el-button type="primary" style="padding: 0;" size="small" icon="el-icon-arrow-right"
                    @click="rightInputValue(partUsedArr, i)"></el-button>
                  </el-tag
                  >
                  <el-input
                    class="input-new-tag"
                    v-if="partUsedInputVisible"
                    v-model="partUsedInputValue"
                    ref="partUsedSaveTagInput"
                    size="small"
                    @keyup.enter.native="
                      handleInputConfirm(
                        'partUsedInputValue',
                        'partUsedArr',
                        'partUsedInputVisible'
                      )
                    "
                    @blur="
                      handleInputConfirm(
                        'partUsedInputValue',
                        'partUsedArr',
                        'partUsedInputVisible'
                      )
                    "
                  ></el-input>
                  <el-button
                    :disabled="partUsedD"
                    v-else
                    class="button-new-tag"
                    size="small"
                    @click="
                      showInput('partUsedInputVisible', 'partUsedSaveTagInput')
                    "
                    >+ 新增使用部位</el-button
                  >
                </div>
              </div>
            </div>
            <div style="height: auto">
              <span>原产地：</span>
              
              <el-input
                v-model="form.countryOfOrigin"
                placeholder="请输入"
              ></el-input>
            </div>
            
            <div style="height: auto">
              <div style="display: inline-block; text-align: left">
              <table style="border: 1px solid #eee;">
                <tr>
                  <td style="border: 1px solid #eee;">
                    <el-input placeholder="请输入内容" style="width: 100%" v-model="form.tableInput1"></el-input>
                  </td>
                  <td style="border: 1px solid #eee;">
                    <el-input placeholder="请输入内容" style="width: 100%" v-model="form.tableInput2"></el-input>
                  </td>
                  <td style="border: 1px solid #eee;">
                    <el-input placeholder="请输入内容" style="width: 100%" v-model="form.tableInput3"></el-input>
                  </td>
                  <td style="border: 1px solid #eee;">
                    <el-input placeholder="请输入内容" style="width: 100%" v-model="form.tableInput4"></el-input>
                  </td>
                  <td style="border: 1px solid #eee;">
                    <el-input placeholder="请输入内容" style="width: 100%" v-model="form.tableInput5"></el-input>
                  </td>
                  <td>
                    <el-button type="primary" class="button-new-tag" size="small" @click="addTableInput(1, 0)">+</el-button>
                  </td>
                </tr>
                <tr v-for="(item, tableInputIndex) in tableInputCustomFieldAttr" :key="tableInputIndex + 1">
                  <td style="border: 1px solid #eee;">
                    <el-input placeholder="请输入内容" style="width: 100%" v-model="item.tableInput1"></el-input>
                  </td>
                  <td style="border: 1px solid #eee;">
                    <el-input placeholder="请输入内容" style="width: 100%" v-model="item.tableInput2"></el-input>
                  </td>
                  <td style="border: 1px solid #eee;">
                    <el-input placeholder="请输入内容" style="width: 100%" v-model="item.tableInput3"></el-input>
                  </td>
                  <td style="border: 1px solid #eee;">
                    <el-input placeholder="请输入内容" style="width: 100%" v-model="item.tableInput4"></el-input>
                  </td>
                  <td style="border: 1px solid #eee;">
                    <el-input placeholder="请输入内容" style="width: 100%" v-model="item.tableInput5"></el-input>
                  </td>
                  <td>
                    <el-button type="primary" class="button-new-tag" size="small" @click="addTableInput(1, 0)">+</el-button>
                    <el-button type="danger" class="button-new-tag" size="small" @click="addTableInput(0, tableInputIndex)">-</el-button>
                  </td>
                </tr>
              </table>
              </div>
            </div>
            
            <div>
              <span>外观：</span>
              <el-input
                v-model="form.appearance"
                placeholder="请输入"
              ></el-input>
            </div>
            
            <div>
              <span>颜色：</span>
              <el-select
                v-model="form.color"
                placeholder="select"
                clearable
                filterable
                allow-create
              >
                <el-option
                  v-for="(item, i) in colorList"
                  :key="i"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>

            <div>
              <span>气味：</span>
              <el-input
                v-model="form.odor"
                placeholder="请输入"
              ></el-input>
            </div>

            <div>
              <span>味道：</span>
              <el-input
                v-model="form.taste"
                placeholder="请输入"
              ></el-input>
            </div>
            <div>
              <span>其他成分：</span>
              <el-input
                v-model="form.otherIngredients"
                class="custom-row"
                placeholder="请输入其他成分"
              ></el-input>
            </div>
            
            <div>
              <span>
            <el-input
              v-model="form.customKey1"
              placeholder="请输入"
              style="width: 160px"
            ></el-input>：</span>
            <el-input
              v-model="form.customValue1"
              placeholder="请输入"
            ></el-input>
          </div>
          <div>
              <span>
            <el-input
              v-model="form.customKey2"
              placeholder="请输入"
              style="width: 160px"
            ></el-input>：</span>
            <el-input
              v-model="form.customValue2"
              placeholder="请输入"
            ></el-input>
          </div>
          <div>
              <span style="margin-left: 48px">
            <el-input
              v-model="form.customKey3"
              placeholder="请输入"
              style="width: 160px"
            ></el-input>：</span>
            <el-input
              v-model="form.customValue3"
              placeholder="请输入"
            ></el-input>
              <el-button
                type="primary"
                class="button-new-tag"
                size="small"
                @click="addGenSpecCustomRow(1, 0)"
                >+</el-button>
          </div>
            <div
              v-for="(item, genSpecIndex) in genSpecCustomFieldAttr"
              :key="genSpecIndex + 1"
            >
              <span style="margin-left: 96px">
                <el-input
                  v-model="item.customKey"
                  placeholder="请输入"
              style="width: 160px"
                ></el-input
                >：</span
              >
              <div class="custom-row el-input">
                <el-input
                  v-model="item.customValue"
                  class="custom-row"
                  placeholder="请输入"
                ></el-input>
              </div>
              <el-button
                type="primary"
                class="button-new-tag"
                size="small"
                @click="addGenSpecCustomRow(1, genSpecIndex)"
                >+</el-button
              >
              <el-button
                type="danger"
                class="button-new-tag"
                size="small"
                @click="addGenSpecCustomRow(0, genSpecIndex)"
                >-</el-button
              >
            </div>
          </div>
        </div>
        <!-- Analysis -->
        <div class="analysis">
          <div></div>
          <div style="display: flex; margin-top: 20px">
            <div
              class="specification"
              style="width: 50%; margin-right: 120px; border: 1px solid #e9e9e9"
            >
              <div>范围</div>
              <div style="display: flex; align-items: center">
                <span>酸价：</span>
                <el-input
                  v-model="form.testLimitAcidValue"
                  placeholder="请输入"
                >
                  <template slot="prepend">
                    <div style="width: 100%">NMT</div>
                  </template>
                  <template slot="append">
                    <div style="width: 100%">mg KOH/G</div>
                  </template>
                </el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span>过氧化物值：</span>
                <el-input
                  v-model="form.testLimitPeroxideValue"
                  placeholder="请输入"
                >
                  <template slot="prepend">
                    <div style="width: 100%">NMT</div>
                  </template>
                  <template slot="append">
                    <div style="width: 100%">meq/kg</div>
                  </template>
                </el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span>皂化值：</span>
                <el-input
                  v-model="form.testLimitSaponificationValue"
                  placeholder="请输入"
                >
                  <template slot="append">
                    <div style="width: 100%">mg KOH/g</div>
                  </template>
                </el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span>不皂化物含量：</span>
                <el-input
                  v-model="form.testLimitUnsaponifiableMaster"
                  placeholder="请输入"
                >
                  <template slot="append">
                    <div style="width: 100%">%</div>
                  </template>
                </el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span>水分和挥发物：</span>
                <el-input
                  v-model="form.testLimitMoistureVolatiles"
                  placeholder="请输入"
                >
                  <template slot="prepend">
                    <div style="width: 100%">NMT</div>
                  </template>
                  <template slot="append">
                    <div style="width: 100%">%</div>
                  </template>
                </el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span>总重金属：</span>
                <el-input
                  v-model="form.testLimitHeavyMetals"
                  placeholder="请输入"
                >
                  <template slot="prepend">
                    <div style="width: 100%">&lt;</div>
                  </template>
                  <template slot="append">
                    <div style="width: 100%">ppm</div>
                  </template>
                </el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span>铅：</span>
                <el-input
                  v-model="form.testLimitLead"
                  placeholder="请输入"
                >
                  <!-- <template slot="prepend">
                    <div style="width:100%;">NMT</div>
                  </template> -->
                  <template slot="append">
                    <div style="width: 100%">ppm</div>
                  </template>
                </el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span>砷：</span>
                <el-input
                  v-model="form.testLimitArsenic"
                  placeholder="请输入"
                >
                  <!-- <template slot="prepend">
                    <div style="width: 100%">NMT</div>
                  </template> -->
                  <template slot="append">
                    <div style="width: 100%">ppm</div>
                  </template>
                </el-input>
              </div>

                <div style="display: flex; align-items: center">
                <span>镉：</span>
                <el-input v-model="form.testLimitCadmium" placeholder="请输入">
                  <!-- <template slot="prepend">
                    <div style="width: 100%">NMT</div>
                  </template> -->
                  <template slot="append">
                    <div style="width: 100%">ppm</div>
                  </template>
                </el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span>汞：</span>
                <el-input
                  v-model="form.testLimitMercury"
                  placeholder="请输入"
                >
                  <!-- <template slot="prepend">
                    <div style="width: 100%">NMT</div>
                  </template> -->
                  <template slot="append">
                    <div style="width: 100%">ppm</div>
                  </template>
                </el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span>农药残留：</span>
                <el-input 
                  v-model="form.testLimitPesticides"
                  placeholder="请输入"
                ></el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span>
                <el-input
                  v-model="form.testLimitCustomKey1"
                  placeholder="请输入"
                  style="width: 160px"
                ></el-input>：</span>
                <el-input
                  v-model="form.testLimitCustomKey1Value"
                  placeholder="请输入"
                ></el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span>
                <el-input
                  v-model="form.testLimitCustomKey2"
                  placeholder="请输入"
                  style="width: 160px"
                ></el-input>：</span>
                <el-input
                  v-model="form.testLimitCustomKey2Value"
                  placeholder="请输入"
                ></el-input>
              </div>
              
              <div
                v-for="(item, analysisIndexEhxto) in analysisCustomFieldAttr"
                :key="analysisIndexEhxto"
              >
                <span>
                  <el-input
                    v-model="item.customKey"
                    placeholder="请输入"
                    style="width: 160px"
                  ></el-input>：
                </span>
                  <el-input
                    v-model="item.customValue"
                    placeholder="请输入"
                  ></el-input>
              </div>
            </div>

            <div
              class="test-method"
              style="width: 50%; border: 1px solid #e9e9e9"
            >
              <div>测试方法</div>
              <div style="display: flex; align-items: center">
                <span>酸价：</span>
                <el-input 
                  v-model="form.testMethodAcidValue"
                  placeholder="请输入"
                ></el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span>过氧化物值：</span>
                <el-input 
                  v-model="form.testMethodPeroxideValue"
                  placeholder="请输入"
                ></el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span>皂化值：</span>
                <el-input 
                  v-model="form.testMethodSaponificationValue"
                  placeholder="请输入"
                ></el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span>不皂化物含量：</span>
                <el-input 
                  v-model="form.testMethodUnsaponifiableMaster"
                  placeholder="请输入"
                ></el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span>水分和挥发物：</span>
                <el-input 
                  v-model="form.testMethodMoistureVolatiles"
                  placeholder="请输入"
                ></el-input>
              </div>

              <div>
                <span>总重金属：</span>
                <el-input 
                  v-model="form.testMethodHeavyMetals"
                  placeholder="请输入"
                ></el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span>铅：</span>
                <el-input 
                  v-model="form.testMethodLead"
                  placeholder="请输入"
                ></el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span>砷：</span>
                <el-input 
                  v-model="form.testMethodArsenic"
                  placeholder="请输入"
                ></el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span>镉：</span>
                <el-input 
                  v-model="form.testMethodCadmium"
                  placeholder="请输入"
                ></el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span>汞：</span>
                <el-input 
                  placeholder="请输入"
                  v-model="form.testMethodMercury" 
                ></el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span>农药残留：</span>
                <el-input 
                  v-model="form.testMethodPesticides"
                  placeholder="请输入"
                ></el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span>{{form.testLimitCustomKey1}}：</span>
                <el-input
                  v-model="form.testMethodCustomKey1Value"
                  placeholder="请输入"
                ></el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span>{{form.testLimitCustomKey2}}：</span>
                <el-input
                  v-model="form.testMethodCustomKey2Value"
                  placeholder="请输入"
                ></el-input>
                <el-button
                  style="margin-top: 14px"
                  type="primary"
                  class="button-new-tag"
                  size="small"
                  @click="addAnalysisCustomRow(1, 0)"
                  >+</el-button>
              </div>
              <div
                v-for="(item, analysisIndexEhxto) in analysisCustomFieldAttr"
                :key="analysisIndexEhxto"
              >
                <span>{{item.customKey}}：
                </span>
                  <el-input
                    v-model="item.customValue1"
                    placeholder="请输入"
                  ></el-input>
                <el-button
                  type="primary"
                  class="button-new-tag"
                  size="small"
                  style="margin-top: 14px"
                  @click="addAnalysisCustomRow(1, analysisIndexEhxto)"
                  >+</el-button
                >
                <el-button
                  type="danger"
                  class="button-new-tag"
                  size="small"
                  style="margin-top: 14px"
                  @click="addAnalysisCustomRow(0, analysisIndexEhxto)"
                  >-</el-button
                >
              </div>
            </div>
          </div>
        </div>
        <!-- Fatty Analysis -->
        <div class="analysis">
                  <div>
        </div>
          <div style="display: flex; margin-top: 20px">
            <div
              class="specification"
              style="width: 50%; margin-right: 120px; border: 1px solid #e9e9e9"
            >
              <div>范围</div>
              <!-- <div style="display: flex; align-items: center">
                <el-input
                  v-model="form.fattryLimitKey1"
                  placeholder="请输入"
                  style="width: 160px"
                ></el-input>
                <el-input
                  v-model="form.fattryLimitKey1Value"
                  placeholder="请输入"
                  style="width: 160px"
                ></el-input>
              </div>
              <div style="display: flex; align-items: center">
                <el-input
                  v-model="form.fattryLimitKey2"
                  placeholder="请输入"
                  style="width: 160px"
                ></el-input>
                <el-input
                  v-model="form.fattryLimitKey2Value"
                  placeholder="请输入"
                  style="width: 160px"
                ></el-input>
              </div> -->

              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">C8:0辛酸：</span>
                <el-input
                  v-model="form.fattryLimitCaprylic"
                  placeholder="请输入"
                >
                  <template slot="append">
                    <div style="width: 100%">%</div>
                  </template>
                </el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">C10:0癸酸：</span>
                <el-input
                  v-model="form.fattryLimitCapric"
                  placeholder="请输入"
                >
                  <template slot="append">
                    <div style="width: 100%">%</div>
                  </template>
                </el-input>
              </div>
              
              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">C12:0月桂酸：</span>
                <el-input
                  v-model="form.fattryLimitLauric"
                  placeholder="请输入"
                >
                  <template slot="append">
                    <div style="width: 100%">%</div>
                  </template>
                </el-input>
              </div>
              
              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">C14:0豆蔻酸：</span>
                <el-input
                  v-model="form.fattryLimitMyristic"
                  placeholder="请输入"
                >
                  <template slot="append">
                    <div style="width: 100%">%</div>
                  </template>
                </el-input>
              </div>
              
              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">C14:1豆蔻油酸：</span>
                <el-input
                  v-model="form.fattryLimitMyristoleic"
                  placeholder="请输入"
                >
                  <template slot="append">
                    <div style="width: 100%">%</div>
                  </template>
                </el-input>
              </div>


              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">C16：0棕榈酸：</span>
                <el-input
                  v-model="form.fattryLimitPalmitica"
                  placeholder="请输入"
                >
                  <template slot="append">
                    <div style="width: 100%">%</div>
                  </template>
                </el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">C16：1棕榈油酸：</span>
                <el-input
                  v-model="form.fattryLimitPalmitoleic"
                  placeholder="请输入"
                >
                  <template slot="append">
                    <div style="width: 100%">%</div>
                  </template>
                </el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">C18：0硬脂酸：</span>
                <el-input
                  v-model="form.fattryLimitStearic"
                  placeholder="请输入"
                >
                  <template slot="append">
                    <div style="width: 100%">%</div>
                  </template>
                </el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">C18：1油酸：</span>
                <el-input
                  v-model="form.fattryLimitOleicVaccenic"
                  placeholder="请输入"
                >
                  <template slot="append">
                    <div style="width: 100%">%</div>
                  </template>
                </el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">C18：2亚油酸：</span>
                <el-input
                  v-model="form.fattryLimitLinoleic"
                  placeholder="请输入"
                >
                  <template slot="append">
                    <div style="width: 100%">%</div>
                  </template>
                </el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">C18：3 α-亚麻酸：</span>
                <el-input
                  v-model="form.fattryLimitALinolenic"
                  placeholder="请输入"
                >
                  <template slot="append">
                    <div style="width: 100%">%</div>
                  </template>
                </el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">C18:3 γ-亚麻酸：</span>
                <el-input
                  v-model="form.fattryLimitLinoleicacid"
                  placeholder="请输入"
                >
                  <template slot="append">
                    <div style="width: 100%">%</div>
                  </template>
                </el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">C18:3 石榴酸/Punicic acid：</span>
                <el-input
                  v-model="form.fattryLimitPunicicacid"
                  placeholder="请输入"
                >
                  <template slot="append">
                    <div style="width: 100%">%</div>
                  </template>
                </el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">C 20:0 花生酸/AA：</span>
                <el-input
                  v-model="form.fattryLimitArachidic"
                  placeholder="请输入"
                >
                  <template slot="append">
                    <div style="width: 100%">%</div>
                  </template>
                </el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">C20:4 花生四烯酸：</span>
                <el-input
                  v-model="form.fattryLimitArachidonic"
                  placeholder="请输入"
                >
                  <template slot="append">
                    <div style="width: 100%">%</div>
                  </template>
                </el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">C20:5 二十碳五烯酸/EPA：</span>
                <el-input
                  v-model="form.fattryLimitEicosapentaenoic"
                  placeholder="请输入"
                >
                  <template slot="append">
                    <div style="width: 100%">%</div>
                  </template>
                </el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">C 22:6 二十二碳六烯酸/DHA：</span>
                <el-input
                  v-model="form.fattryLimitDocosahexaenoic"
                  placeholder="请输入"
                >
                  <template slot="append">
                    <div style="width: 100%">%</div>
                  </template>
                </el-input>
              </div>
              
              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">
                <el-input
                  v-model="form.fattryLimitKey1"
                  placeholder="请输入"
                  style="width: 160px"
                ></el-input>：</span>
                <el-input
                  v-model="form.fattryLimitKey1Value"
                  placeholder="请输入"
                ></el-input>
              </div>
              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">
                <el-input
                  v-model="form.fattryLimitKey2"
                  placeholder="请输入"
                  style="width: 160px"
                ></el-input>：</span>
                <el-input
                  v-model="form.fattryLimitKey2Value"
                  placeholder="请输入"
                ></el-input>
              </div>
              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">
                <el-input
                  v-model="form.fattryLimitKey3"
                  placeholder="请输入"
                  style="width: 160px"
                ></el-input>：</span>
                <el-input
                  v-model="form.fattryLimitKey3Value"
                  placeholder="请输入"
                ></el-input>
              </div>
              
              <div
                v-for="(item, fattyIndex) in fattyCustomFieldAttr"
                :key="fattyIndex"
              >
                <span class="acidSpanClass">
                  <el-input
                    v-model="item.customKey"
                    placeholder="请输入"
                  style="width: 160px"
                  ></el-input>：
                </span>
                  <el-input
                    v-model="item.customValue"
                    placeholder="请输入"
                  ></el-input>
              </div>
              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">其他：</span>
                <el-input
                  v-model="form.fatterLimitOthers"
                  placeholder="请输入"
                >
                  <template slot="append">
                    <div style="width: 100%">%</div>
                  </template>
                </el-input>
              </div>
            </div>

            <div
              class="test-method"
              style="width: 50%; border: 1px solid #e9e9e9"
            >
              <div>测试方法</div>
              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">C8:0辛酸：</span>
                <el-input
                  v-model="form.fattryMethodCaprylic"
                  placeholder="请输入"
                >
                </el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">C10:0癸酸：</span>
                <el-input
                  v-model="form.fattryMethodCapric"
                  placeholder="请输入"
                >
                </el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">C12:0月桂酸：</span>
                <el-input
                  v-model="form.fattryMethodLauric"
                  placeholder="请输入"
                >
                </el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">C12:0月桂酸：</span>
                <el-input
                  v-model="form.fattryMethodMyristic"
                  placeholder="请输入"
                >
                </el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">C14:1豆蔻油酸：</span>
                <el-input
                  v-model="form.fattryMethodMyristoleic"
                  placeholder="请输入"
                >
                </el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">C16：0棕榈酸：</span>
                <el-input 
                  v-model="form.fattryMethodPalmitica"
                  placeholder="请输入"
                ></el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">C16：1棕榈油酸：</span>
                <el-input 
                  v-model="form.fattryMethodPalmitoleic"
                  placeholder="请输入"
                ></el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">C18：0硬脂酸：</span>
                <el-input 
                  v-model="form.fattryMethodStearic"
                  placeholder="请输入"
                ></el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">C18：1油酸：</span>
                <el-input 
                  v-model="form.fattryMethodOleicVaccenic"
                  placeholder="请输入"
                ></el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">C18：2亚油酸：</span>
                <el-input 
                  v-model="form.fattryMethodLinoleic"
                  placeholder="请输入"
                ></el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">C18：3 α-亚麻酸：</span>
                <el-input 
                  v-model="form.fattryMethodALinolenic"
                  placeholder="请输入"
                ></el-input>
              </div>

            <div style="display: flex; align-items: center">
              <span class="acidSpanClass">C18:3 γ-亚麻酸：</span>
              <el-input 
                v-model="form.fattryMethodLinoleicacid"
                placeholder="请输入"
              ></el-input>
            </div>

            <div style="display: flex; align-items: center">
              <span class="acidSpanClass">C18:3 石榴酸/Punicic acid：</span>
              <el-input 
                v-model="form.fattryMethodPunicicacid"
                placeholder="请输入"
              ></el-input>
            </div>

            <div style="display: flex; align-items: center">
              <span class="acidSpanClass">C 20:0 花生酸/AA：</span>
              <el-input 
                v-model="form.fattryMethodArachidic"
                placeholder="请输入"
              ></el-input>
            </div>

            <div style="display: flex; align-items: center">
              <span class="acidSpanClass">C20:4 花生四烯酸：</span>
              <el-input 
                v-model="form.fattryMethodArachidonic"
                placeholder="请输入"
              ></el-input>
            </div>

            <div style="display: flex; align-items: center">
              <span class="acidSpanClass">C20:5 二十碳五烯酸/EPA：</span>
              <el-input 
                v-model="form.fattryMethodEicosapentaenoic"
                placeholder="请输入"
              ></el-input>
            </div>

            <div style="display: flex; align-items: center">
              <span class="acidSpanClass">C 22:6 二十二碳六烯酸/DHA：</span>
              <el-input 
                v-model="form.fattryMethodDocosahexaenoic"
                placeholder="请输入"
              ></el-input>
            </div>


              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">{{form.fattryLimitKey1}}：</span>
                <el-input
                  v-model="form.fattryMethodKey1Value"
                  placeholder="请输入"
                ></el-input>
              </div>
              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">{{form.fattryLimitKey2}}：</span>
                <el-input
                  v-model="form.fattryMethodKey2Value"
                  placeholder="请输入"
                ></el-input>
              </div>
              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">{{form.fattryLimitKey3}}：</span>
                <el-input
                  v-model="form.fattryMethodKey3Value"
                  placeholder="请输入"
                ></el-input>
                <el-button
                  style="margin-top: 14px"
                  type="primary"
                  class="button-new-tag"
                  size="small"
                  @click="addShxtoFattyCustomRow(1, 0)"
                  >+</el-button>
              </div>
              <div
                v-for="(item, fattyIndex) in fattyCustomFieldAttr"
                :key="fattyIndex"
              >
                <span class="acidSpanClass">{{item.customKey}}：
                </span>
                  <el-input
                    v-model="item.customValue1"
                    placeholder="请输入"
                  ></el-input>
                <el-button
                  style="margin-top: 14px"
                  type="primary"
                  class="button-new-tag"
                  size="small"
                  @click="addShxtoFattyCustomRow(1, fattyIndex)"
                  >+</el-button
                >
                <el-button
                  style="margin-top: 14px"
                  type="danger"
                  class="button-new-tag"
                  size="small"
                  @click="addShxtoFattyCustomRow(0, fattyIndex)"
                  >-</el-button
                >
              </div>
            <div style="display: flex; align-items: center">
              <span class="acidSpanClass">其他：</span>
              <el-input 
                v-model="form.fatterMethodOthers"
                placeholder="请输入"
              ></el-input>
            </div>
            </div>
          </div>
        </div>
        <!-- Microbiological Tests -->
        <div class="microbiological-tests">
          <div>微生物检验</div>
          <div>
            <div class="specification">
              <div>规格</div>
              <div style="display: flex; align-items: center">
                <span>细菌总数：</span>
                <el-input
                  onkeyup="value=value.replace(/[^\d.]|/g,'')"
                  v-model="form.specificationTotalPlateCount"
                  placeholder="请输入"
                >
                  <template slot="prepend">
                    <div style="width: 100%">&lt;</div>
                  </template>
                  <template slot="append">
                    <div style="width: 100%">cfu / g</div>
                  </template>
                </el-input>
              </div>
              <div style="display: flex; align-items: center">
                <span>酵母和霉菌：</span>
                <!-- <div style="width: 40%;display:inline-block;text-align:left">&lt;100/g</div>
                -->
                <el-input
                  onkeyup="value=value.replace(/[^\d.]|/g,'')"
                  :readonly="specificationYeastAndMoldD"
                  v-model="form.specificationYeastAndMold"
                  placeholder="请输入"
                >
                  <template slot="prepend">
                    <div style="width: 100%">&lt;</div>
                  </template>
                  <template slot="append">
                    <div style="width: 100%">cfu / g</div>
                  </template>
                </el-input>
              </div>
              <div>
                <span>大肠杆菌：</span>
                <el-input
                  v-model="form.specificationEcoli"
                  placeholder="请输入"
                ></el-input>
              </div>
              <div>
                <span>沙门氏菌：</span>
                <el-input
                  v-model="form.specificationSalmonella"
                  placeholder="请输入"
                ></el-input>
              </div>
              <div>
                <span>金色葡萄球菌：</span>
                <el-input
                  v-model="form.specificationStaphAureus"
                  placeholder="请输入"
                ></el-input>
              </div>
              <div style="display: flex; align-items: center">
                <span>大肠菌群：</span>
                <!-- <div style="width: 40%;display:inline-block;text-align:left">&lt;100/g</div>
                -->
                <el-input
                  @blur="checkSpecificationTotalColiformscn()"
                  v-model="form.specificationTotalColiforms"
                  placeholder="请输入"
                >
                  <template slot="prepend">
                    <div style="width: 100%">&lt;</div>
                  </template>
                  <template slot="append">
                    <div style="width: 100%">MPN/g</div>
                  </template>
                </el-input>
              </div>
              <div>
                <span>
                <el-input
                  v-model="form.microbiologicalCustomerKey1"
                  placeholder="请输入"
                  style="width: 160px"
                ></el-input>：</span>
                <el-input
                  v-model="form.microbiologicalCustomerValue1"
                  placeholder="请输入"
                ></el-input>
              </div>
              <div
                v-for="(item, micTestsIndex) in micTestsCustomFieldAttr"
                :key="micTestsIndex"
              >
                <span>
                  <el-input
                    v-model="item.customKey"
                    placeholder="请输入"
                    style="width: 160px"
                  ></el-input>：
                </span>
                  <el-input
                    v-model="item.customValue"
                    placeholder="请输入"
                  ></el-input>
              </div>
            </div>
            <div class="test-method">
              <div>测试方法</div>
              <div>
                <span>细菌总数：</span>
                <!-- <div style="width: 40%; display: inline-block; text-align: left">
                  FDA-BAM
                </div> -->
                
                <el-input
                  v-model="form.testMethodTotalPlateCount"
                  placeholder="请输入"
                ></el-input>
              </div>
  
              <div>
                <span>酵母和霉菌：</span>
                <!-- <div style="width: 40%; display: inline-block; text-align: left">
                  FDA-BAM
                </div> -->
                <el-input
                  v-model="form.testMethodYeastAndMold"
                  placeholder="请输入"
                ></el-input>
              </div>
              <div>
                <span>大肠杆菌：</span>
                <!-- <div style="width: 40%; display: inline-block; text-align: left">
                  FDA-BAM
                </div> -->
                <el-input
                  v-model="form.testMethodEcoli"
                  placeholder="请输入"
                ></el-input>
              </div>
              <div>
                <span>沙门氏菌：</span>
                <!-- <div style="width: 40%; display: inline-block; text-align: left">
                  FDA-BAM
                </div> -->
                <el-input
                  v-model="form.testMethodSalmonella"
                  placeholder="请输入"
                ></el-input>
              </div>
              <div>
                <span>金色葡萄球菌：</span>
                <!-- <div style="width: 40%; display: inline-block; text-align: left">
                  FDA-BAM
                </div> -->
                <el-input
                  v-model="form.testMethodTaphAureus"
                  placeholder="请输入"
                ></el-input>
              </div>
              <div>
                <span>大肠菌群：</span>
                <!-- <div style="width: 40%; display: inline-block; text-align: left">
                  FDA-BAM
                </div> -->
                <el-input
                  v-model="form.testMethodTotalColiforms"
                  placeholder="请输入"
                ></el-input>
              </div>
              <div>
                <span>{{form.microbiologicalCustomerKey1}}：</span>
                <el-input
                  v-model="form.microbiologicalTestMethodCustomerValue1"
                  placeholder="请输入"
                ></el-input>
                <el-button
                  style="margin-top: 14px"
                  type="primary"
                  class="button-new-tag"
                  size="small"
                  @click="addMicTestsCustomRow(1, 0)"
                  >+</el-button>
              </div>
              <div
                v-for="(item, micTestsIndex) in micTestsCustomFieldAttr"
                :key="micTestsIndex"
              >
                <span>{{item.customKey}}：</span>
                  <el-input
                    v-model="item.customValue1"
                    placeholder="请输入"
                  ></el-input>
                <el-button
                  style="margin-top: 14px"
                  type="primary"
                  class="button-new-tag"
                  size="small"
                  @click="addMicTestsCustomRow(1, micTestsIndex)"
                  >+</el-button
                >
                <el-button
                  style="margin-top: 14px"
                  type="danger"
                  class="button-new-tag"
                  size="small"
                  @click="addMicTestsCustomRow(0, micTestsIndex)"
                  >-</el-button
                >
              </div>
            </div>
          </div>
        </div>
        <!-- OTHER ONTES -->
        <div class="other-notes">
          <div>其他</div>
          <div>
            <span>保质期：</span>
            <el-select
              v-model="form.shelflifeNumber"
              placeholder="保质期"
              clearable
              filterable
              allow-create
            >
              <el-option
                v-for="(item, i) in shelflifeList"
                :key="i"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <div>
            <span>保质期备注：</span>
            <!-- <span> 18 months unopened in the original package. Once opened the oil should be blanketed under nitrogen and used within six months.</span> -->
            <el-input
              type="textarea"
              :rows="5"
              resize="none"
              style="width: 600px"
              placeholder="请输入"
              v-model="form.shelflife"
            ></el-input>
          </div>
          <div style="margin-bottom:10px;">
            <span>储存条件：</span>
            <el-input
              v-model="form.storage"
              placeholder="请输入"
            ></el-input>
          </div>
          <div style="margin-left:160px;">
            <span>包装：</span>
            <el-input
              type="textarea"
              :rows="5"
              resize="none"
              style="width: 600px"
              placeholder="请输入"
              v-model="form.packageInfo"
            ></el-input>
          </div>
        </div>
      
        <div>
        <el-form :inline="true" class="demo-form-inline">
          <div v-for="(item, index) in noteTableData" :key="index">
            <el-form-item label="修订日期">
              <el-input
                v-model="item.revisionDate"
                placeholder="修订日期"
                style="width: 140px"
                :disabled="item.isComplete"
              ></el-input>
            </el-form-item>
            <el-form-item label="提出修订人">
              <el-input
                v-model="item.requestedBy"
                placeholder="提出修订人"
                style="width: 140px"
                :disabled="item.isComplete"
              ></el-input>
            </el-form-item>
            <el-form-item label="修订项目">
              <el-input
                v-model="item.itemsRevised"
                placeholder="修订项目"
                style="width: 140px"
                :disabled="item.isComplete"
              ></el-input>
            </el-form-item>
            <el-form-item label="修订人">
              <el-input
                v-model="item.editedBy"
                placeholder="修订人"
                style="width: 140px"
                :disabled="item.isComplete"
              ></el-input>
            </el-form-item>
            <el-form-item label="修订原因">
              <el-input
                v-model="item.reason"
                placeholder="修订原因"
                style="width: 140px"
                :disabled="item.isComplete"
              ></el-input>
            </el-form-item>
            <el-form-item label="批准人">
              <el-input
                v-model="item.approvedBy"
                placeholder="批准人"
                style="width: 140px"
                disabled="disabled"
              ></el-input>
            </el-form-item>
            <!-- <el-form-item style="width: 120px">
              <el-button type="text" @click="addNoteTableClick(index,'cn')" 
                v-if="item.revisionDate && item.requestedBy && item.itemsRevised && item.editedBy && item.reason && item.approvedBy"
              >Complete</el-button>
              &nbsp;&nbsp;
              <el-button type="text"
                v-if="!item.isComplete && noteTableData.length>1" @click="removeNoteTableClick(index)">
                <i style="color:Red;font-size:18px;" class="el-icon-delete"></i>
              </el-button>
            </el-form-item> -->
          </div>
        </el-form>
        </div>
        <div class="other-notes">
          <div>Notes</div>
          <div style="margin-top:0px;">
            <el-input v-model="newNote" placeholder="Please Input" style="width:600px;margin-bottom:10px;margin-right:10px;"></el-input>
            <el-button type="text" @click="addNewNoteTableClick">
              Complete
            </el-button>
          </div>
        </div>
        <div class="base-info">
          <div>注释:</div>
          <div style="width: 80%; text-align: left; font-weight: bold">
            <div>
              * 不同批次产品的颜色可能会因天然原材料的自然变化而产生差异。
            </div>
            <div style="color:Red;">
              <el-checkbox v-model="form.isShowNotes2" label="打印是否显示"></el-checkbox>* 产品含抗氧化化合物，可能会随着时间推移颜色变深但不影响使用效果。
            </div>
            <div>
              * 天然植物产品在长期静置时可能会产生一些产品本身的沉淀物，这不会影响产品的质量；如发现产品有沉淀，请在使用前适当加热并搅拌至充分混合。
            </div>
            <div v-for="(item, index) in newNoteArr" :key="index" style="margin-top:0px;">
              * {{item.content}}
              <el-button type="text" @click="deleteNewNoteTableClick(index)">
                <i style="color:red;font-size:20px;" class="el-icon-delete"></i>
              </el-button>
            </div>
          </div>
        </div>
      <div>
        <el-checkbox v-model="isSignaturePrint" @change="changeSignatureHandle">打印客户确认</el-checkbox>
      </div>
        <!-- Save -->
        <div class="save-bottom">
          <el-button v-if="userInfo.operateStatus && form.productId!=0 && form.verifiedList==0" type="warning" @click="handleCnSpecSNew('Continue Edit',1)">新增至 Verify List</el-button>
          <el-button v-if="userInfo.operateStatus" type="primary" @click="handleCnSpecSNew('Continue Edit')">保存</el-button>
          <el-button v-if="userInfo.operateStatus" type="primary" @click="handleCnSpecSNew('New')">保存并新增</el-button>
          <el-button
            v-if="cnpemission(userInfo.username) && isShowVertify && userInfo.operateStatus"
            type="warning"
            @click="cnvertify(formData.id,'shxto')"
            >审核</el-button>
          <el-button @click="backcn">返回首页</el-button>
          <el-button type="success" icon="el-icon-printer" @click="printCnSPage()">打印</el-button>
        </div>
      </div>
    </center>
  </div>
</template>
<script>
import base from "@/common/base.js";
import COAOilShxto from "../PrintProductTypeModule/Cn-OilShxto";
import newItemMethod from "../module/NewItemMethod";
export default {
  props: ["baseInfoDetail"],
  mixins: [base],
  components: { COAOilShxto },
  data() {
    return {
      isShowVertify:false,
      newItemType:"New",
      isSignaturePrint: false,
      specificationYeastAndMoldD: false,
      colorD: false,
      colorList:[
        { label: "标准", value: "标准" },
        { label: "浅黄--黄色", value: "浅黄--黄色" },
        { label: "浅黄色--棕黄色", value: "浅黄色--棕黄色" },
        { label: "浅红棕色--深棕红色", value: "浅红棕色--深棕红色" },
        { label: "红色", value: "红色" },
      ],
      shTestArr: [],

      inclNameArr: [],
      inclNameInputVisible: "",
      inclNameInputValue: "",
      inciNameD: false,
      inclNameArrIndex:-1,

      commonNameArr: [],
      commonNameInputVisible: "",
      commonNameInputValue: "",
      commonNameD: false,
      commonNameArrIndex:-1,

      botanicalNameArr: [],
      botanicalNameInputVisible: "",
      botanicalNameInputValue: "",
      botanicalNameD: false,
      botanicalNameArrIndex:-1,

      partUsedArr: [],
      partUsedInputVisible: "",
      partUsedInputValue: "",
      partUsedD: false,
      partUsedArrIndex:-1,

      noteTableData: [
        {
          revisionDate: "",
          requestedBy: "",
          itemsRevised: "",
          editedBy: "",
          approvedBy: "",
          reason:"",
          isComplete:false,
        },
      ],
      newNoteArr:[],
      newNote:"",
      shelflifeList: [
        { label: "3个月", value: "3" },
        { label: "6个月", value: "6" },
        { label: "12个月", value: "12" },
        { label: "18个月", value: "18" },
        { label: "24个月", value: "24" },
        { label: "36个月", value: "36" }
      ],

      printShow: false,
      revDateTime:base.methods.getLocalNowTime(),
      baseInfoCustomFieldList:[],
      form: {
        baseInfoCustomField:"",
        otherIngredients:"",
        versionNumber: "",
        revNumber:1,
        revDate:[],
        BaseInfoEditPrintTitle: "上海天源植物制品有限公司",
        BaseInfoEditPrintTitleCheckbox: false,
        BaseInfoEditPrintSubtitle: "PRODUCT SPECIFICATION",
        BaseInfoEditPrintSubtitleCheckbox: false,
        productId:"",
        productType: "",
        BaseInfoItemCode: "",
        BaseInfoProductName: "",
        BaseInfoProductDescription: "",
        BaseInfoLot: "",
        fattryLimitCaprylic: "",
        fattryMethodCaprylic: "AOCS Ce 1e-91",
        fattryLimitCapric: "",
        fattryMethodCapric: "AOCS Ce 1e-91",
        fattryLimitLauric: "",
        fattryMethodLauric: "AOCS Ce 1e-91",
        fattryLimitMyristic: "",
        fattryMethodMyristic: "AOCS Ce 1e-91",
        fattryLimitMyristoleic: "",
        fattryMethodMyristoleic: "AOCS Ce 1e-91",
        fattryLimitArachidic: "",
        fattryMethodArachidic: "AOCS Ce 1e-91",
        fattryLimitArachidonic: "",
        fattryMethodArachidonic: "AOCS Ce 1e-91",
        fattryLimitEicosapentaenoic: "",
        fattryMethodEicosapentaenoic: "AOCS Ce 1e-91",
        fattryLimitDocosahexaenoic: "",
        fattryMethodDocosahexaenoic: "AOCS Ce 1e-91",
        fattryLimitLinoleicacid: "",
        fattryMethodLinoleicacid: "AOCS Ce 1e-91",
        fattryLimitPunicicacid: "",
        fattryMethodPunicicacid: "AOCS Ce 1e-91",

        confidential:false,
        fattryLimitPalmitica: "", //28.0-35.0
        fattryLimitPalmitoleic: "", //25.0-55.0
        fattryLimitStearic: "", //0-3.0
        fattryLimitOleicVaccenic: "", //21.0-32.0
        fattryLimitLinoleic: "", //3.0-12.0
        fattryLimitALinolenic: "", //0-4.0
        fatterLimitOthers: "", //0-3.0
        fattryLimitKey1: "",
        fattryLimitKey1Value: "",
        fattryMethodKey1Value: "",
        fattryLimitKey2: "",
        fattryLimitKey2Value: "",
        fattryMethodKey2Value: "",
        fattryLimitKey3: "",
        fattryLimitKey3Value: "",
        fattryMethodKey3Value: "",
        fattryLimitKey4: "",
        fattryLimitKey4Value: "",
        fattryMethodKey4Value: "",
        fattryLimitC8: "",
        fattryMethodC8: "",
        fattryLimitC10: "",
        fattryMethodC10: "",
        fattryMethodPalmitica: "AOCS Ce 1e-91",
        fattryMethodPalmitoleic: "AOCS Ce 1e-91",
        fattryMethodStearic: "AOCS Ce 1e-91",
        fattryMethodOleicVaccenic: "AOCS Ce 1e-91",
        fattryMethodLinoleic: "AOCS Ce 1e-91",
        fattryMethodALinolenic: "AOCS Ce 1e-91",
        fatterMethodOthers: "AOCS Ce 1e-91",
        testLimitHeavyMetals: "10",
        testMethodHeavyMetals: "USP <231>",
        testLimitArsenic: "",
        testMethodArsenic: "AOCS Ca 18c-91",
        testLimitCadmium: "",
        testMethodCadmium: "AOAC 986.15",
        testLimitLead: "",
        testMethodLead: "AOAC 986.15",
        testLimitMercury: "",
        testMethodMercury: "AOAC 971.2",
        testLimitAcidValue: "",
        testMethodAcidValue: "AOCS Cd 3d-63",
        testLimitPeroxideValue: "",
        testMethodPeroxideValue: "AOCS Cd 8b-90",
        testLimitSaponificationValue: "",
        testMethodSaponificationValue: "AOCS Cd 3-25",
        testLimitUnsaponifiableMaster: "",
        testMethodUnsaponifiableMaster: "AOCS Ca 6a-40",
        testLimitMoistureVolatiles: "", 
        testMethodMoistureVolatiles: "AOCS Ca 2d-25",
        testLimitPesticides: "符合USP <561>",
        testMethodPesticides: "USP <561>",
        customKey1: "",
        customValue1: "",
        customKey2: "",
        customValue2: "",
        customKey3: "",
        customValue3: "",
        testLimitCustomKey1: "",
        testLimitCustomKey1Value: "",
        testMethodCustomKey1Value: "",
        testLimitCustomKey2: "",
        testLimitCustomKey2Value: "",
        testMethodCustomKey2Value: "",
        specificationTotalPlateCount: "",
        specificationYeastAndMold: "",
        specificationEcoli: "",
        specificationSalmonella: "",
        specificationStaphAureus: "",
        specificationTotalColiforms: "",
        testMethodTotalPlateCount: "FDA-BAM",
        testMethodYeastAndMold: "FDA-BAM",
        testMethodEcoli: "FDA-BAM",
        testMethodSalmonella: "FDA-BAM",
        testMethodTaphAureus: "FDA-BAM",
        testMethodTotalColiforms: "FDA-BAM",
        microbiologicalCustomerKey1: "",
        microbiologicalCustomerValue1: "",
        microbiologicalTestMethodCustomerKey1: "",
        microbiologicalTestMethodCustomerValue1: "",
        appearance: "粘性液体",
        shelflife: "未开封情况下两年保质期。",
        shelflifeNumber: "",
        storage: "避光，密封，在干燥处保存。",
        packageInfo: "",
        color: "",
        odor: "天然植物气味",
        taste: "",

        genSpecCustomField: "",
        analysisCustomField: "",
        fattyCustomField: "",
        micTestsCustomField: "",

        tableInput1:"",
        tableInput2:"",
        tableInput3:"",
        tableInput4:"",
        tableInput5:"",
        tableInputCustomField:"",
        isShowNotes2:true
      },
      formData: {
        id: 0,
        productName: "",
        itemCode: "",
        lot: "",
        jsonValue: {},
      },
      genSpecCustomFieldAttr: [],
      analysisCustomFieldAttr: [],
      fattyCustomFieldAttr:[],
      micTestsCustomFieldAttr:[],
      customField: {
        customKey: "",
        customValue: "",
        customKey1: "",
        customValue1: "",
        customKey2: "",
        customValue2: "",
      },
      tableInputCustomFieldAttr: [],
      tableInputField: {
        tableInput1: "",
        tableInput2: "",
        tableInput3: "",
        tableInput4: "",
        tableInput5: "",
      },
    };
  },
  methods:newItemMethod,
  mounted() {
    let params = { ...this.$route.query };
    if (params.id) {
      this.isShowVertify = true
      this.getCnSpecSData(params);
      this.formData.id = params.id;
      this.newItemType="Edit";
    }else {
      this.newItemType = "New";
    }
    let localData = localStorage.getItem("tpsDataKey");
    let data;
    if (localData != "undefined") {
      data = JSON.parse(localStorage.getItem("tpsDataKey"));
    }

    if (data) {
      Object.keys(data).forEach((key) => {
        this.form[key] = data[key];
      });
    }
  },
  watch: {
    baseInfoDetail: {
      handler(basInfo) {
        console.log("oilshxto=====basInfo")
        console.log(basInfo)
        this.form.productType=basInfo.params.productType;
        this.form.BaseInfoProductName=basInfo.params.BaseInfoProductName;
        this.form.BaseInfoItemCode=basInfo.params.BaseInfoItemCode;
        this.form.BaseInfoProductDescription=basInfo.params.BaseInfoProductDescription;
        this.form.versionNumber=basInfo.params.versionNumber;
        this.baseInfoCustomFieldList = basInfo.BaseInfoCustomFieldAttr
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../module/NewItemStyle.scss";
.acidSpanClass{
  width: 35% !important;
}
</style>

