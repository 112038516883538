<template>
  <div class="page" style="text-align: left">
    <div class="row">
      <div style="width: 99%">
        <div class="header" id="header" style="width: 100%; height: 100px">
          <div style="width: 11%; float: left">
            <img
              src="../../assets/print_logo.jpg"
              style="width: 100px; height: 100px"
            />
          </div>
          <div style="width: 89%; float: right">
            <p
              style="
                font-size: 28px;
                font-family: Times New Roman;
                text-align: center;
                vertical-align: text-top;
              "
            >
              <b v-if="printModel.form.BaseInfoEditPrintTitleCheckbox==false">Draco Natural Products, Inc. </b>
              <b v-if="printModel.form.BaseInfoEditPrintTitleCheckbox">{{printModel.form.BaseInfoEditPrintTitle}}</b>
            </p>
            <p
              style="
                font-size: 28px;
                font-family: Times New Roman;
                text-align: center;
                vertical-align: text-top;
              "
            >
              <!-- <b><u>TECHNICAL DATA SHEET</u></b> -->
              <b v-if="printModel.form.BaseInfoEditPrintSubtitleCheckbox==false"><u>TECHNICAL DATA SHEET</u></b>
              <b v-if="printModel.form.BaseInfoEditPrintSubtitleCheckbox"><u>{{printModel.form.BaseInfoEditPrintSubtitle}}</u></b>
            </p>
          </div>
        </div>

        <!-- Product info -->
        <table style="width: 99%">
          <tr v-show="printModel.form.productName">
            <td style="width: 25%"><b>产品名称</b></td>
            <td style="width: 75%">
              <span t-field="o.name" />{{ printModel.form.productName }}
            </td>
          </tr>
          <tr v-show="printModel.form.versionNumber">
            <td style="width: 25%"><b>版本号</b></td>
            <td style="width: 75%">
              {{ printModel.form.versionNumber }}
            </td>
          </tr>

          <tr v-show="printModel.form.itemCode">
            <td style="width: 25%"><b>产品编号</b></td>
            <td style="width: 75%">
              <span t-field="o.default_code" />{{ printModel.form.itemCode }}
            </td>
          </tr>
          <tr v-show="printModel.form.productDescription">
            <td style="width: 25%"><b>产品说明</b></td>
            <td style="width: 75%">
              <span t-field="o.description" />{{
                printModel.form.productDescription
              }}
            </td>
          </tr>
        </table>
        <table style="width: 99%" v-show="printModel.baseInfoCustomFieldList && printModel.baseInfoCustomFieldList.length>0">
          <tr v-for="(baseinfoCustom,baseinfoIndex) in printModel.baseInfoCustomFieldList" :key="baseinfoIndex">
            <td style="width: 25%"><b>{{baseinfoCustom.baseInfoInput1}}</b></td>
            <td style="width: 75%">
              {{ baseinfoCustom.baseInfoInput2 }}
            </td>
          </tr>
        </table>

        <div style="height: 10px"></div>
        <div style="font-size: 18px">
          <b>标准规格</b>
        </div>
        <!-- 遍历table -->
        <template
          v-if="
            printModel.inclNameArr.length > 0 ||
            printModel.botanicalNameArr.length > 0 ||
            printModel.commonNameArr.length > 0 ||
            printModel.partUsedArr.length > 0
          "
        >
          <table
            style="width: 98%;border-collapse: collapse;"
            cellspacing="0"
            class="tab"
          >
            <thead>
              <tr style="text-align: left;border-bottom:2px solid gray">
                <th v-show="printModel.commonNameArr.length > 0">通用名/其他名称</th>
                <th v-show="printModel.inclNameArr.length>0">INCI名称</th>
                <th v-show="printModel.botanicalNameArr.length > 0">植物学名</th>
                <th v-show="printModel.partUsedArr.length > 0">使用部位</th>
              </tr>
            </thead>
            <tbody>
              <tr :key="i" v-for="(tag, i) in maxArr">
                <td v-show="i + 1 <= printModel.commonNameArr.length">
                  {{ printModel.commonNameArr[i] }}
                </td>
                <td v-show="(i+1)<=printModel.inclNameArr.length">{{printModel.inclNameArr[i]}}</td>
                <td v-show="i + 1 <= printModel.botanicalNameArr.length" style="font-style:italic;">
                  {{ printModel.botanicalNameArr[i] }}
                </td>
                <td v-show="i + 1 <= printModel.partUsedArr.length">
                  {{ printModel.partUsedArr[i] }}
                </td>
              </tr>
            </tbody>
          </table>
        </template>
        
        <template v-if="isShowTable">
          <table
            style="width: 98%;margin-top:10px;"
            cellspacing="0"
            class="tab"
          >
            <tbody>
              <tr>
                <td v-show="printModel.form.tableInput1" style="width:20%;">{{ printModel.form.tableInput1 }}</td>
                <td v-show="printModel.form.tableInput2" style="width:20%;">{{ printModel.form.tableInput2 }}</td>
                <td v-show="printModel.form.tableInput3" style="width:20%;">{{ printModel.form.tableInput3 }}</td>
                <td v-show="printModel.form.tableInput4" style="width:20%;">{{ printModel.form.tableInput4 }}</td>
                <td v-show="printModel.form.tableInput5" style="width:20%;">{{ printModel.form.tableInput5 }}</td>
              </tr>
              <tr v-for="(item, tableInputIndex) in printModel.tableInputCustomFieldAttr" :key="tableInputIndex + 1">
                <td v-show="item.tableInput1">{{ item.tableInput1 }}</td>
                <td v-show="item.tableInput2">{{ item.tableInput2 }}</td>
                <td v-show="item.tableInput3">{{ item.tableInput3 }}</td>
                <td v-show="item.tableInput4">{{ item.tableInput4 }}</td>
                <td v-show="item.tableInput5">{{ item.tableInput5 }}</td>
              </tr>
            </tbody>
          </table>
        </template>

        <table style="width: 99%">
          <tbody>
            <!-- <tr v-show="printModel.commonNameArr.length > 0">
              <td style="width: 30%"><b>通用名/其他名称</b></td>
              <td style="width: 70%">
                <span
                  t-field="o.inci_name"
                  :key="i"
                  v-for="(tag, i) in printModel.commonNameArr"
                  >{{ tag }}
                  <template v-if="i + 1 < printModel.commonNameArr.length"
                    >,</template
                  >
                </span>
              </td>
            </tr>
            <tr v-show="printModel.inclNameArr.length > 0">
              <td style="width: 30%"><b>INCI名称</b></td>
              <td style="width: 70%">
                <span
                  t-field="o.inci_name"
                  :key="i"
                  v-for="(tag, i) in printModel.inclNameArr"
                  >{{ tag }}
                  <template v-if="i + 1 < printModel.inclNameArr.length"
                    >,</template
                  >
                </span>
              </td>
            </tr>
            <tr v-show="printModel.botanicalNameArr.length > 0">
              <td style="width: 30%"><b>植物学名</b></td>
              <td style="width: 70%">
                <span
                  t-field="o.inci_name"
                  :key="i"
                  v-for="(tag, i) in printModel.botanicalNameArr"
                  >{{ tag }}
                  <template v-if="i + 1 < printModel.botanicalNameArr.length"
                    >,</template
                  >
                </span>
              </td>
            </tr>
            <tr v-show="printModel.partUsedArr.length > 0">
              <td style="width: 30%"><b>使用部位</b></td>
              <td style="width: 70%">
                <span
                  t-field="o.inci_name"
                  :key="i"
                  v-for="(tag, i) in printModel.partUsedArr"
                  >{{ tag }}
                  <template v-if="i + 1 < printModel.partUsedArr.length"
                    >,</template
                  >
                </span>
              </td>
            </tr> -->

            <tr v-show="printModel.form.countryOfOrigin">
              <td style="width: 30%"><b>原产地</b></td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{
                  printModel.form.countryOfOrigin
                }}</span>
              </td>
            </tr>

            <tr v-show="printModel.form.appearance">
              <td style="width: 30%"><b>外观</b></td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{
                  printModel.form.appearance
                }}</span>
              </td>
            </tr>

            <tr v-show="printModel.form.solubility">
              <td style="width: 30%"><b>溶解度</b></td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{
                  printModel.form.solubility
                }}</span>
              </td>
            </tr>

            <tr v-show="printModel.form.color">
              <td style="width: 30%"><b>颜色</b></td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{ printModel.form.color }}</span>
              </td>
            </tr>

            <tr v-show="printModel.form.odor">
              <td style="width: 30%"><b>气味</b></td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{ printModel.form.odor }}</span>
              </td>
            </tr>

            <tr v-show="printModel.form.taste">
              <td style="width: 30%"><b>味道</b></td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{ printModel.form.taste }}</span>
              </td>
            </tr>

            <tr v-show="printModel.form.ehxtoIcip">
              <td style="width: 30%"><b>植物主要成分</b></td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{
                  printModel.form.ehxtoIcip
                }}</span>
              </td>
            </tr>
            <tr v-show="printModel.form.ehxtoHomogeneity">
              <td style="width: 30%"><b>均匀度</b></td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{
                  printModel.form.ehxtoHomogeneity
                }}</span>
              </td>
            </tr>
            <tr v-show="printModel.form.ehxtoExtractionProcess">
              <td style="width: 30%"><b>提取过程</b></td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{
                  printModel.form.ehxtoExtractionProcess
                }}</span>
              </td>
            </tr>
            <tr v-show="printModel.form.ehxtoSolventExtraction">
              <td style="width: 30%"><b>提取溶剂</b></td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{
                  printModel.form.ehxtoSolventExtraction
                }}</span>
              </td>
            </tr>
            <tr v-show="printModel.form.ehxtoCarrier">
              <td style="width: 30%"><b>辅料</b></td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{
                  printModel.form.ehxtoCarrier
                }}</span>
              </td>
            </tr>
            <tr v-show="printModel.form.ehxtoPesticides">
              <td style="width: 30%"><b>农药残留</b></td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{
                  printModel.form.ehxtoPesticides
                }}</span>
              </td>
            </tr>
            <tr v-show="printModel.form.ehxtoCertification">
              <td style="width: 30%"><b>证书</b></td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{
                  printModel.form.ehxtoCertification
                }}</span>
              </td>
            </tr>
            <tr v-show="printModel.form.otherIngredients">
              <td style="width: 30%"><b>其他成分</b></td>
              <td style="width: 35%">
                <span t-field="o.apperance">{{ printModel.form.otherIngredients }}</span>
              </td>
              <td style="width: 35%">
              </td>
            </tr>
            <tr v-show="printModel.form.ehxtoCustomerKey1">
              <td style="width: 30%">
                <b>{{ printModel.form.ehxtoCustomerKey1 }}</b>
              </td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{
                  printModel.form.ehxtoCustomerValue1
                }}</span>
              </td>
            </tr>
          </tbody>
          
          <tbody v-for="(item, genIndex) in printModel.genSpecCustomFieldAttr" :key="genIndex" >
            <tr v-show="item.customKey">
              <td style="width: 30%">
                <b>{{ item.customKey }}</b>
              </td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{ item.customValue }}</span>
              </td>
            </tr>
          </tbody>
        </table>

        <!-- Analysis -->
        <div style="height: 10px"></div>
        <table style="width: 99%">
          <tr>
            <th style="width: 30%">
              <b><u>产品规格</u></b>
            </th>
            <th style="width: 35%"><u></u></th>
            <th style="width: 35%"><u>测试方法</u></th>
          </tr>
          <tbody>
            <tr v-show="printModel.form.testLimitMoisture">
              <td style="width: 30%"><b>水分</b></td>
              <td style="width: 35%">
                {{ printModel.form.testLimitMoisture }}
              </td>
              <td style="width: 35%">{{ printModel.form.testMethodMeshSize }}</td>
            </tr>

            <tr v-show="printModel.form.testLimitSpecificaGravity">
              <td style="width: 30%"><b>比重</b></td>
              <td style="width: 35%">
                {{ printModel.form.testLimitSpecificaGravity }}
              </td>
              <td style="width: 35%">
                {{ printModel.form.testMethodSpecificaGravity }}
              </td>
            </tr>

            <tr v-show="printModel.form.testLimitRefractiveIndex">
              <td style="width: 30%"><b>折光率</b></td>
              <td style="width: 35%">
                {{ printModel.form.testLimitRefractiveIndex }}
              </td>
              <td style="width: 35%">
                {{ printModel.form.testMethodRefractiveIndex }}
              </td>
            </tr>

            <tr v-show="printModel.form.testLimitOpticalRotation">
              <td style="width: 30%"><b>旋光度</b></td>
              <td style="width: 35%">
                <span t-field="o.standard2">{{
                  printModel.form.testLimitOpticalRotation
                }}</span>
              </td>
              <td style="width: 35%">
                {{ printModel.form.testMethodOpticalRotation }}
              </td>
            </tr>

            <tr v-if="printModel.form.testLimitHeavyMetals">
              <td style="width: 30%"><b>总重金属</b></td>
              <td style="width: 35%">
                &lt;{{ printModel.form.testLimitHeavyMetals }} ppm
              </td>
              <td style="width: 35%">
                {{ printModel.form.testMethodHeavyMetals }}
              </td>
            </tr>

            <tr v-if="printModel.form.testLimitArsenic">
              <td style="width: 30%"><b>砷</b></td>
              <td style="width: 35%">
                {{ printModel.form.testLimitArsenic }} ppm
              </td>
              <td style="width: 35%">
                {{ printModel.form.testMethodArsenic }}
              </td>
            </tr>

            <tr v-if="printModel.form.testLimitCadmium">
              <td style="width: 30%"><b>镉 </b></td>
              <td style="width: 35%">
                {{ printModel.form.testLimitCadmium }} ppm
              </td>
              <td style="width: 35%">
                {{ printModel.form.testMethodCadmium }}
              </td>
            </tr>

          
            <tr v-if="printModel.form.testLimitLead">
              <td style="width: 30%"><b>铅 </b></td>
              <td style="width: 35%">
                {{ printModel.form.testLimitLead }} ppm
              </td>
              <td style="width: 35%">{{ printModel.form.testMethodLead }}</td>
            </tr>

            <tr v-if="printModel.form.testLimitMercury">
              <td style="width: 30%"><b>汞 </b></td>
              <td style="width: 35%">
                {{ printModel.form.testLimitMercury }} ppm
              </td>
              <td style="width: 35%">
                {{ printModel.form.testMethodMercury }}
              </td>
            </tr>

            <tr v-if="printModel.form.ehxtoTestLimitCustomerKey1">
              <td style="width: 30%">
                <b>{{ printModel.form.ehxtoTestLimitCustomerKey1 }} </b>
              </td>
              <td style="width: 30%">
                {{ printModel.form.ehxtoTestLimitCustomerValue1 }}
              </td>
              <td style="width: 35%">
                {{ printModel.form.ehxtoTestMethodCustomerValue1 }}
              </td>
            </tr>

            <tr v-if="printModel.form.ehxtoTestLimitCustomerKey2">
              <td style="width: 30%">
                <b>{{ printModel.form.ehxtoTestLimitCustomerKey2 }} </b>
              </td>
              <td style="width: 30%">
                {{ printModel.form.ehxtoTestLimitCustomerValue2 }}
              </td>
              <td style="width: 35%">
                {{ printModel.form.ehxtoTestMethodCustomerValue2 }}
              </td>
            </tr>
            <tr v-if="printModel.form.ehxtoTestLimitCustomerKey3">
              <td style="width: 30%">
                <b>{{ printModel.form.ehxtoTestLimitCustomerKey3 }} </b>
              </td>
              <td style="width: 30%">
                {{ printModel.form.ehxtoTestLimitCustomerValue3 }}
              </td>
              <td style="width: 35%">
                {{ printModel.form.ehxtoTestMethodCustomerValue3 }}
              </td>
            </tr>
            <tr v-if="printModel.form.ehxtoTestLimitCustomerKey4">
              <td style="width: 30%">
                <b>{{ printModel.form.ehxtoTestLimitCustomerKey4 }}</b>
              </td>
              <td style="width: 30%">
                {{ printModel.form.ehxtoTestLimitCustomerValue4 }}
              </td>
              <td style="width: 35%">
                {{ printModel.form.ehxtoTestMethodCustomerValue4 }}
              </td>
            </tr>
          </tbody>
          <tbody v-for="(item, analysisIndex) in printModel.analysisCustomFieldAttr" :key="analysisIndex" >
            <tr v-show="item.customKey">
              <td style="width: 35%">
                <b>{{ item.customKey }}</b>
              </td>
              <td style="width: 35%">
                <span t-field="o.apperance">{{ item.customValue }}</span>
              </td>
              <td style="width: 35%">
                <span t-field="o.apperance">{{ item.customValue1 }}</span>
              </td>
            </tr>
          </tbody>
        </table>

        <div style="height: 10px"></div>
        <table style="width: 99%">
          <tbody>
            <tr>
              <th style="width: 30%">
                <b><u>微生物检验</u></b>
              </th>
              <th style="width: 35%"><u></u></th>
              <th style="width: 35%"><u>测试方法</u></th>
            </tr>
            <tr v-show="printModel.form.specificationTotalPlateCount">
              <td style="width: 30%"><b>细菌总数</b></td>
              <td style="width: 35%">
                <span t-field="o.total_plate" />&lt;{{
                  printModel.form.specificationTotalPlateCount
                }} cfu/g
              </td>
              <td style="width: 35%">
                <span t-field="o.total_plate_test" />FDA-BAM
              </td>
            </tr>

            <tr v-show="printModel.form.specificationYeastAndMold">
              <td style="width: 30%"><b>酵母和霉菌</b></td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold" />&lt;{{
                  printModel.form.specificationYeastAndMold
                }} cfu/g
              </td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />FDA-BAM
              </td>
            </tr>

            <tr v-show="printModel.form.specificationEcoli">
              <td style="width: 30%"><b>大肠杆菌</b></td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold" />{{
                  printModel.form.specificationEcoli
                }}<span
                  v-if="printModel.form.specificationEcoli != '阴性' && printModel.form.specificationEcoli.toLowerCase() != 'negative'"
                  > cfu/g</span
                >
              </td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />FDA-BAM
              </td>
            </tr>

            <tr v-show="printModel.form.specificationSalmonella">
              <td style="width: 30%"><b>沙门氏菌</b></td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold" />{{
                  printModel.form.specificationSalmonella
                }}<span
                  v-if="printModel.form.specificationSalmonella != '阴性' && printModel.form.specificationSalmonella.toLowerCase() != 'negative'"
                  > cfu/g</span
                >
              </td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />FDA-BAM
              </td>
            </tr>

            <tr v-show="printModel.form.specificationStaphAureus">
              <td style="width: 30%"><b>金色葡萄球菌</b></td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold" />{{
                  printModel.form.specificationStaphAureus
                }}<span
                  v-if="printModel.form.specificationStaphAureus != '阴性' && printModel.form.specificationStaphAureus.toLowerCase() != 'negative'"
                  > cfu/g</span
                >
              </td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />FDA-BAM
              </td>
            </tr>

            <tr v-show="printModel.form.specificationTotalColiforms">
              <td style="width: 30%"><b>大肠菌群</b></td>
              <!-- <td style="width: 35%">
                <span t-field="o.yeast_mold" />&lt;{{
                  printModel.form.specificationTotalColiforms
                }} MPN/g
              </td> -->
              <td style="width: 35%" v-if="
            !printModel.form.specificationTotalColiforms || 
            printModel.form.specificationTotalColiforms.toLowerCase()=='negative' || 
            printModel.form.specificationTotalColiforms.toLowerCase()=='阴性'">
              {{ printModel.form.specificationTotalColiforms }}
            </td>
            <td style="width: 35%" v-else>
              &lt;{{ printModel.form.specificationTotalColiforms }} MPN/g
            </td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />FDA-BAM
              </td>
            </tr>

            <tr v-if="printModel.form.microbiologicalCustomerKey1">
              <td style="width: 30%">
                <b>{{ printModel.form.microbiologicalCustomerKey1 }}</b>
              </td>
              <td style="width: 35%">
                {{ printModel.form.microbiologicalCustomerValue1 }}
              </td>
              <td style="width: 35%">
                {{ printModel.form.microbiologicalTestMethodCustomerValue1 }}
              </td>
            </tr>
          </tbody>
          
          <tbody v-for="(item, micTestsIndex) in printModel.micTestsCustomFieldAttr" :key="micTestsIndex" >
            <tr v-show="item.customKey">
              <td style="width: 35%">
                <b>{{ item.customKey }}</b>
              </td>
              <td style="width: 35%">
                <span t-field="o.apperance">{{ item.customValue }}</span>
              </td>
              <td style="width: 35%">
                <span t-field="o.apperance">{{ item.customValue1 }}</span>
              </td>
            </tr>
          </tbody>
        </table>

        <div style="height: 10px"></div>
        <table style="width: 99%">
          <tbody>
            <tr>
              <td style="width: 30%; font-size: 18px">
                <b><u>其他</u></b>
              </td>
              <td style="width: 70%" />
            </tr>
            <tr v-if="printModel.form.shelflife">
              <td style="width: 30%"><b>保质期</b></td>
              <td style="width70%">
                <span t-field="o.shelf_life" />{{ printModel.form.shelflife }}
              </td>
            </tr>

            <tr v-if="printModel.form.storage">
              <td style="width: 30%"><b>储存条件</b></td>
              <td style="width70%">
                <span t-field="o.storage" />{{ printModel.form.storage }}
              </td>
            </tr>
            <tr v-if="printModel.form.packageInfo.length>0">
              <td style="width: 30%"><b>包装</b></td>
              <td style="width70%">
                <span t-field="o.packageInfo" />{{
                  printModel.form.packageInfo
                }}
              </td>
            </tr>
          </tbody>
        </table>

        <div style="clear: both"></div>
        <div style="height: 20px"></div>
        <div style="font-size: 18px">
          <b><u>注释</u></b>
        </div>
        <div
          class="footer"
          id="footer"
          style="
            font-size: 11px;
            font-family: Times New Roman;
            margin-top: 10px;
          "
        >
          <div>
            <span><i>* 不同批次产品的颜色可能会因天然原材料的自然变化而产生差异。</i></span>
          </div>
          <div style="height: 10px"></div>
          <div v-if="printModel.form.isShowNotes2">
            <span><i>* 产品含抗氧化化合物，可能会随着时间推移颜色变深但不影响使用效果。</i></span>
          </div>
          <div style="height: 10px" v-if="printModel.form.isShowNotes2"></div>
          <div>
            <span><i>* 天然植物产品在长期静置时可能会产生一些产品本身的沉淀物，这不会影响产品的质量；如发现产品有沉淀，请在使用前适当加热并搅拌至充分混合。</i></span>
          </div>
          <p v-for="(item, index) in printModel.newNoteArr" :key="index">
            * {{item.content}}
          </p>
          <div style="height: 10px"></div>
          <div>
            <b><u>Disclaimer: The product specifications, limits, test methods, and information contained on this document are accurate to the best of our knowledge based upon general industry information, available scientific data, and customer requests. These values are not intended to be used as specifications for a finished product and should be used only at the sole discretion of the purchaser. According to GMP regulations, the test results of the product must be independently verified by the purchasing party. The appropriate use of this product is the sole responsibility of the user or the purchasing party.</u></b>
          </div>
        </div>
        <FooterSigner></FooterSigner>
      </div>
    </div>
  </div>
</template>
<script>
import FooterSigner from "./Cn-FooterSigner";
export default {
  name: "CSFMU",
  components: { FooterSigner },
  props: ["printModel"],
  data() {
    return {
      username: "",
    };
  },
  computed: {
    maxArr() {
      let tmp = [
        this.printModel.commonNameArr.length,
        this.printModel.inclNameArr.length,
        this.printModel.botanicalNameArr.length,
        this.printModel.partUsedArr.length,
      ].sort();
      console.info(tmp[tmp.length - 1]);
      return tmp[tmp.length - 1];
    },
    isShowTable(){
      if(this.printModel.form.tableInput1 || this.printModel.form.tableInput2 || this.printModel.form.tableInput3 || this.printModel.form.tableInput4 || this.printModel.form.tableInput5){
        return true
      }
      return false
    }
  },
  methods: {},
};
</script>

<style scoped>
.tab tr td,
.tab thead th {
  padding: 5px;
}
</style>