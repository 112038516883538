<template>
  <div class="page" style="text-align: left; width: 100vw">
    <div class="row">
      <div style="width: 99%">
        <div class="header" id="header" style="width: 100%; height: 100px">
          <div style="width: 11%; float: left">
            <img
              src="../../assets/print_logo.jpg"
              style="width: 100px; height: 100px"
            />
          </div>
          <div style="width: 89%; float: right">
            <p
              style="
                font-size: 28px;
                font-family: Times New Roman;
                text-align: center;
                vertical-align: text-top;
              "
            >
              <!-- <b>Draco	Natural	Products,	Inc. </b> -->
              <b
                v-if="printModel.params.BaseInfoEditPrintTitleCheckbox == false"
                >Draco Natural Products, Inc.</b
              >
              <b v-if="printModel.params.BaseInfoEditPrintTitleCheckbox">{{
                printModel.params.BaseInfoEditPrintTitle
              }}</b>
            </p>
            <p
              v-if="
                printModel.params.BaseInfoEditPrintSubtitleCheckbox == false
              "
              style="
                font-size: 28px;
                font-family: Times New Roman;
                text-align: center;
                vertical-align: text-top;
              "
            >
              <b><u>PRODUCT SPECIFICATION</u></b>
            </p>
            <p
              v-if="printModel.params.BaseInfoEditPrintSubtitleCheckbox"
              style="
                font-size: 28px;
                font-family: Times New Roman;
                text-align: center;
                vertical-align: text-top;
              "
            >
              <b
                ><u>{{ printModel.params.BaseInfoEditPrintSubtitle }}</u></b
              >
            </p>
            <p
              style="
                font-size: 28px;
                font-family: Times New Roman;
                text-align: center;
                vertical-align: text-top;
              "
            >
              <b>Custom Formulated Powdered Extracts</b>
            </p>
          </div>
        </div>

        <!-- Product info -->
        <table style="width: 99%">
          <!-- <tr v-show="printModel.params.productSubType">
                                    <td style="width:30%"><b>Product SubType</b></td>
                                    <td style="width:70%">
                                        {{printModel.params.productSubType}}
                                    </td>
                                </tr> -->

          <tr v-show="printModel.params.productName">
            <td style="width: 30%"><b>产品名称</b></td>
            <td style="width: 70%">
              {{ printModel.params.productName }}
            </td>
          </tr>
          <tr v-show="printModel.params.versionNumber">
            <td style="width: 30%"><b>版本号</b></td>
            <td style="width: 70%">
              {{ printModel.params.versionNumber }}
            </td>
          </tr>

          <tr v-show="printModel.params.itemCode">
            <td style="width: 30%"><b>产品编号</b></td>
            <td style="width: 70%">{{ printModel.params.itemCode }}</td>
          </tr>
          <tr v-show="printModel.params.productDescription">
            <td style="width: 30%"><b>产品说明</b></td>
            <td style="width: 70%">
              <div t-field="o.description">
                {{ printModel.params.productDescription }}
              </div>
            </td>
          </tr>
        </table>
        <table style="width: 99%" v-show="printModel.baseInfoCustomFieldList && printModel.baseInfoCustomFieldList.length>0">
          <tr v-for="(baseinfoCustom,baseinfoIndex) in printModel.baseInfoCustomFieldList" :key="baseinfoIndex">
            <td style="width: 30%"><b>{{baseinfoCustom.baseInfoInput1}}</b></td>
            <td style="width: 70%">
              {{ baseinfoCustom.baseInfoInput2 }}
            </td>
          </tr>
        </table>

        <div style="height: 10px"></div>
        <div style="font-size: 18px">
          <b></b>
        </div>

        <!-- 遍历table -->
        <template v-if="printModel.inclNameArr.length > 0">
          <table
            style="width: 98%;border-collapse: collapse;"
            cellspacing="0"
            class="tab"
          >
            <thead>
              <tr style="text-align: left;border-bottom:2px solid gray">
                <th v-show="printModel.commonOtherNameArr.length > 0">
                  通用名/其他名称
                </th>
                <th v-show="printModel.inclNameArr.length > 0">INCI名称</th>
                <th v-show="printModel.botanicalNameArr.length > 0">
                  植物学名
                </th>
                <th v-show="printModel.partUsedArr.length > 0">使用部位</th>
                <th v-show="printModel.percentageArr.length > 0">百分比</th>
              </tr>
            </thead>
            <tbody>
              <tr :key="i" v-for="(tag, i) in maxArr">
                <td v-show="i + 1 <= printModel.commonOtherNameArr.length">
                  {{ printModel.commonOtherNameArr[i] }}
                </td>
                <td v-show="i + 1 <= printModel.inclNameArr.length">
                  {{ printModel.inclNameArr[i] }}
                </td>
                <td v-show="i + 1 <= printModel.botanicalNameArr.length">
                  {{ printModel.botanicalNameArr[i] }}
                </td>
                <td v-show="i + 1 <= printModel.partUsedArr.length">
                  {{ printModel.partUsedArr[i] }}
                </td>
                <td v-show="i + 1 <= printModel.percentageArr.length">
                  {{ printModel.percentageArr[i] }}
                </td>
              </tr>
            </tbody>
          </table>
        </template>

        <div v-else-if="isSingle().count == 1">
          <div style="width: 30%; float: left">
            <b>{{ isSingle().name }}</b>
          </div>
          <div style="width: 70%; float: left">
            <div
              style="width: 265px; float: left"
              v-for="(item, val, index) in isSingle().arr"
              :key="index"
            >
              {{ item }}
              <span v-if="val + 1 < isSingle().arr.length" style="">,</span>
            </div>
          </div>
        </div>
        <template v-else>
          <table
            style="width: 98%;border-collapse: collapse;"
            cellspacing="0"
            class="tab"
          >
            <thead>
              <tr style="text-align: center;border-bottom:2px solid gray">
                <th v-show="printModel.commonOtherNameArr.length > 0">
                  通用名/其他名称
                </th>
                <th v-show="printModel.botanicalNameArr.length > 0">
                  植物学名
                </th>
                <th v-show="printModel.partUsedArr.length > 0">使用部位</th>
                <th v-show="printModel.percentageArr.length > 0">百分比</th>
              </tr>
            </thead>
            <tbody>
              <tr :key="i" v-for="(tag, i) in maxArr">
                <td v-show="printModel.commonOtherNameArr.length > 0">
                  <div v-show="i + 1 <= printModel.commonOtherNameArr.length">
                    {{ printModel.commonOtherNameArr[i] }}
                  </div>
                </td>
                <td v-show="printModel.botanicalNameArr.length > 0">
                  <div v-show="i + 1 <= printModel.botanicalNameArr.length">
                    {{ printModel.botanicalNameArr[i] }}
                  </div>
                </td>
                <td v-show="printModel.partUsedArr.length > 0">
                  <div v-show="i + 1 <= printModel.partUsedArr.length">
                    {{ printModel.partUsedArr[i] }}
                  </div>
                </td>
                <td v-show="printModel.percentageArr.length > 0">
                  <div v-show="i + 1 <= printModel.percentageArr.length">
                    {{ printModel.percentageArr[i] }}%
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </template>

        <template v-if="isShowTable">
          <table
            style="width: 98%; margin-top: 10px"
            cellspacing="0"
            class="tab"
          >
            <tbody>
              <tr>
                <td v-show="printModel.params.tableInput1" style="width: 20%">
                  {{ printModel.params.tableInput1 }}
                </td>
                <td v-show="printModel.params.tableInput2" style="width: 20%">
                  {{ printModel.params.tableInput2 }}
                </td>
                <td v-show="printModel.params.tableInput3" style="width: 20%">
                  {{ printModel.params.tableInput3 }}
                </td>
                <td v-show="printModel.params.tableInput4" style="width: 20%">
                  {{ printModel.params.tableInput4 }}
                </td>
                <td v-show="printModel.params.tableInput5" style="width: 20%">
                  {{ printModel.params.tableInput5 }}
                </td>
              </tr>
              <tr
                v-for="(
                  item, tableInputIndex
                ) in printModel.tableInputCustomFieldAttr"
                :key="tableInputIndex + 1"
              >
                <td v-show="item.tableInput1">{{ item.tableInput1 }}</td>
                <td v-show="item.tableInput2">{{ item.tableInput2 }}</td>
                <td v-show="item.tableInput3">{{ item.tableInput3 }}</td>
                <td v-show="item.tableInput4">{{ item.tableInput4 }}</td>
                <td v-show="item.tableInput5">{{ item.tableInput5 }}</td>
              </tr>
            </tbody>
          </table>
        </template>

        <div style="height: 10px"></div>

        <table style="width: 99%">
          <tbody>
            <!-- <tr v-show="typeof(printModel.inclNameArr) == 'undefined' ? true : printModel.inclNameArr.length>0">
                                        <td style="width:30%"><b>INCI名称</b></td>
                                        <td style="width:70%"><span t-field="o.inci_name" :key="i" v-for="(tag,i) in printModel.inclNameArr">
                                            {{tag}}
                                            <template v-if="i+1<printModel.inclNameArr.length">,</template>
                                        </span></td>
                                    </tr> -->
            <tr v-show="printModel.params.extractionRatio">
              <td style="width: 30%"><b>提取率</b></td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{
                  printModel.params.extractionRatio
                }}</span>
              </td>
            </tr>
            <tr v-show="printModel.params.processingAid">
              <td style="width: 30%"><b>加工助剂</b></td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{
                  printModel.params.processingAid
                }}</span>
              </td>
            </tr>
            <tr v-show="printModel.params.importantConstitueInPlant">
              <td style="width: 30%"><b>植物主要成分</b></td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{
                  printModel.params.importantConstitueInPlant
                }}</span>
              </td>
            </tr>

            <tr v-show="printModel.params.appearance">
              <td style="width: 30%"><b>外观</b></td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{
                  printModel.params.appearance
                }}</span>
              </td>
            </tr>

            <tr v-show="printModel.params.solubility">
              <td style="width: 30%"><b>溶解度</b></td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{
                  printModel.params.solubility
                }}</span>
              </td>
            </tr>

            <tr>
              <td style="width: 30%"><b>均匀度</b></td>
              <td style="width: 70%">
                <span t-field="o.apperance">完全均匀</span>
              </td>
            </tr>

            <tr v-show="printModel.params.ehxtoExtractionProcess">
              <td style="width: 30%"><b>提取过程</b></td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{
                  printModel.params.ehxtoExtractionProcess
                }}</span>
              </td>
            </tr>

            <tr v-show="printModel.params.solventForExtracti">
              <td style="width: 30%"><b>提取溶剂</b></td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{
                  printModel.params.solventForExtracti
                }}</span>
              </td>
            </tr>

            <!-- <tr v-show="printModel.params.carrier">
                                        <td style="width:30%"><b>Carrier</b></td>
                                        <td style="width:70%"><span t-field="o.apperance" >{{printModel.params.carrier}}</span></td>
                                    </tr> -->

            <tr v-show="printModel.params.carrier">
              <td style="width: 30%"><b>辅料</b></td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{
                  printModel.params.carrier
                }}</span>
              </td>
            </tr>

            <tr v-show="printModel.params.pesticides">
              <td style="width: 30%"><b>农药残留</b></td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{
                  printModel.params.pesticides
                }}</span>
              </td>
            </tr>

            <tr v-show="printModel.params.certification">
              <td style="width: 30%"><b>证书</b></td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{
                  printModel.params.certification
                }}</span>
              </td>
            </tr>
            <tr v-show="printModel.params.generalSpecificationKey1">
              <td style="width: 30%">
                <b>{{ printModel.params.generalSpecificationKey1 }}</b>
              </td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{
                  printModel.params.generalSpecificationValue1
                }}</span>
              </td>
            </tr>
            <tr v-show="printModel.params.generalSpecificationKey2">
              <td style="width: 30%">
                <b>{{ printModel.params.generalSpecificationKey2 }}</b>
              </td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{
                  printModel.params.generalSpecificationValue2
                }}</span>
              </td>
            </tr>
            <tr v-show="printModel.params.generalSpecificationKey3">
              <td style="width: 30%">
                <b>{{ printModel.params.generalSpecificationKey3 }}</b>
              </td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{
                  printModel.params.generalSpecificationValue3
                }}</span>
              </td>
            </tr>
          </tbody>

          <tbody
            v-for="(item, genIndex) in printModel.genSpecCustomFieldAttr"
            :key="genIndex"
          >
            <tr v-show="item.customKey">
              <td style="width: 30%">
                <b>{{ item.customKey }}</b>
              </td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{ item.customValue }}</span>
              </td>
            </tr>
          </tbody>
        </table>

        <!-- Analysis -->
        <div style="height: 10px"></div>
        <table style="width: 99%">
          <thead style="font-size: 18px">
            <tr>
              <th style="width: 30%">
                <b><u>分析</u></b>
              </th>
              <th style="width: 35%"><u>产品规格</u></th>
              <th style="width: 35%"><u>测试方法</u></th>
            </tr>
          </thead>
          <tbody>
            <tr v-show="printModel.params.specificationStandard1">
              <td style="width: 30%"><b>标准</b></td>
              <td style="width: 35%">
                {{ printModel.params.specificationStandard1 }}
              </td>
              <td style="width: 35%">
                {{ printModel.params.testMethodStandard1 }}
              </td>
            </tr>

            <tr v-show="printModel.params.specificationStandard2">
              <td style="width: 30%"><b>标准</b></td>
              <td style="width: 35%">
                {{ printModel.params.specificationStandard2 }}
              </td>
              <td style="width: 35%">
                {{ printModel.params.testMethodStandard2 }}
              </td>
            </tr>

            <tr v-show="printModel.params.specificationStandard3">
              <td style="width: 30%"><b>标准</b></td>
              <td style="width: 35%">
                {{ printModel.params.specificationStandard3 }}
              </td>
              <td style="width: 35%">
                {{ printModel.params.testMethodStandard3 }}
              </td>
            </tr>

            <tr v-show="printModel.params.specificationMeshSize">
              <td style="width: 30%"><b>过筛尺寸</b></td>
              <td style="width: 35%">
                <span t-field="o.standard2">{{
                  printModel.params.specificationMeshSize
                }}</span>
              </td>
              <td style="width: 35%">筛子</td>
            </tr>

            <tr v-show="printModel.params.specificationLossonDrying">
              <td style="width: 30%"><b>干燥失重</b></td>
              <td style="width: 35%">
                &lt;{{ printModel.params.specificationLossonDrying }} %
              </td>
              <td style="width: 35%">Draco-1.1.1.0</td>
            </tr>

            <tr v-show="printModel.params.specificationBulkDensity">
              <td style="width: 30%"><b>堆密度</b></td>
              <td style="width: 35%">
                {{ printModel.params.specificationBulkDensity }} g/mL
              </td>
              <td style="width: 35%">USP &lt;616&gt;</td>
            </tr>

            <tr>
              <td style="width: 30%"><b>HPTLC鉴定</b></td>
              <td style="width: 35%">合格</td>
              <td style="width: 35%">标准感光板</td>
            </tr>
          </tbody>
          <tbody>
            <tr v-show="printModel.params.specificationTotalHeavyMetals">
              <td style="width: 30%"><b>总重金属</b></td>
              <td style="width: 35%">
                &lt;{{ printModel.params.specificationTotalHeavyMetals }} ppm
              </td>
              <td style="width: 35%">USP &lt;231&gt;</td>
            </tr>
            <tr v-if="printModel.params.specificationArsenic">
              <td style="width: 30%"><b>砷</b></td>
              <td style="width: 35%">
                {{ printModel.params.specificationArsenic }} ppm
              </td>
              <td style="width: 35%">
                {{ printModel.params.testMethodArsenic }}
              </td>
            </tr>
            <tr v-if="printModel.params.specificationCadmium">
              <td style="width: 30%"><b>镉</b></td>
              <td style="width: 35%">
                {{ printModel.params.specificationCadmium }} ppm
              </td>
              <td style="width: 35%">
                {{ printModel.params.testMethodCadmium }}
              </td>
            </tr>
            <tr v-if="printModel.params.specificationLead">
              <td style="width: 30%"><b>铅</b></td>
              <td style="width: 35%">
                {{ printModel.params.specificationLead }} ppm
              </td>
              <td style="width: 35%">{{ printModel.params.testMethodLead }}</td>
            </tr>
            <tr v-if="printModel.params.specificationMercury">
              <td style="width: 30%"><b>汞</b></td>
              <td style="width: 35%">
                {{ printModel.params.specificationMercury }} ppm
              </td>
              <td style="width: 35%">
                {{ printModel.params.testMethodMercury }}
              </td>
            </tr>
            <tr v-show="printModel.params.specificationCustomerKey1">
              <td style="width: 30%">
                <b>{{ printModel.params.specificationCustomerKey1 }}</b>
              </td>
              <td style="width: 35%">
                {{ printModel.params.specificationCustomerValue1 }}
              </td>
              <td
                style="width: 35%"
                v-show="
                  typeof printModel.params.testMethodCustomerValue1 !=
                  'undefined'
                "
              >
                {{ printModel.params.testMethodCustomerValue1 }}
              </td>
            </tr>
            <tr v-if="printModel.params.specificationCustomerKey2">
              <td style="width: 30%">
                <b>{{ printModel.params.specificationCustomerKey2 }}</b>
              </td>
              <td style="width: 35%">
                {{ printModel.params.specificationCustomerValue2 }}
              </td>
              <td
                style="width: 35%"
                v-show="
                  typeof printModel.params.testMethodCustomerValue2 !=
                  'undefined'
                "
              >
                {{ printModel.params.testMethodCustomerValue2 }}
              </td>
            </tr>
            <tr v-if="printModel.params.specificationCustomerKey3">
              <td style="width: 30%">
                <b>{{ printModel.params.specificationCustomerKey3 }}</b>
              </td>
              <td style="width: 35%">
                {{ printModel.params.specificationCustomerValue3 }}
              </td>
              <td
                style="width: 35%"
                v-show="
                  typeof printModel.params.testMethodCustomerValue3 !=
                  'undefined'
                "
              >
                {{ printModel.params.testMethodCustomerValue3 }}
              </td>
            </tr>
            <tr v-if="printModel.params.specificationCustomerKey4">
              <td style="width: 30%">
                <b>{{ printModel.params.specificationCustomerKey4 }}</b>
              </td>
              <td style="width: 35%">
                {{ printModel.params.specificationCustomerValue4 }}
              </td>
              <td
                style="width: 35%"
                v-show="
                  typeof printModel.params.testMethodCustomerValue4 !=
                  'undefined'
                "
              >
                {{ printModel.params.testMethodCustomerValue4 }}
              </td>
            </tr>
            <tr v-if="printModel.params.specificationCustomerKey5">
              <td style="width: 30%">
                <b>{{ printModel.params.specificationCustomerKey5 }}</b>
              </td>
              <td style="width: 35%">
                {{ printModel.params.specificationCustomerValue5 }}
              </td>
              <td
                style="width: 35%"
                v-show="
                  typeof printModel.params.testMethodCustomerValue5 !=
                  'undefined'
                "
              >
                {{ printModel.params.testMethodCustomerValue5 }}
              </td>
            </tr>
          </tbody>

          <tbody
            v-for="(item, analysisIndex) in printModel.analysisCustomFieldAttr"
            :key="analysisIndex"
          >
            <tr v-show="item.customKey">
              <td style="width: 30%">
                <b>{{ item.customKey }}</b>
              </td>
              <td style="width: 35%">
                {{ item.customValue }}
              </td>
              <td style="width: 35%">
                {{ item.customValue1 }}
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr v-show="printModel.params.specificationColor">
              <td style="width: 30%"><b>颜色</b></td>
              <td style="width: 35%">
                {{ printModel.params.specificationColor }}
              </td>
              <td style="width: 35%">视觉</td>
            </tr>

            <tr v-show="printModel.params.specificationOrdor">
              <td style="width: 30%"><b>气味</b></td>
              <td style="width: 35%">
                {{ printModel.params.specificationOrdor }}
              </td>
              <td style="width: 35%">
                {{ printModel.params.testMethodOrdor }}
              </td>
            </tr>

            <tr v-show="printModel.params.specificationTaste">
              <td style="width: 30%"><b>味道</b></td>
              <td style="width: 35%">
                {{ printModel.params.specificationTaste }}
              </td>
              <td style="width: 35%">
                {{ printModel.params.testMethodTaste }}
              </td>
            </tr>
          </tbody>
        </table>

        <!-- Microbiological Tests -->
        <div style="height: 10px"></div>
        <table style="width: 99%">
          <tbody>
            <tr>
              <th style="width: 30%">
                <b><u>微生物检验</u></b>
              </th>
              <th style="width: 35%"><u>产品规格</u></th>
              <th style="width: 35%"><u>测试方法</u></th>
            </tr>
            <tr v-show="printModel.params.specificationTotalPlateCount">
              <td style="width: 30%"><b>细菌总数</b></td>
              <td style="width: 35%">
                <span t-field="o.total_plate" />&lt;{{
                  printModel.params.specificationTotalPlateCount
                }} cfu/g
              </td>
              <td style="width: 35%">
                <span t-field="o.total_plate_test" />FDA-BAM
              </td>
            </tr>

            <tr v-show="printModel.params.specificationYeastAndMold">
              <td style="width: 30%"><b>酵母和霉菌</b></td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold" />&lt;{{
                  printModel.params.specificationYeastAndMold
                }} cfu/g
              </td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />FDA-BAM
              </td>
            </tr>

            <tr v-show="printModel.params.specificationEcoli">
              <td style="width: 30%"><b>大肠杆菌</b></td>
              <td style="width: 35%" v-if="
            !printModel.params.specificationEcoli || 
            printModel.params.specificationEcoli.toLowerCase()=='negative' || 
            printModel.params.specificationEcoli.toLowerCase()=='阴性'">
              {{ printModel.params.specificationEcoli }}
            </td>
            <td style="width: 35%" v-else>
              {{ printModel.params.specificationEcoli }} 
            </td>
              <!-- <td style="width: 35%">
                <span t-field="o.yeast_mold" />{{
                  printModel.params.specificationEcoli
                }}<span
                  v-if="printModel.params.specificationEcoli != '阴性' && printModel.params.specificationEcoli.toLowerCase() != 'negative'"
                  > cfu/g</span
                >
              </td> -->
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />FDA-BAM
              </td>
            </tr>

            <tr v-show="printModel.params.specificationSalmonella">
              <td style="width: 30%"><b>沙门氏菌</b></td>
              <td style="width: 35%" v-if="
            !printModel.params.specificationSalmonella || 
            printModel.params.specificationSalmonella.toLowerCase()=='negative' || 
            printModel.params.specificationSalmonella.toLowerCase()=='阴性'">
              {{ printModel.params.specificationSalmonella }}
            </td>
            <td style="width: 35%" v-else>
              {{ printModel.params.specificationSalmonella }}
            </td>
              <!-- <td style="width: 35%">
                <span t-field="o.yeast_mold" />{{
                  printModel.params.specificationSalmonella
                }}<span
                  v-if="printModel.params.specificationSalmonella != '阴性' && printModel.params.specificationSalmonella.toLowerCase() != 'negative'"
                  > cfu/g</span
                >
              </td> -->
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />FDA-BAM
              </td>
            </tr>

            <tr v-show="printModel.params.specificationStaphAureus">
              <td style="width: 30%"><b>金色葡萄球菌</b></td>
              <td style="width: 35%" v-if="
            !printModel.params.specificationStaphAureus || 
            printModel.params.specificationStaphAureus.toLowerCase()=='negative' || 
            printModel.params.specificationStaphAureus.toLowerCase()=='阴性'">
              {{ printModel.params.specificationStaphAureus }}
            </td>
            <td style="width: 35%" v-else>
              {{ printModel.params.specificationStaphAureus }}
            </td>
              <!-- <td style="width: 35%">
                <span t-field="o.yeast_mold" />{{
                  printModel.params.specificationStaphAureus
                }}<span
                  v-if="printModel.params.specificationStaphAureus != '阴性' && printModel.params.specificationStaphAureus.toLowerCase() != 'negative'"
                  > cfu/g</span
                >
              </td> -->
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />FDA-BAM
              </td>
            </tr>

            <tr v-show="printModel.params.specificationTotalColiforms">
              <td style="width: 30%"><b>大肠菌群</b></td>
              <!-- <td style="width: 35%">
                <span t-field="o.yeast_mold" />&lt;{{
                  printModel.params.specificationTotalColiforms
                }} MPN/g
              </td> -->
              <td style="width: 35%" v-if="
            !printModel.params.specificationTotalColiforms || 
            printModel.params.specificationTotalColiforms.toLowerCase()=='negative' || 
            printModel.params.specificationTotalColiforms.toLowerCase()=='阴性'">
              {{ printModel.params.specificationTotalColiforms }}
            </td>
            <td style="width: 35%" v-else>
              &lt;{{ printModel.params.specificationTotalColiforms }} MPN/g
            </td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />FDA-BAM
              </td>
            </tr>
            <tr v-show="printModel.params.microbiologicalCustomerKey1">
              <td style="width: 30%">
                <b>{{ printModel.params.microbiologicalCustomerKey1 }}</b>
              </td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold" />{{
                  printModel.params.microbiologicalCustomerValue1
                }}
              </td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />{{
                  printModel.params.microbiologicalTestMethodCustomerValue1
                }}
              </td>
            </tr>
          </tbody>
          <tbody
            v-for="(item, micIndex) in printModel.micTestsCustomFieldAttr"
            :key="micIndex"
          >
            <tr v-show="item.customKey">
              <td style="width: 30%">
                <b>{{ item.customKey }}</b>
              </td>
              <td style="width: 35%">
                {{ item.customValue }}
              </td>
              <td style="width: 35%">
                {{ item.customValue1 }}
              </td>
            </tr>
          </tbody>
        </table>

        <div style="height: 10px"></div>
        <table style="width: 99%">
          <tbody>
            <tr>
              <td style="width: 30%; font-size: 18px">
                <b><u>其他</u></b>
              </td>
              <td style="width: 70%" />
            </tr>
            <tr v-if="printModel.params.shelflife">
              <td style="width: 30%"><b>保质期</b></td>
              <td style="width70%">
                <span t-field="o.shelf_life" />{{ printModel.params.shelflife }}
              </td>
            </tr>

            <tr v-if="printModel.params.storage">
              <td style="width: 30%"><b>储存条件</b></td>
              <td style="width70%">
                <span t-field="o.storage" />{{ printModel.params.storage }}
              </td>
            </tr>
          </tbody>
        </table>

        <div style="height: 20px"></div>
        <div style="font-size: 18px">
          <b><u>注释</u></b>
        </div>
        <div
          class="footer"
          id="footer"
          style="
            font-size: 11px;
            font-family: Times New Roman;
            margin-top: 10px;
          "
        >
          <div>
            <span
              ><i
                >* 不同批次产品的颜色可能会因天然原材料的自然变化而产生差异。</i
              ></span
            >
          </div>
          <div style="height: 10px"></div>
          <div v-if="printModel.params.isShowNotes2">
            <span><i>* 产品含抗氧化化合物，可能会随着时间推移颜色变深但不影响使用效果。</i></span>
          </div>
          <div style="height: 10px" v-if="printModel.params.isShowNotes2"></div>
          <div>
            <span
              ><i
                >* 必要情况下，产品的pH 值应该在1%该产品的水溶液中测定。</i
              ></span
            >
          </div>
          <p v-for="(item, index) in printModel.newNoteArr" :key="index">
            * {{item.content}}
          </p>
          <div style="height: 10px"></div>
          <div>
            <b
              ><u
                >Disclaimer: The product specifications, limits, test methods,
                and information contained on this document are accurate to the
                best of our knowledge based upon general industry information,
                available scientific data, and customer requests. These values
                are not intended to be used as specifications for a finished
                product and should be used only at the sole discretion of the
                purchaser. According to GMP regulations, the test results of the
                product must be independently verified by the purchasing party.
                The appropriate use of this product is the sole responsibility
                of the user or the purchasing party.</u
              ></b
            >
          </div>
        </div>
        <FooterSigner></FooterSigner>
      </div>
    </div>
  </div>
</template>
<script>
import FooterSigner from "./Cn-FooterSigner";
export default {
  name: "Cn-TY-P",
  components: { FooterSigner },
  props: ["printModel"],
  data() {
    return {
      username: "",
    };
  },
  computed: {
    maxArr() {
      let tmp = [
        this.printModel.ingredientsArr.length,
        this.printModel.inclNameArr.length,
        this.printModel.botanicalNameArr.length,
        this.printModel.commonOtherNameArr.length,
        this.printModel.partUsedArr.length,
      ].sort();
      return tmp[tmp.length - 1];
    },
    isShowTable() {
      if (
        this.printModel.params.tableInput1 ||
        this.printModel.params.tableInput2 ||
        this.printModel.params.tableInput3 ||
        this.printModel.params.tableInput4 ||
        this.printModel.params.tableInput5
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    isSingle() {
      var count = 0;
      var name = "";
      var arr = [];
      if (this.printModel.commonOtherNameArr.length >= 1) {
        name = "通用名/其他名称";
        count = count + 1;
        arr = this.printModel.commonOtherNameArr;
      }
      if (this.printModel.botanicalNameArr.length >= 1) {
        name = "植物学名";
        count = count + 1;
        arr = this.printModel.botanicalNameArr;
      }
      if (this.printModel.partUsedArr.length >= 1) {
        name = "使用部位";
        count = count + 1;
        arr = this.printModel.partUsedArr;
      }
      if (this.printModel.percentageArr.length >= 1) {
        name = "百分比 ";
        count = count + 1;
        arr = this.printModel.percentageArr;
      }
      let res = { count, name, arr };
      return res;
    },
  },
};
</script>

<style scoped>
.tab tr td,
.tab thead th {
  padding: 5px;
}
</style>
