<template>
  <div
    class="app-new-item"
    v-loading.fullscreen.lock="loading"
    element-loading-spinner="el-icon-loading"
    element-loading-text="Desperately loading..."
    :style="{ width: sWidth }"
  >
    <div class="new-item-head">
      TECH DOC. EDITOR Import {{ titleType }}
      <span v-if="showVerified">
        <i :class="verify_show.icon" :style="{ color: verify_show.color }"></i>
      </span>
      <span
        style="position: absolute; left: 10px; cursor: pointer"
        @click="back"
      >
        <i class="el-icon-arrow-left"></i>
      </span>
      <span
        style="
          position: absolute;
          right: 10px;
          cursor: pointer;
          font-weight: 400;
          font-size: 14px;
        "
      >
        <el-dropdown placement>
          <div>
            <i class="el-icon-user" style="margin-right: 10px"></i>
            {{ userInfo.username }}
            <i class="el-icon-arrow-down"></i>
            <!-- <span class="user-name">语言切换</span> -->
            <el-dropdown-menu class="user-dropdown" slot="dropdown">
              <el-dropdown-item @click.native="logoutCnSpecHandle"
                >退出</el-dropdown-item
              >
            </el-dropdown-menu>
          </div>
        </el-dropdown>
      </span>
    </div>
      <div style="position: absolute;text-align: left;margin: 120px 0 0 180px;color: red;z-index: 9;">
        <div style="height: 30px;line-height: 30px;">Rev：{{params.revNumber}}</div>
        <div style="height: 30px;line-height: 30px;">Date：{{revDateTime!=""?formatTime(revDateTime,"yyyy-MM-dd hh:mm"):revDateTime}}</div>
      </div>
    <div class="new-item-content">
      <!-- base info -->
      <div class="base-info">
        <div>基础信息</div>
        <div>
          <div>
            <span>产品类别：</span>
            <el-select
              ref="productType"
              :class="{ 'velidate-item': checkProductType }"
              @change="productTypeChange"
              @clear="validate('productType')"
              v-model="params.productType"
              placeholder="select"
              clearable
              :disabled="isSelectProType"
            >
              <el-option
                v-for="(item, i) in productTypeList"
                :key="i"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <div>
            <span>产品名称：</span>
            <el-autocomplete
              :class="{ 'velidate-item': checkProductName }"
              ref="productName"
              clearable
              v-model="params.BaseInfoProductName"
              :fetch-suggestions="nameSearch"
              placeholder="请输入产品名称"
              @select="handleSelectName"
              @blur="validate('productName')"
              @input="validate('productName')"
              popper-class="select-option"
              :popper-append-to-body="false"
            ></el-autocomplete>
          </div>

          <div>
            <span>产品编号：</span>
            <el-autocomplete
              clearable
              ref="itemCode"
              :class="{ 'velidate-item': checkItemCode }"
              v-model="params.BaseInfoItemCode"
              :fetch-suggestions="codeSearch"
              placeholder="请输入产品编号"
              @blur="validate('itemCode', 'check')"
              @input="validate('itemCode', 'change')"
              @select="handleSelectCode"
              popper-class="select-option"
              :popper-append-to-body="false"
            ></el-autocomplete>
          </div>
          <div>
            <span>版本号：</span>
            <el-input
              v-model="params.versionNumber"
              placeholder="请输入版本号"
            ></el-input>
          </div>

          <div class="area-input">
            <span>产品说明：</span>
            <el-input
              type="textarea"
              :rows="3"
              resize="none"
              style="width: 600px"
              placeholder="请输入产品说明"
              v-model="params.BaseInfoProductDescription"
            ></el-input>
          </div>
          <div style="height: auto;margin-top:20px;">
            <div style="display: inline-block; text-align: left">
              <table style="border: 1px solid #eee;">
                <tr v-for="(item, baseInfoInputIndex) in BaseInfoCustomFieldAttr" :key="baseInfoInputIndex + 1">
                  <td style="border: 1px solid #eee;">
                    <el-input placeholder="请输入内容" style="width: 100%" v-model="item.baseInfoInput1"></el-input>
                  </td>
                  <td style="border: 1px solid #eee;">
                    <el-input placeholder="请输入内容" v-model="item.baseInfoInput2"></el-input>
                  </td>
                  <td>
                    <el-button type="primary" class="button-new-tag" size="small" @click="addBaseInfoInput(1, 0)">+</el-button>
                    <el-button v-show="BaseInfoCustomFieldAttr.length>1" type="danger" class="button-new-tag" size="small" @click="addBaseInfoInput(0, baseInfoInputIndex)">-</el-button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Liquid v-if="otherType == 'TY-L'" :baseInfoDetail="this.$data"></Liquid>
      <OilEhxto v-else-if="otherType == 'TY-EHXTO'" :baseInfoDetail="this.$data"></OilEhxto>
      <OilShxto v-else-if="otherType == 'TY-SHXTO'" :baseInfoDetail="this.$data"></OilShxto>
      <Powder v-else-if="otherType == 'TY-P'" :baseInfoDetail="this.$data"></Powder>
    </div>
  </div>
</template>

<script>
import base from "@/common/base.js";
import Powder from "./Powder.vue";
import Liquid from "./Liquid.vue";
import OilEhxto from "./OilEhxto.vue";
import OilShxto from "./OilShxto.vue";

export default {
  name: "cnnew-item",
  mixins: [base],
  components: { Liquid, OilEhxto, OilShxto, Powder},
  data() {
    return {
      productTypeList: [
        { label: "TY-P", value: "TY-P" },
        { label: "TY-L", value: "TY-L" },
        { label: "TY-EHXTO", value: "TY-EHXTO" },
        { label: "TY-SHXTO", value: "TY-SHXTO" },
      ],
      isSelectProType: false,
      showVerified: false,
      isVerifing: false,
      checkItemCode: false,
      checkProductName: false,
      checkProductType: false,
      specificationYeastAndMoldD: false,
      hasVerifyItemCode: false,
      loading: false,
      itemCodeExist: 1,
      otherType: "",
      titleType: "添加",
      sWidth: 0,
    
      printShow: false,
      revDateTime:"",
      params: {
        productId:0,
        productType: "TY-P",
        BaseInfoItemCode: "",
        BaseInfoProductName: "",
        BaseInfoLot: "",
        BaseInfoProductDescription: "经有机认证的全谱工艺提取、喷雾干燥的粉末状 XXX(自动填入植物学名）植物提取物。",
        revNumber:0,
        revDate:[],
        versionNumber: "",
        confirmed:2,
      },
      specUserInfo:{},
      BaseInfoCustomFieldAttr: [{
        baseInfoInput1: "",
        baseInfoInput2: ""
      }],
      baseInfoField: {
        baseInfoInput1: "",
        baseInfoInput2: ""
      },
    };
  },
  computed: {
    verify_show: {
      get() {
        switch (this.params.confirmed) {
          case 1:
            return { icon: "el-icon-success", color: "#67c23a" };
          case 2:
            return { icon: "el-icon-question", color: "#a6a9ad" };
          default:
            return { icon: "el-icon-error", color: "#f56c6c" };
        }
      },
      set() {
        switch (this.params.confirmed) {
          case 1:
            return { icon: "el-icon-success", color: "#67c23a" };
          case 2:
            return { icon: "el-icon-question", color: "#a6a9ad" };
          default:
            return { icon: "el-icon-error", color: "#f56c6c" };
        }
      },
    },
  },
  methods: {
    back() {
      this.$router.push({ path: "/cn-home" });
    },
    addBaseInfoInput(type,index){
      if(type==1){
        this.BaseInfoCustomFieldAttr.push({ ...this.baseInfoField });
      }
      else{
        this.$delete(this.BaseInfoCustomFieldAttr,index)
      }
    },
    productTypeChange() {
      console.log(this.params.productType);
      this.params.revNumber = 0
      this.otherType = this.params.productType;
      if(this.params.productType=="TY-L"){
        this.params.BaseInfoProductDescription="液体植物提取物中的活性物质来自于本公司特有的控温封闭提取系统，其中大多数生物活性化合物被完全保留到产品中。本提取液100%从天然植物提取"
      }
      else if(this.params.productType=="TY-EHXTO" || this.params.productType=="TY-SHXTO"){
        this.params.BaseInfoProductDescription=""
      }
      else{
        this.params.BaseInfoProductDescription="经有机认证的全谱工艺提取、喷雾干燥的粉末状 XXX(自动填入植物学名）植物提取物"
      }
    },
    validate(item, type) {
      switch (item) {
        case "productType":
          {
            this.checkProductType = this.params.productType.trim()
              ? false
              : true;
          }
          break;
        case "productName":
          {
            this.checkProductName = this.params.BaseInfoProductName.trim()
              ? false
              : true;
          }
          break;
        case "itemCode":
          {
            this.checkItemCode = this.params.BaseInfoItemCode.trim() ? false : true;
            if (this.itemCodeExist == 0) {
              this.checkItemCode = true;
            }
            if (type == "change") {
              // console.log(123);
              this.hasVerifyItemCode = false;
            }
            if (type == "check" && !this.hasVerifyItemCode) {
              this.check();
            }
          }
          break;
        default:
          break;
      }

    },
    nameSearch(queryString, cb) {
      // var restaurants = this.tableData.map(v => {
      //   return { value: v.productName };
      // });
      var restaurants = this.$store.state.nameList;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    codeSearch(queryString, cb) {
      // var restaurants = this.tableData.map(v => {
      //   return { value: v.itemCode };
      // });
      var restaurants = this.$store.state.codeList;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) !=
            -1 || restaurant.value.indexOf(queryString) != -1
        );
      };
    },
    handleSelectName(val) {
      console.log(val);
      this.checkProductName = false;
    },
    handleSelectCode(val) {
      console.log(val);
      this.checkItemCode = false;
      this.check();
    },
    check() {
      if (this.params.BaseInfoItemCode.trim() == "") {
        return;
      }
      let data = { itemCode: this.params.BaseInfoItemCode, id: this.params.productId };
      console.log("check()check()check()data")
      console.log(data)
      this.$api.handleSpec
        .checkItemCode(data)
        .then((data) => {
          this.hasVerifyItemCode = true;
          if (data) {
            this.$message({
              type: "error",
              message: "Item Code Already Exists",
            });
            this.itemCodeExist = 0;
            this.checkItemCode = true;
          } else {
            this.$message({ type: "success", message: "Item Code Is Usable" });
            this.itemCodeExist = 2;
            this.checkItemCode = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    let specUserInfo = JSON.parse(localStorage.getItem('specUserInfo'))
    if(specUserInfo){
      this.specUserInfo = specUserInfo;
    }
    this.params.productType = this.productTypeList[0].value;
    this.titleType = "添加";
    let params = { ...this.$route.query };
    
    this.params.productId = params.id;
    if (params.id) {
      this.isSelectProType = true;
      this.titleType = "修改";
      this.itemCodeExist == 2;
      this.showVerified = true;
      if(params.productType){
        this.otherType = params.productType;
        this.params.productType = params.productType;
      }
    }
    else{
      this.otherType = this.params.productType;
    }
    this.sWidth = "1920px";
  },
  created() {},
};
</script>
<style lang="scss" scoped>
@import "../module/NewItemStyle.scss";
</style>

